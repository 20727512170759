import { useFetchPost } from "@src/utils/api/hooks";
import React, { useCallback } from "react";

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  loading: boolean;
  data: any;
  error: any;
  onContact: (value: any) => void;
}

export const ContactContext = React.createContext<ContextValue>(
  {
    error: { message: ""}
  } as ContextValue
);

const ContactProvider = ({ children }: ProviderProps) => {
  const { loading, data, error, runRequest } = useFetchPost();

  const onContact = useCallback((params: any) => {
    runRequest("/api/v1/redeem/feedback", params);
  }, [error]);

  return (
    <ContactContext.Provider value={{ loading, data, error, onContact }}>
      {children}
    </ContactContext.Provider>
  );
};

export default ContactProvider;
