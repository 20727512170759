import React, { useState } from "react";
import { useInputHelper } from "@src/utils/inputs-helper";
import { TextInput02 } from "@src/components/textinput";
import { Button02 } from "@src/components/button";
import { ForgotContext } from "../provider";
import { View, Text } from "react-native";
import useStyles from "./styles.css";
import _ from "lodash";

const initialState = {
  email: "",
};

function EnterEmail() {
  const styles = useStyles();
  const errorRef = React.useRef<any>(null);
  const [error, setError] = useState<any>({});
  const {state, onDispatch} = useInputHelper(initialState);
  const { data, onForgot, loading } = React.useContext(ForgotContext);

  const onSubmit = () => {
    const Err: any = {};
    if (!state.email) {
      Err.email = "Required";
    }

    setError(Err);

    if (_.isEmpty(Err)) {
      onForgot(state);
    }
  };

  React.useEffect(() => {
    setError({});
    errorRef?.current?.onClear();
  }, [state]);

  if(!_.isEmpty(data)){
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.v_details}>
        <Text style={styles.t_login}>Forgot Password</Text>
        <Text style={styles.t_requirement}>Enter your email address</Text>
      </View>
      <View style={styles.v_inputs}>
        <TextInput02 
          label="Email"
          value={state.email}
          placeholder="me@email.com"
          error={error?.email}
          onChangeText={onDispatch("email")}
          keyboardType="email-address" />
        <Button02 
          label="SUBMIT"
          onPress={onSubmit}
          isLoading={loading}
          labelStyle={{color: "white"}}
          style={styles.btn_submit} />
      </View>
    </View>
  );
}

export default EnterEmail;