import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import { useRoute } from "@react-navigation/native";

function Product() {
  const styles = useStyles();
  const route = useRoute<any>();
  const amount = route?.params?.amount;
  const productName = route?.params?.name;
  
  return (
    <View style={[styles.product_container]}>
      <Image style={styles.item_banner} source={images["amazon"]} resizeMode="stretch" />
      <Text style={styles.t_pleasecheck2}>Please check your selection is correct.</Text>
      <View style={styles.item_details}>
        <View style={styles.v_field}>
          <Text style={styles.t_field} >Gift Card:</Text>
          <View style={styles.v_value}>
            <Text style={styles.t_value} >{productName}</Text>
          </View>
        </View>
        <View style={[styles.v_field2]}>
          <Text style={styles.t_field} >Amount:</Text>
          <View style={[styles.v_value, { width: 115 }]}>
            <Text style={styles.t_value} >{amount}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default Product;