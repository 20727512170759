import React from "react";
import { selectAuthSession } from "@src/ducks/slices/auth.slice";
import { getCountryValue, getGenderValue, useInputHelper } from "@src/utils/inputs-helper";
import { useNavigation } from "@react-navigation/native";
import { COUNTRIES, GENDER } from "@src/utils/mock-data";
import { TextInput01 } from "@src/components/textinput";
import DatePicker01 from "@src/components/date-picker";
import { Text, View, ScrollView } from "react-native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useFetchPost } from "@src/utils/api/hooks";
import { Button01 } from "@src/components/button";
import { useAuthService } from "@src/ducks/hooks";
import { AuthContext } from "@src/AuthProvider";
import Dropdown from "@src/components/dropdown";
import Footer from "../dashboard/footer";
import useStyles from "./styles.css";
import dayjs from "dayjs";
import moment from "moment";
import _ from "lodash";

const EditProfile = () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const { onLogin } = useAuthService();
  const session = useAppSelector(selectAuthSession);
  const { loading, data, runRequest } = useFetchPost();
  const [error, setError] = React.useState<any>({});
  const { onSuccessMessage } = React.useContext(AuthContext);
  const { state, onDispatch, onSetInitial } = useInputHelper();

  const onUpdate = () => {
    const Err: any = {};
    if (!state.firstName) {
      Err.firstName = "Required";
    } else if (!state.lastName) {
      Err.lastName = "Required";
    } else if (!state.gender) {
      Err.gender = "Required";
    } else if (!state.dob) {
      Err.dob = "Required";
    } else if (!state.contactNumber) {
      Err.contactNumber = "Required";
    } else if (!state.streetAddress) {
      Err.streetAddress = "Required";
    } else if (!state.city) {
      Err.city = "Required";
    } else if (!state.country) {
      Err.country = "Required";
    }

    setError(Err);

    if (_.isEmpty(Err)) {
      const dob = typeof state.dob !== "string" ? 
        moment(state.dob).format("DD/MM/YYYY") : state.dob;
      const gender = getGenderValue(state.gender);
      const country = getCountryValue(state.country);
      runRequest("/api/v1/redeem/user/userProfileUpdate", {...state, gender, country, dob});
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      onLogin({...data, token: session.token});
      onSuccessMessage("Update Success!");
      navigation.goBack();
    }
  }, [data]);

  React.useEffect(() => {
    if(!_.isEmpty(session)){
      const country = session?.userDetail?.country ?? {};
      const gender = session?.userDetail?.gender ?? {};

      onSetInitial({
        "city": session?.userDetail?.city ?? "",
        "contactNumber": session.phoneNumber,
        "dob": session.dateOfBirth ? moment(session.dateOfBirth) : "",
        "country": country?.name ?? "",
        "firstName": session.firstName,
        "lastName": session.lastName,
        "postcode": session?.userDetail?.postCode ?? "",
        "streetAddress": session?.userDetail?.street ?? "",
        "gender": gender?.name ?? "",
        "userId": session.id,
      });
    }
  }, [session]);


  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.t_editprofile}>EDIT MY PROFILE</Text>
        <Text style={styles.t_personalinfo}>PERSONAL INFORMATION</Text>
        <View style={styles.v_row_details}>
          <View style={styles.v_row_1}>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >FIRST NAME:</Text>
              <TextInput01 placeholder="Firstname"
                value={state.firstName}
                error={error.firstName}
                onChangeText={onDispatch("firstName")}
                containerStyle={styles.ti_containerStyle} />
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >LAST NAME:</Text>
              <TextInput01 placeholder="Lastname"
                value={state.lastName}
                error={error.lastName}
                onChangeText={onDispatch("lastName")}
                containerStyle={styles.ti_containerStyle} />
            </View>
            <View style={styles.v_row_details}>
              <View style={styles.v_field}>
                <Text style={styles.t_field} >GENDER:</Text>
                <Dropdown
                  options={GENDER}
                  placeholder="Select"
                  value={state.gender}
                  style={styles.ti_gender}
                  onSelected={(e: any) => onDispatch("gender")(e.name)}
                  ItemSeparatorComponent={() => <View style={{height: 0}} />}
                />
              </View>
              <View style={styles.v_field}>
                <Text style={styles.t_field} >D.O.B:</Text>
                <DatePicker01 
                  format="DD/MM/YYYY"
                  value={dayjs(state.dob)}
                  error={error.dob}
                  onChange={(e: any) => onDispatch("dob")(e.format("DD/MM/YYYY"))} />
              </View>
            </View>

            <View style={styles.v_field}>
              <Text style={styles.t_field} >CONTACT NUMBER:</Text>
              <TextInput01 placeholder="Contact number"
                value={state.contactNumber}
                error={error.contactNumber}
                onChangeText={onDispatch("contactNumber")}
                containerStyle={styles.ti_containerStyle} />
            </View>
          </View>
          <View style={styles.v_address}>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >STREET ADDRESS:</Text>
              <TextInput01 placeholder="Street address"
                value={state.streetAddress}
                error={error.streetAddress}
                onChangeText={onDispatch("streetAddress")}
                containerStyle={styles.ti_containerStyle} />
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >CITY:</Text>
              <TextInput01 placeholder="City"
                value={state.city}
                error={error.city}
                onChangeText={onDispatch("city")}
                containerStyle={styles.ti_containerStyle} />
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >COUNTRY:</Text>
              <Dropdown
                options={COUNTRIES}
                placeholder="Select"
                value={state.country}
                style={styles.ti_containerStyle}
                onSelected={(e: any) => onDispatch("country")(e.name)}
                ItemSeparatorComponent={() => <View style={{height: 0}} />}
              />
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >POSTCODE:</Text>
              <TextInput01 placeholder="Postcode"
                value={state.postcode}
                error={error.postcode}
                inputStyle={styles.input_postcode}
                onChangeText={onDispatch("postcode")}
                containerStyle={[styles.ti_postcode]} />
            </View>
          </View>
        </View>
        <View style={styles.v_row_buttons}>
          <Button01 onPress={() => navigation.goBack()} style={styles.btn_cancel} label="CANCEL" />
          <View style={{width: 30}}/>
          <Button01 isLoading={loading} onPress={onUpdate} style={styles.btn_save} label="SAVE" />
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default EditProfile;
