import images from "@assets/images";

export type FeaturesValue = {
  name: string;
  description: string;
  banner: string | typeof images;
}

export const FeaturesData: FeaturesValue[] = [
  {
    name: "Treasure Chase",
    description: "Take your next adventure with Treasure Chase. Your trip will be wild with this slots cascading reels with massive multipliers. Have hours of fun playing Treasure Chase.",
    banner: "TC_tabela-v2"
  },
  {
    name: "The Great Sevens",
    description: "Are you feeling lucky? It’s time to take a spin and take your chances on the The Great Sevens slot. ",
    banner: "The-Great-Sevens-t"
  },
  {
    name: "Candy Dreams",
    description: "There’s so much fun to be had in matching candy in Candy Dreams. You will love this fun new take on casino slots and it’s here for you. Play Candy Dreams now for a whole new experience.",
    banner: "candydreams"
  },
  {
    name: "Talisman of Fortune",
    description: "Plunge into the world of Asian inspired lucky charms and activate Free Spins in the Talisman of Fortune. This could be your favourite game online.",
    banner: "talismanoffortune"
  },
  {
    name: "Hot Blaze ReSpin",
    description: "Play Hot Blaze ReSpin for you retro slot experience. You will love the bonus wheel and multipliers, stop missing the classic slot experience and play Hot Blaze ReSpin now.",
    banner: "hot_blazer"
  },
  {
    name: "Sword Master",
    description: "Check out the latest addition to our RPG catalogue. Sword Master is an actioned packed anime inspired game with unique battle systems. It’s time to dominate your way into your next adventure in Sword Master.",
    banner: "sword_master"
  }
];

export const PromotionalData = [
  {
    "id": 1,
    "productName": "Kirito [The Black Swordsman]",
    "description": "Damage Dealer - Physical DMG, Berserk",
    "goldAmount": 0.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:38:59.000+00:00"
  },
  {
    "id": 2,
    "productName": "Kirito [Knights of the Blood]",
    "description": "Assist - Physical DMG, Bleeding, Sunder",
    "goldAmount": 15.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:38:59.000+00:00"
  },
  {
    "id": 3,
    "productName": "Kirito [Spriggans]",
    "description": "Tank - Mana DMG, Weaken",
    "goldAmount": 10.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:38:59.000+00:00"
  },
  {
    "id": 4,
    "productName": "Tetsuo",
    "description": "Tank - Physical DMG, Paralyzed",
    "goldAmount": 5.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:38:59.000+00:00"
  },
  {
    "id": 5,
    "productName": "Asuna [Cape]",
    "description": "Damage Dealer - Physical DMG",
    "goldAmount": 5.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:38:59.000+00:00"
  },
  {
    "id": 6,
    "productName": "Asuna [Knights of the Blood]",
    "description": "Damage Dealer - Physical DMG, Bleeding",
    "goldAmount": 15.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:38:59.000+00:00"
  },
  {
    "id": 7,
    "productName": "Asuna [Titania]",
    "description": "Damage Dealer - Mana DMG, Breakdown",
    "goldAmount": 10.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 8,
    "productName": "Asuna [Undines]",
    "description": "Healer-Healer, Berserk, Focus",
    "goldAmount": 15.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 9,
    "productName": "Leafa [Sylphs]",
    "description": "Assist - Mana DMG, Wind Bonus",
    "goldAmount": 10.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 10,
    "productName": "Shino [Cait Sith]",
    "description": "Assist - Mana DMG, Bleeding",
    "goldAmount": 15.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 11,
    "productName": "Silica [Dragon Master]",
    "description": "Healer - Healer, Water Bonus, Berserk",
    "goldAmount": 10.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 12,
    "productName": "Silica [Cait Sith]",
    "description": "Damage Dealer - Mana DMG, Focus",
    "goldAmount": 15.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 13,
    "productName": "Lisbeth [War Hammer]",
    "description": "Tank- Physical DMG, Sunder",
    "goldAmount": 10.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 14,
    "productName": "Lisbeth [Leprechauns]",
    "description": "Tank-Mana DMG, Breakdown",
    "goldAmount": 15.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 15,
    "productName": "Klein [Katana]",
    "description": "Assist-Physical DMG, Burning",
    "goldAmount": 10.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 16,
    "productName": "Klein [Salamander]",
    "description": "Damage Dealer - Mana DMG, Burning",
    "goldAmount": 10.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 17,
    "productName": "Agil [Giant Axe Warrior]",
    "description": "Tank - Physical DMG, Earth Bonus",
    "goldAmount": 10.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:39:00.000+00:00"
  },
  {
    "id": 18,
    "productName": "Agil [Gnomes]",
    "description": "Tank - Mana DMG, Breakdown, Earth Bonus",
    "goldAmount": 15.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:41:50.000+00:00"
  },
  {
    "id": 19,
    "productName": "Sachi",
    "description": "Assist - Physical DMG, Sunder, Rage ",
    "goldAmount": 5.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:41:50.000+00:00"
  },
  {
    "id": 20,
    "productName": "Keita",
    "description": "Damage Dealer - Physical DMG",
    "goldAmount": 5.00,
    "isActive": 1,
    "createdDate": "2023-11-08T08:41:50.000+00:00"
  }
];

export const PromotionalImages: any = {
  1: require("../../assets/images/promotional/Avatar1001.webp"),
  2: require("../../assets/images/promotional/Avatar1002.webp"),
  3: require("../../assets/images/promotional/Avatar1003.webp"),
  4: require("../../assets/images/promotional/Avatar1004.webp"),
  5: require("../../assets/images/promotional/Avatar1005.webp"),
  6: require("../../assets/images/promotional/Avatar1006.webp"),
  7: require("../../assets/images/promotional/Avatar1007.webp"),
  8: require("../../assets/images/promotional/Avatar1008.webp"),
  9: require("../../assets/images/promotional/Avatar1009.webp"),
  10: require("../../assets/images/promotional/Avatar1010.webp"),
  11: require("../../assets/images/promotional/Avatar1011.webp"),
  12: require("../../assets/images/promotional/Avatar1012.webp"),
  13: require("../../assets/images/promotional/Avatar1013.webp"),
  14: require("../../assets/images/promotional/Avatar1014.webp"),
  15: require("../../assets/images/promotional/Avatar1015.webp"),
  16: require("../../assets/images/promotional/Avatar1016.webp"),
  17: require("../../assets/images/promotional/Avatar1017.webp"),
  18: require("../../assets/images/promotional/Avatar1018.webp"),
  19: require("../../assets/images/promotional/Avatar1019.webp"),
  20: require("../../assets/images/promotional/Avatar1020.webp"),
};


export const GENDER = [
  { value: 1, name: "MALE" },
  { value: 2, name: "FEMALE" },
];

export const COUNTRIES = [
  { value: 1, name: "Afghanistan" },
  { value: 2, name: "Albania" },
  { value: 3, name: "Algeria" },
  { value: 4, name: "Andorra" },
  { value: 5, name: "Angola" },
  { value: 6, name: "Antigua" },
  { value: 7, name: "Argentina" },
  { value: 8, name: "Armenia" },
  { value: 9, name: "Australia" },
  { value: 10, name: "Austria" },
  { value: 11, name: "Azerbaijan" },
  { value: 12, name: "Bahamas" },
  { value: 13, name: "Bahrain" },
  { value: 14, name: "Bangladesh" },
  { value: 15, name: "Barbados" },
  { value: 16, name: "Belarus" },
  { value: 17, name: "Belgium" },
  { value: 18, name: "Belize" },
  { value: 19, name: "Benin" },
  { value: 20, name: "Bhutan" },
  { value: 21, name: "Bolivia" },
  { value: 22, name: "Bosnia" },
  { value: 23, name: "Botswana" },
  { value: 24, name: "Brazil" },
  { value: 25, name: "Brunei" },
  { value: 26, name: "Bulgaria" },
  { value: 27, name: "Burkina" },
  { value: 28, name: "Burundi" },
  { value: 29, name: "Cabo Verde" },
  { value: 30, name: "Cambodia" },
  { value: 31, name: "Cameroon" },
  { value: 32, name: "Canada" },
  { value: 33, name: "Central African" },
  { value: 34, name: "Chad" },
  { value: 35, name: "Chile" },
  { value: 36, name: "China" },
  { value: 37, name: "Colombia" },
  { value: 38, name: "Comoros" },
  { value: 39, name: "Congo" },
  { value: 40, name: "Cook Islands" },
  { value: 41, name: "Costa Rica" },
  { value: 42, name: "Côte d'Ivoire" },
  { value: 43, name: "Croatia" },
  { value: 44, name: "Cuba" },
  { value: 45, name: "Cyprus" },
  { value: 46, name: "Czechia" },
  { value: 47, name: "Democratic People's Republic of Korea" },
  { value: 48, name: "Democratic Republic of the Congo" },
  { value: 49, name: "Denmark" },
  { value: 50, name: "Djibouti" },
  { value: 51, name: "Dominica" },
  { value: 52, name: "Dominican Republic" },
  { value: 53, name: "Ecuador" },
  { value: 54, name: "Egypt" },
  { value: 55, name: "El Salvador" },
  { value: 56, name: "Equatorial Guinea" },
  { value: 57, name: "Eritrea" },
  { value: 58, name: "Estonia" },
  { value: 59, name: "Eswatini" },
  { value: 60, name: "Ethiopia" },
  { value: 61, name: "Faroe Islands" },
  { value: 62, name: "Fiji" },
  { value: 63, name: "Finland" },
  { value: 64, name: "France" },
  { value: 65, name: "Gabon" },
  { value: 66, name: "Gambia" },
  { value: 67, name: "Georgia" },
  { value: 68, name: "Germany" },
  { value: 69, name: "Ghana" },
  { value: 70, name: "Greece" },
  { value: 71, name: "Grenada" },
  { value: 72, name: "Guatemala" },
  { value: 73, name: "Guinea" },
  { value: 74, name: "Guinea-Bissau" },
  { value: 75, name: "Guyana" },
  { value: 76, name: "Haiti" },
  { value: 77, name: "Honduras" },
  { value: 78, name: "Hungary" },
  { value: 79, name: "Iceland" },
  { value: 80, name: "India" },
  { value: 81, name: "Indonesia" },
  { value: 82, name: "Iran" },
  { value: 83, name: "Iraq" },
  { value: 84, name: "Ireland" },
  { value: 85, name: "Israel" },
  { value: 86, name: "Italy" },
  { value: 87, name: "Jamaica" },
  { value: 88, name: "Japan" },
  { value: 89, name: "Jordan" },
  { value: 90, name: "Kazakhstan" },
  { value: 91, name: "Kenya" },
  { value: 92, name: "Kiribati" },
  { value: 93, name: "Kuwait" },
  { value: 94, name: "Kyrgyzstan" },
  { value: 95, name: "Lao People's Democratic Republic" },
  { value: 96, name: "Latvia" },
  { value: 97, name: "Lebanon" },
  { value: 98, name: "Lesotho" },
  { value: 99, name: "Liberia" },
  { value: 100, name: "Libya" },
  { value: 101, name: "Lithuania" },
  { value: 102, name: "Luxembourg" },
  { value: 103, name: "Madagascar" },
  { value: 104, name: "Malawi" },
  { value: 105, name: "Malaysia" },
  { value: 106, name: "Maldives" },
  { value: 107, name: "Mali" },
  { value: 108, name: "Malta" },
  { value: 109, name: "Marshall Islands" },
  { value: 110, name: "Mauritania" },
  { value: 111, name: "Mauritius" },
  { value: 112, name: "Mexico" },
  { value: 113, name: "Micronesia (Federated States of)" },
  { value: 114, name: "Monaco" },
  { value: 115, name: "Mongolia" },
  { value: 116, name: "Montenegro" },
  { value: 117, name: "Morocco" },
  { value: 118, name: "Mozambique" },
  { value: 119, name: "Myanmar" },
  { value: 120, name: "Namibia" },
  { value: 121, name: "Nauru" },
  { value: 122, name: "Nepal" },
  { value: 123, name: "Netherlands" },
  { value: 124, name: "New Zealand" },
  { value: 125, name: "Nicaragua" },
  { value: 126, name: "Niger" },
  { value: 127, name: "Nigeria" },
  { value: 128, name: "Niue" },
  { value: 129, name: "North Macedonia" },
  { value: 130, name: "Norway" },
  { value: 131, name: "Oman" },
  { value: 132, name: "Pakistan" },
  { value: 133, name: "Palau" },
  { value: 134, name: "Panama" },
  { value: 135, name: "Papua New Guinea" },
  { value: 136, name: "ParaguayGuinea" },
  { value: 137, name: "Peru" },
  { value: 138, name: "Philippines" },
  { value: 139, name: "Poland" },
  { value: 140, name: "Portugal" },
  { value: 141, name: "Qatar" },
  { value: 142, name: "Republic of Korea" },
  { value: 143, name: "Republic of Moldova" },
  { value: 144, name: "Romania" },
  { value: 145, name: "Russian Federation" },
  { value: 146, name: "Rwanda" },
  { value: 147, name: "Saint Kitts" },
  { value: 148, name: "Saint Lucia" },
  { value: 149, name: "Saint Vincent and the Grenadines" },
  { value: 150, name: "Samoa" },
  { value: 151, name: "San Marino" },
  { value: 152, name: "Sao Tome and Principe" },
  { value: 153, name: "Saudi Arabia" },
  { value: 154, name: "Senegal" },
  { value: 155, name: "Serbia" },
  { value: 156, name: "Seychelles" },
  { value: 157, name: "Sierra Leone" },
  { value: 158, name: "Singapore" },
  { value: 159, name: "Slovakia" },
  { value: 160, name: "Slovenia" },
  { value: 161, name: "Solomon Islands" },
  { value: 162, name: "Somalia" },
  { value: 163, name: "South Africa" },
  { value: 164, name: "South Sudan" },
  { value: 165, name: "Spain" },
  { value: 166, name: "Sri Lanka" },
  { value: 167, name: "Sudan" },
  { value: 168, name: "Suriname" },
  { value: 169, name: "Sweden" },
  { value: 170, name: "Switzerland" },
  { value: 171, name: "Syrian Arab Republic" },
  { value: 172, name: "Tajikistan" },
  { value: 173, name: "Thailand" },
  { value: 174, name: "Timor-Leste" },
  { value: 175, name: "Togo" },
  { value: 176, name: "Tokelau" },
  { value: 177, name: "Tonga" },
  { value: 178, name: "Trinidad and Tobago" },
  { value: 179, name: "Tunisia" },
  { value: 180, name: "Turkey" },
  { value: 181, name: "Turkmenistan" },
  { value: 182, name: "Tuvalu" },
  { value: 183, name: "Uganda" },
  { value: 184, name: "Ukraine" },
  { value: 185, name: "United Arab Emirates" },
  { value: 186, name: "United Kingdom of Great Britain and Northern Ireland" },
  { value: 187, name: "United Republic of Tanzania" },
  { value: 188, name: "United States of America" },
  { value: 189, name: "Uruguay" },
  { value: 190, name: "Uzbekistan" },
  { value: 191, name: "Vanuatu" },
  { value: 192, name: "Venezuela" },
  { value: 193, name: "Viet Nam" },
  { value: 194, name: "Yemen" },
  { value: 195, name: "Zambia" },
  { value: 196, name: "Zimbabwe" },
];