import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      minHeight: 500,
      alignItems: "center", 
      justifyContent: "center",
      backgroundColor: "white",
    },
    t_success: {
      fontSize: 30, 
      marginTop: 35,
      lineHeight: 32,
      color: colors.black, 
      textAlign: "center",
      fontFamily: "Montserrat-Bold", 
    },
    t_verify: {
      fontSize: 16, 
      marginTop: 15,
      lineHeight: 21,
      color: colors.black, 
      textAlign: "center",
      fontFamily: "Montserrat-Bold", 
    },
    btn_dashboard: {width: 280, height: 44, borderRadius: 4, marginTop: 35},
    label_dashboard: {fontSize: 14},
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      container: { paddingHorizontal: 25 },
      t_verify: { fontSize: 12, lineHeight: 14 }
    },
  }
);

export default useStyles;
