import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import colors from "@assets/colors";
import useStyles from "./styles.css";

type PaginationProps = {
  page: number;
  count: number;
  rowsPerPage: number;
  onPageChange: (value: any) => void;
}

const Pagination = (props: PaginationProps) => {
  const styles = useStyles();
  const { page, rowsPerPage = 10, count } = props;
  const value = React.useMemo(() => {
    const to = (page + 1) * rowsPerPage;
    return {
      from: (page * rowsPerPage) + 1,
      to: to >= count ? count : to,
    };
  },[page, rowsPerPage, count]);

  const onPageChange = React.useCallback((value: number) => {
    const newpage = page + value;
    if(newpage <= 0){
      props.onPageChange(0);
    }else{
      props.onPageChange(newpage);
    }
  },[page]);

  return (
    <View style={styles.container}>
      <TouchableOpacity disabled={page === 0} 
        onPress={() => onPageChange(-1)} 
        style={[styles.btn_arrow, page === 0 && styles.btn_hide]}>
        <Text style={styles.t_rows}>Back</Text>
      </TouchableOpacity>
      <View style={styles.divider1} />
      <View style={{flexDirection: "row", alignItems: "center"}}>
        <Icon name="arrow-back-ios" size={15} color={colors.black} />
        <View style={styles.divider2} />
        <Text style={styles.t_rows}>{value.from}-{value.to} of {count}</Text>
        <View style={styles.divider2} />
        <Icon name="arrow-forward-ios" size={15} color={value.to >= count ? colors.black :  colors.black} />
      </View>
      <View style={styles.divider1} />
      <TouchableOpacity 
        disabled={value.to >= count} 
        onPress={() => onPageChange(1)}
        style={[styles.btn_arrow, value.to >= count && styles.btn_hide]}>
        <Text style={styles.t_rows}>Next Page</Text>
      </TouchableOpacity>
    </View>
  );
};

export default Pagination;