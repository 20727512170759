import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
  authActions,
  selectAuthLoggingIn,
  selectAuthLogInFailed,
  selectAuthLoggedIn,
} from "../slices/auth.slice";

// Types
import { LoginInput, ErrorValue, SignupInput } from "../types";

export type AuthServiceOperators = {
  failed: ErrorValue;
  isLoading: boolean;
  isLoggedIn: boolean;
  onLogin: (params: any) => void;
  onLogout: () => void;
  onSignin: (params: LoginInput) => void;
  onSignup: (params: SignupInput) => void;
};

export const useAuthService = (): Readonly<AuthServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    failed: useAppSelector(selectAuthLogInFailed),
    isLoading: useAppSelector(selectAuthLoggingIn),
    isLoggedIn: useAppSelector(selectAuthLoggedIn),
    onLogin: useCallback(
      (params: any) => {
        dispatch(authActions.loginSuccess(params));
      },
      [dispatch]
    ),
    onSignin: useCallback(
      (params: LoginInput) => {
        dispatch(authActions.loginRequest(params));
      },
      [dispatch]
    ),
    onSignup: useCallback(
      (params: SignupInput) => {
        dispatch(authActions.signupRequest(params));
      },
      [dispatch]
    ),
    onLogout: useCallback(() => {
      dispatch(authActions.logout());
    }, [dispatch]),
  };
};

export default useAuthService;
