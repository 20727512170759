import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 564,
      width: "100%",
      alignItems: "center", 
      paddingHorizontal: "13%",
      justifyContent: "center",
      backgroundColor: colors.violet2,
    },
    image_bg: { width: "100%", height: "100%", position: "absolute" },
    v_details: {flex: 1, width: "100%", justifyContent: "center", alignItems: "center"},
    i_logo: {width: 120, height: 104},
    i_email: {width: 114, height: 63},
    t_title: {
      fontFamily: "Montserrat-Bold", fontSize: 32, textAlign: "center",
      lineHeight: 39, color: colors.gold1, marginTop: 35
    },
    t_verify: {
      fontFamily: "Montserrat-Bold", fontSize: 24, textAlign: "center",
      lineHeight: 30, color: colors.black, marginTop: 35
    },
    btn_redeem: {
      width: 167, 
      height: 48,
      marginTop: 50,
      backgroundColor: colors.green1,
    },
    label_redeem: { fontFamily: "Montserrat", fontWeight: "600", 
      color: colors.white, fontSize: 16, lineHeight: 20 },
    t_notes: {
      fontFamily: "Montserrat-Medium", 
      fontSize: 20,
      lineHeight: 24,
      color: colors.white,
      textAlign: "center",
    },
    t_notes2: {
      fontFamily: "Montserrat-Medium", 
      fontSize: 20,
      lineHeight: 24,
      color: colors.black,
      textAlign: "center",
    },
    t_notes3: {
      fontFamily: "Montserrat-Medium", 
      fontSize: 16,
      lineHeight: 20,
      color: colors.black,
      textAlign: "center",
    },

    cell: {
      width: 42,
      height: 42,
      marginRight: 20,
      fontSize: 20,
      lineHeight: 40,
      borderRadius: 5,
      borderWidth: 1,
      textAlign: "center",
      borderColor: colors.black,
    },
    focusCell: {
      borderColor: colors.black,
    },

    t_didnotreceive: {
      fontFamily: "Montserrat-Medium", 
      fontSize: 14,
      lineHeight: 16,
      color: colors.black,
      textAlign: "center",
    },
    btn_sendcode: {
      width: 206, 
      height: 44,
      marginTop: 12,
      backgroundColor: colors.colorPrimary,
    },
    label_sendcode: { fontFamily: "Montserrat", fontWeight: "600", 
      color: colors.white, fontSize: 16, lineHeight: 20 },
  },
  {
    [DEVICE_SIZES.LG]: {
      label_redeem: { color: colors.white, fontSize: 12, lineHeight: 15 },
    },
    [DEVICE_SIZES.MD]: {
      t_title: { fontSize: 47, lineHeight: 55 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { height: 279, paddingHorizontal: 20},
      v_details: { alignItems: "flex-start"},
      t_title: { fontSize: 24, lineHeight: 28 },
      btn_redeem: {
        width: 126, 
        height: 34,
        marginTop: 20,
      },
      label_redeem: { fontSize: 10, lineHeight: 14 },
    },
  }
);

export default useStyles;
