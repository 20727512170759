import React from "react";
import { useAuthService } from "@src/ducks/hooks";
import { StyleSheet, Text } from "react-native";
import colors from "@assets/colors";

const Error = React.forwardRef((_, ref: any) => {
  const { failed } = useAuthService();
  const [message, setMessage] = React.useState("");

  React.useImperativeHandle(ref, () => ({
    onClear: () => setMessage(""),
  }));

  React.useEffect(() => {
    if (failed.message) {
      setMessage(failed.message);
    }
  }, [failed.message]);

  if (!message) {
    return null;
  }

  return <Text style={styles.errorStyle}>{message}</Text>;
});

const styles = StyleSheet.create({
  errorStyle: {
    fontFamily: "Inter",
    fontSize: 15,
    lineHeight: 20,
    fontWeight: "400",
    marginTop: 20,
    color: colors.red, 
    textAlign: "center" 
  }
});

export default React.memo(Error);
