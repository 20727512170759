import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      paddingTop: 50,
      paddingBottom: 70,
      alignItems: "center",
      backgroundColor: "white"
    },
    t_title: {
      fontFamily: "Montserrat-Bold", fontSize: 32, 
      lineHeight: 38, color: colors.colorPrimary, textAlign: "center"
    },
    t_subtitle: {
      fontFamily: "Montserrat", fontSize: 18,
      paddingHorizontal: 30,
      lineHeight: 27, color: colors.black1, 
      marginTop: 15, textAlign: "center"
    },
    image_promo: {width: "100%", maxWidth: 1077, height: 269, marginTop: 25},
    v_right_content: {flexDirection: "row", alignItems: "center"},
    btn_started: { backgroundColor: colors.colorPrimary, width: 130, height: 41, marginVertical: 50 },

    // ITEMS
    item_container: { width: 220, marginHorizontal: 30 },
    v_banners: { marginTop: 50},
    item_banner: { width: "100%", height: 220 },
    item_details: { paddingTop: 15, paddingLeft: 15, alignItems: "flex-start"},
    t_item_title: {
      fontFamily: "Montserrat-Bold", 
      fontSize: 12, 
      lineHeight: 14, 
      color: colors.black,
    },
    t_item_subtitle: {
      fontFamily: "Montserrat", 
      fontSize: 12, 
      lineHeight: 14, 
      color: colors.black,
      marginTop: 15,
      maxWidth: 190
    },
    t_item_gold: {
      fontFamily: "Montserrat-Bold", 
      fontSize: 12, 
      marginTop: 20,
      lineHeight: 14, 
      color: colors.black,
    },
    divider: { height: 60 },
  },
  {
    [DEVICE_SIZES.MD]: {
      t_title: { fontSize: 44, lineHeight: 50},
      item_container: { width: 250, },
      image_promo: { height: 120 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { paddingHorizontal: 10, marginTop: 0, paddingBottom: 20 },
      t_title: { fontSize: 24, lineHeight: 24 },
      t_subtitle: { fontSize: 10, lineHeight: 16 },
      image_promo: { height: 95 },
      divider: { height: 40 },
      v_banners: { alignItems: "center", width: "100%",  marginTop: 0 },
      item_details: { paddingLeft: 10},
      item_container: { width: 240, paddingHorizontal: 12, marginTop: 10, marginHorizontal: 0 },
    },
  }
);

export default useStyles;
