import React from "react";
import { View, FlatList } from "react-native";
import useStyles from "./styles.css";
import FAQList from "./faq.json";
import FAQItem from "./faq-item";

const FaqList = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <FlatList
        data={FAQList}
        keyExtractor={(item, index) => `faq_idx${index}`}
        ItemSeparatorComponent={() => <View style={styles.v_separator} />}
        renderItem={({ item, index }) => {

          return <FAQItem key={`faq_idx${index}`} item={item} />;
        }}
      />
    </View>
    
  );
};

export default FaqList;
