import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { RootStackParamList, RootStackOptionsValue, UnSignedStackParam, SignedStackParam } from "./NavigatorTypes";

// REDUCER
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { useAppSelector } from "@src/ducks/ducksHook";

// HEADER
import Header from "@src/screens/header";

// COMPONENT SCREENS
import SignIn from "@src/screens/auth/signin";
import Verification from "@src/screens/auth/verification";
import ForgotPassword from "@src/screens/auth/forgot-password";
import ResetPassword from "@src/screens/auth/reset-password";
import Dashboard from "@src/screens/dashboard";
import TermsCondition from "@src/screens/terms-condition";
import PrivacyPolicy from "@src/screens/privacy-policy";
import GamingPolicy from "@src/screens/gaming-policy";
import FAQs from "@src/screens/faqs";

// DASHBOARD HOME
import DashboardHome from "@src/screens/dashboard-home";
import Redeem from "@src/screens/redeem";
import EditProfile from "@src/screens/edit-profile";
import RequestForm from "@src/screens/request-form";
import ContactUs from "@src/screens/contactus";
import MyAccount from "@src/screens/my-account";
import Chat from "@src/screens/chat";

const RootStack = createStackNavigator<RootStackParamList>();
const StackUnSigned = createStackNavigator<UnSignedStackParam>();
const StackSigned = createStackNavigator<SignedStackParam>();

const RootStackOptions: RootStackOptionsValue = {
  headerShown: false,
  title: "GWz Rewardz",
};


const UnSignedDashboardNavigation = () => {
  return (
    <StackUnSigned.Navigator
      initialRouteName="Welcome"
      screenOptions={{
        title: "GWz Rewardz",
        header: () => <Header />
      }}
    >
      <StackUnSigned.Screen
        name="Welcome"
        component={Dashboard}
      />
      <StackUnSigned.Screen
        name="Signin"
        component={SignIn}
      />
      <StackUnSigned.Screen
        name="Verification"
        component={Verification}
      />
      <StackUnSigned.Screen
        name="ForgotPassword"
        component={ForgotPassword}
      />
      <StackUnSigned.Screen
        name="ResetPassword"
        component={ResetPassword}
      />
    </StackUnSigned.Navigator>
  );
};

const SignedDashboardNavigation = () => {
  return (
    <StackSigned.Navigator
      initialRouteName="Home"
      screenOptions={{
        title: "GWz Rewardz",
        header: () => <Header />
      }}
    >
      <StackSigned.Screen
        name="Home"
        component={DashboardHome}
      />
      <StackSigned.Screen
        name="MyAccount"
        component={MyAccount}
      />
      <StackSigned.Screen
        name="Redeem"
        component={Redeem}
      />
      <StackSigned.Screen
        name="RequestForm"
        component={RequestForm}
      />
      <StackSigned.Screen
        name="ContactUs"
        component={ContactUs}
      />
      <StackSigned.Screen
        name="EditProfile"
        component={EditProfile}
      />
    </StackSigned.Navigator>
  );
};

const AppRouting = () => {
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const screenComponents = React.useMemo(() => {
    if (isLoggedIn) {
      return (
        <>
          <RootStack.Screen name="DashboardNavigation" component={SignedDashboardNavigation} />
          <RootStack.Screen name="FAQs" component={FAQs}
            options={{
              title: "GWz Rewardz",
              header: () => <Header />
            }} />
          <RootStack.Screen name="TermsCondition" component={TermsCondition}
            options={{
              title: "GWz Rewardz",
              header: () => <Header />
            }} />
          <RootStack.Screen name="PrivacyPolicy" component={PrivacyPolicy}
            options={{
              title: "GWz Rewardz",
              header: () => <Header />
            }} />
          <RootStack.Screen name="GamingPolicy" component={GamingPolicy} />
        </>
      );
    } else {
      return (
        <>
          <RootStack.Screen name="UnSignedDashboardNavigation" component={UnSignedDashboardNavigation} />
          <RootStack.Screen name="FAQs" component={FAQs}
            options={{
              title: "GWz FAQs",
              headerTitle: "Awesome app",
              header: () => <Header />
            }} />
          <RootStack.Screen name="TermsCondition" component={TermsCondition}
            options={{
              title: "GWz Rewardz",
              header: () => <Header />
            }} />
          <RootStack.Screen name="PrivacyPolicy" component={PrivacyPolicy}
            options={{
              title: "GWz Rewardz",
              header: () => <Header />
            }} />
          <RootStack.Screen name="GamingPolicy" component={GamingPolicy}
            options={{
              title: "GWz Rewardz",
              header: () => <Header />
            }} />
        </>
      );
    }
  }, [isLoggedIn]);

  return (
    <RootStack.Navigator screenOptions={RootStackOptions}>
      {screenComponents}
    </RootStack.Navigator>
  );
};

export default AppRouting;
