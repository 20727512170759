import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      paddingVertical: 30,
      paddingHorizontal: "12%",
    },
    t_title: {fontFamily: "Montserrat-Medium", fontSize: 24, color: colors.colorPrimary},
    v_contact: {flexDirection: "row", alignItems: "center", justifyContent: "flex-end", marginTop: 25},
    t_notes: {fontFamily: "Montserrat", fontSize: 12, lineHeight: 14, color: colors.black},
    btn_contact: { width: 200, height: 46, borderRadius: 3, marginLeft: 10, backgroundColor: colors.colorPrimary },
    label_contact: {fontFamily: "Montserrat", fontWeight: "500", fontSize: 20, lineHeight: 22, color: colors.white},
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      container: { paddingHorizontal: 20, paddingBottom: 10 },
      t_title: { textAlign: "center", fontWeight: "600", fontSize: 16 },
      v_contact: {flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginTop: 30},
      btn_contact: { alignSelf: "center", marginTop: 20, width: 137, height: 37 },
      label_contact: { fontSize: 14, lineHeight: 18 },

    },
  }
);

export default useStyles;
