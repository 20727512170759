import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    main: {
      flex: 1,
      backgroundColor: colors.white
    },

    // INPUT AMOUNT
    ia_container: {width: "100%", height: 194, alignItems: "center", 
      justifyContent: "center", backgroundColor: colors.colorPrimary},
    ia_v_confirm: {flexDirection: "row", alignItems: "center"},
    ia_t_confirm: { fontFamily: "Montserrat-Bold", fontSize: 20, lineHeight: 22, color: colors.gold1 },
    ti_containerStyle: {width: 269, height: 45, marginLeft: 15, borderRadius: 3},
    ia_t_conversion: {fontFamily: "Montserrat-Medium", fontSize: 16, lineHeight: 18, color: colors.gold1, marginTop: 30},
  },
  {
    [DEVICE_SIZES.MD]: {
      ia_v_confirm: {flexDirection: "column", alignItems: "center"},
      ia_t_confirm: { fontSize: 14, lineHeight: 18, textAlign: "center", },
      ti_containerStyle: { marginLeft: 0, marginTop: 20},
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      ia_v_confirm: {flexDirection: "column", alignItems: "center"},
      ia_t_confirm: { fontSize: 12, lineHeight: 14, textAlign: "center", },
      ti_containerStyle: { marginLeft: 0, marginTop: 20},
    },
  }
);

export default useStyles;
