import React from "react";
import { Text, View, FlatList } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import Items from "./items";
import { mobileSize } from "@src/utils/screensize-helper";

function Rewards() {
  const styles = useStyles();
  const isMobile = mobileSize();

  const array = [
    {
      source: images["gift-card"],
      title: "Redeem Gift Cards",
      subtitle: "Imagine getting your hands on your favourite gift cards just by playing your favourite games. With Gameworkz Rewardz, it's not just a dream—it's a reality. Redeem popular gift cards from leading retailers with your GWz Gold Coins."
    },
    {
      source: images["redeem-cash"],
      title: "Cash Back Bonanza",
      subtitle: "Your play deserves to be rewarded in cash. Exchange your GWz Gold Coins for cash and watch your earnings grow giving you even more reasons to play and earn."
    }
  ];

  return (
    <View style={styles.container}>
      <Text style={styles.t_title} >GAMEWORKZ REWARDZ</Text>
      <Text style={styles.t_subtitle} >
      At Gameworkz Rewardz, we believe in treating our players like royalty. That's why we've created the ultimate 
      Rewards Hub, where you can unlock a world of exciting benefits, from gift cards to cash back!
      </Text>
      <Text style={styles.t_subtitle}>Get ready to embark on a thrilling journey of rewards and entertainment like no other.</Text>
      <View style={styles.v_banners}>
        <FlatList
          data={array}
          scrollEnabled={false}
          style={{width: "100%"}}
          key={Math.random().toString()}
          numColumns={isMobile ? 1 : 3}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({item, index}) => {
            return <Items key={index.toString()} item={item} />;
          }} />
      </View>
    </View>
  );
}

export default Rewards;
