import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 564,
      width: "100%",
      alignItems: "center", 
      paddingHorizontal: "13%",
      justifyContent: "center",
      backgroundColor: colors.violet2,
    },
    image_bg: { width: "100%", height: "100%", position: "absolute" },
    v_details: {flex: 1, width: "100%", justifyContent: "center"},
    t_title: { width: "100%",
      fontFamily: "Montserrat-Bold", fontSize: 32, textAlign: "center",
      lineHeight: 39, color: colors.white
    },
    t_user: {
      fontFamily: "Montserrat", fontWeight: "600", fontSize: 20,
      lineHeight: 24, color: colors.white
    },
    v_row_gold: {flexDirection: "row", alignItems: "center", marginTop: 15},
    t_yourbalance: {
      fontFamily: "Montserrat", fontWeight: "600", fontSize: 14,
      lineHeight: 17, color: colors.gold1
    },
    v_balance: {width: 287, height: 46, backgroundColor: "white", marginLeft: 10, paddingHorizontal: 4,
      borderRadius: 3, flexDirection: "row", alignItems: "center", justifyContent: "flex-start"},
    image_gold: { width: 36, height: 32 },
    t_notes: {
      fontFamily: "Montserrat", 
      fontSize: 12,
      lineHeight: 24,
      color: colors.white,
    },
    v_row_input: { flexDirection: "row", alignItems: "center", marginTop: 25},

    ti_style: {marginTop: 0, marginLeft: 10, maxWidth: 269},
    ti_borderStyle: {width: 269, height: 45, backgroundColor: "white", paddingHorizontal: 0, borderRadius: 3, marginTop: 0},
    btn_redeem: {
      width: 191, 
      height: 35,
      marginLeft: 20,
      backgroundColor: colors.green1,
    },
    label_redeem: { fontFamily: "Montserrat", fontWeight: "600", 
      color: colors.white, fontSize: 14, lineHeight: 20 },
    t_convert: { fontFamily: "Montserrat-Medium", color: colors.gold1, fontSize: 14, lineHeight: 20, marginTop: 15 },
    t_fees: { fontFamily: "Montserrat-Medium", fontStyle: "italic",
      color: colors.white, fontSize: 14, lineHeight: 20, marginTop: 20 },
  },
  {
    [DEVICE_SIZES.LG]: {
      label_redeem: { color: colors.white, fontSize: 12, lineHeight: 15 },
      t_fees: { color: colors.white, fontSize: 12, lineHeight: 15 },
    },
    [DEVICE_SIZES.MD]: {
      container: { minHeight: 200, paddingHorizontal: 18},
      v_details: { alignItems: "flex-start"},
      t_title: { fontSize: 30, lineHeight: 32 },
      t_yourbalance: { fontSize: 14, lineHeight: 15 },
      v_row_gold: {flexDirection: "column", alignItems: "flex-start", marginTop: 15},
      v_row_input: {flexDirection: "column", alignItems: "flex-start", marginTop: 15},
      ti_style: {marginTop: 20, marginLeft: 0, width: 200 },
      ti_borderStyle: { width: 200 },
      v_balance: {width: 200, marginLeft: 0, marginTop: 20 },
      btn_redeem: {
        width: 126, 
        height: 34,
        marginTop: 20,
        marginLeft: 0
      },
      label_redeem: { fontSize: 12, lineHeight: 14 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { minHeight: 200, paddingHorizontal: 18},
      v_details: { alignItems: "flex-start"},
      t_title: { fontSize: 24, lineHeight: 28 },
      t_yourbalance: { fontSize: 13, lineHeight: 15 },
      v_row_gold: {flexDirection: "column", alignItems: "flex-start", marginTop: 15},
      v_row_input: {flexDirection: "column", alignItems: "flex-start", marginTop: 15},
      ti_style: {marginTop: 20, marginLeft: 0, width: 200 },
      ti_borderStyle: { width: 200 },
      v_balance: {width: 200, marginLeft: 0, marginTop: 20 },
      btn_redeem: {
        width: 126, 
        height: 34,
        marginTop: 20,
        marginLeft: 0
      },
      label_redeem: { fontSize: 10, lineHeight: 14 },
    },
  }
);

export default useStyles;
