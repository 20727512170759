import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: { flexDirection: "row", alignItems: "center" },
    label: {  marginRight: 15 },
    v_label: { flexDirection: "row", justifyContent: "space-between" },
    t_input: {
      flex: 1,
      fontSize: 12,
      marginLeft: 15,
      paddingVertical: 0,
      color: colors.black,
      fontFamily: "Roboto",
    },
    inputSx: {
      width: 141,
      height: 34,
      fontSize: 12,
      border: 0.5,
      borderRadius: 0,
      backgroundColor: "white",
      borderColor: colors.black,
    },
    i_schedule: { width: 24, height: 24 },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [maxSize(DEVICE_SIZES.SM)]: {
      container: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      label: {
        marginBottom: 5,
      },
      inputSx: { width: "100%" },
    },
  }
);

export default useStyles;
