import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    // MENU COMPONENTS
    modal: { flex: 1 },
    dropdown: {
      minHeight: 112,
      position: "absolute",
      backgroundColor: "white",
      justifyContent: "center",
      borderColor: colors.gray,
    },
    flatlist: { marginTop: 1},
    item_separator: { height: 2, backgroundColor: colors.white1, },
    v_item: {
      height: 26,
      paddingLeft: 11,
      alignItems: "flex-start",
      justifyContent: "center",
    },
    v_item_active: { backgroundColor: colors.white1 },
    t_item: {
      fontSize: 10,
      lineHeight: 12,
      color: colors.black,
      fontFamily: "Montserrat-Medium",
    },
    btn_rows: {display: "none", flexDirection: "row", alignItems: "center", height: 24},
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      btn_rows: { display: "flex" }
    },
  }
);

export default useStyles;
