import React from "react";
import { Text, View, Image, Linking } from "react-native";
import { Button02 } from "@src/components/button";
import useStyles from "./styles.css";
import images from "@assets/images";

function WhatsNew() {
  const styles = useStyles();
  const onOpenFortune8 = () => {
    Linking.openURL("https://play.fortune8.online");
  };

  return (
    <View style={styles.container}>
      <View style={styles.v_details}>
        <Image style={styles.i_howitworks} source={images.swordmaster} resizeMode='stretch' />
        <View style={styles.divider} />
        <View style={styles.v_info}>
          <Text style={styles.t_title} >What’s New</Text>
          <Text style={[styles.t_subtitle, styles.t_subtitle1]} >Take a look at the new RPG game Sword Master in Fortune 8. Immerse yourself in a new adventure and create your own team of players ready to battle for victory!</Text>
          <Button02 onPress={onOpenFortune8} 
            style={styles.btn_signup} 
            labelStyle={styles.label_signup} label="Play Now" />
        </View>
      </View>
    </View>
  );
}

export default WhatsNew;
