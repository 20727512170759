import React from "react";
import { useRoute } from "@react-navigation/native";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import BankDetails from "./bank";
import Paypal from "./paypal";

const AccountDetails = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const type = route?.params?.type;

  if(type !== "redeem"){
    return null;
  }

  return (
    <View>
      <View style={styles.main}>
        <View style={{alignItems: "center", paddingHorizontal: 50}}>
          <Text style={styles.t_title}>SELECT HOW YOU WANT TO RECEIVE YOUR REWARD</Text>
          <Text style={[styles.t_note]}>Please provide your bank details. We do not permit deposit to another person/s to receive the cashback amount.</Text>
          <Text style={[styles.t_note]}>Please ensure the details are correct. By selecting SUBMIT the amount will be deducted from your GWz Gold Coin total.</Text>
        </View>
        <BankDetails />
        <Paypal />
      </View>
    </View>
  );
};

export default AccountDetails;
