import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

function Items({ item }: any) {
  const styles = useStyles();
  
  return (
    <View style={[styles.item_container]}>
      <Image style={styles.item_banner} source={item.source} resizeMode="stretch" />
      <View style={styles.item_details}>
        <Text style={styles.t_item_title} >
          {item.title}
        </Text>
        <Text style={styles.t_item_subtitle} >
          {item.subtitle}
        </Text>
      </View>
    </View>
  );
}

export default Items;