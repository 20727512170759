import React from "react";
import { FeaturesValue } from "@src/utils/mock-data";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";

type ItemsProps = {
  item: FeaturesValue;
  isEven: boolean;
}

function Items(props: ItemsProps) {
  const { item } = props;
  const styles = useStyles();
  
  return (
    <>
      <View style={[styles.item_container]}>
        <Image style={styles.item_banner} source={images[item.banner]} resizeMode="stretch" />
        <View style={styles.item_details}>
          <Text style={styles.t_item_title} >
            {item.name}
          </Text>
          <Text style={styles.t_item_subtitle} >
            {item.description}
          </Text>
        </View>
      </View>
      {props.isEven && <View style={styles.divider} />}
    </>
  );
}

export default Items;