import React from "react";
import { selectAuthSession, selectedCoinBalance } from "@src/ducks/slices/auth.slice";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Button02 } from "@src/components/button";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";

const Account = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const session = useAppSelector(selectAuthSession);
  const balance = useAppSelector(selectedCoinBalance);

  return (
    <View style={styles.v_myaccount}>
      <View>
        <Text style={styles.t_title}>MY ACCOUNT</Text>
        <View style={styles.v_field}>
          <Text style={styles.t_field} >USER NAME:</Text>
          <Text style={[styles.t_field_value, 
            { fontFamily: "Montserrat-Medium", color: colors.colorPrimary }]} >{session.displayName}</Text>
        </View>
        <View style={styles.v_field}>
          <Text style={styles.t_field} >USER ID #:</Text>
          <Text style={styles.t_field_value} >{session.id}</Text>
        </View>
        <View style={styles.v_field}>
          <Text style={styles.t_field} >E-MAIL ADDRESS:</Text>
          <Text style={[styles.t_field_value, 
            {fontFamily: "Montserrat-Medium", color: colors.colorPrimary }]} >{session.emailAddress}</Text>
        </View>
      </View>
      <View style={{alignItems: "flex-end"}}>
        <View style={styles.v_row_gwz}>
          <Text style={styles.t_gwzgold} >GWz GOLD COIN BALANCE</Text>
          <View style={styles.v_balance}>
            <Image style={styles.image_gold} source={images["ic_gold"]} resizeMode="contain" />
            <Text style={[styles.t_balance, { marginLeft: 7}]} >{balance.GOLD}</Text>
          </View>
        </View>
        <View style={styles.v_row_bonus}>
          <Text style={styles.t_gwzgold} >UNQUALIFIED COINS</Text>
          <View style={styles.v_balance}>
            <Text style={[styles.t_balance ]} >{balance["GOLD BONUS"]}</Text>
          </View>
        </View>
        <Button02 
          onPress={() => navigation.navigate("Redeem")}
          style={styles.btn_redeem} label="Redeem" 
          labelStyle={styles.label_redeem} />
      </View>
    </View>
  );
};

export default Account;
