import React from "react";
import { Text, View, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";

function WelcomeContent() {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Image style={styles.image_started} source={images["play-redeem"]} resizeMode="stretch" />
      <View style={styles.v_requirements}>
        <Image style={styles.image_bg} source={images["redeem-bg"]} resizeMode="cover" />
        <View style={styles.v_redeem_requirements}>
          <Text style={styles.t_title} >REDEMPTION REQUIREMENTS</Text>
          <Text style={[styles.t_notes, { marginTop: 15}]} >1. You must have an existing Gameworkz Fortune 8 Account.</Text>
          <Text style={styles.t_notes} >2. You must have signed up with a valid e-mail address and password.</Text>
          <Text style={styles.t_notes} >3. Your account must be at Emerald Tier Level or above.</Text>
          <Text style={styles.t_notes} >4. You must be 18+ year of age.</Text>
          <Text style={styles.t_notes} >5. Your location is not restricted from Cashback Rewards or Sweepstakes Programs.</Text>
          <Text style={styles.t_notes} >6. You agree to Gameworkz Rewardz policies and Terms & Conditions.</Text>
        </View>
      </View>
    </View>
  );
}

export default WelcomeContent;
