import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
    },
    flex1: { flex: 1 },
    image_background: {width: "100%", height: "100%", position: "absolute"},
    image_logo: {width: 120, height: 104, marginTop: 60},
    t_title: { fontFamily: "Montserrat-Bold", textAlign: "center", fontSize: 30, lineHeight: 32, color: colors.white, marginTop: 25 },
    t_note_title: { fontFamily: "Montserrat-Bold", color: colors.colorPrimary, fontSize: 16, lineHeight: 20, marginTop: 20 },
    t_note: { fontFamily: "Montserrat", fontSize: 16, lineHeight: 20, color: colors.black },
    v_pdf: {
      padding: 15,
      width: "70%", 
      minHeight: 1700, 
      marginTop: 50,
      maxWidth: 1169,
      marginBottom: 50,
      backgroundColor: colors.white
    },
    v_loading: { height: 200 },
  },
  {
    [DEVICE_SIZES.LG]: {
    },
    [DEVICE_SIZES.MD]: {
      t_title: { fontSize: 47, lineHeight: 55 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { height: 279, paddingHorizontal: 20},
      t_title: { fontSize: 22, lineHeight: 25 },
      t_note_title: { fontSize: 10, lineHeight: 14 },
      t_note: { fontSize: 10, lineHeight: 14 },
      v_pdf: { width: "100%" },
    },
  }
);

export default useStyles;
