import React from "react";
import { Text, View, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import { ForgotContext } from "./provider";
import _ from "lodash";


function EmailSent() {
  const styles = useStyles();
  const { data } = React.useContext(ForgotContext);

  if(_.isEmpty(data)){
    return null;
  }

  return (
    <View style={[styles.container]}>
      <Image style={styles.i_email} source={images["ic_mail"]} resizeMode="contain"/>
      <Text style={[styles.t_verify]} >We've sent password reset instructions to your email.</Text>
    </View>
  );
}

export default EmailSent;
