import React, { useCallback, useState } from "react";
import ErrorMessage from "./components/modals/error-sideway";
import SessionExpired from "./components/session-expired";
import SuccessTop from "./components/modals/success-top";

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  onErrorMessage: (value: string) => void;
  onSuccessMessage: (value: string) => void;
}

export const AuthContext = React.createContext<ContextValue>(
  {} as ContextValue
);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { isSessionExpired, resetError } = {} as any;

  const onErrorMessage = useCallback(setError, [error]);
  const onSuccessMessage = useCallback(setSuccess, [success]);

  return (
    <AuthContext.Provider value={{ onErrorMessage, onSuccessMessage }}>
      {children}
      <SessionExpired visible={isSessionExpired} onPress={resetError} />
      <SuccessTop
        message={success}
        visible={success !== null}
        onClose={() => setSuccess(null)}
      />
      <ErrorMessage
        message={error}
        visible={error !== null}
        onClose={() => setError(null)}
      />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
