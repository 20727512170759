import React, { useRef, memo, useState } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
} from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { InputProps2 } from "./types";
import colors from "@assets/colors";
import _ from "lodash";

function TextInput02(props: InputProps2) {
  const textRef = useRef<any>();
  const [isFocus, setFocus] = useState(false);
  const { label, onChangeText, error, hasPassword, onBlur } = props;
  const [showPass, setShowPass] = useState(!!hasPassword);
  const errLabelStyle = error ? { color: colors.red } : {};
  const errBorderStyle: any = error && {
    borderColor: colors.red,
  };

  const handleOnBlur = () => {
    setFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <View style={[styles.marginTop20, props.style]}>
      {!!label && (
        <View style={styles.v_label}>
          <Text style={[styles.label, props.labelStyle, errLabelStyle]}>
            {props.label}
          </Text>

          {_.isEmpty(error) ? null : (
            <Text style={[styles.txtError]}>{error}</Text>
          )}
        </View>
      )}
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => textRef.current.focus()}
        style={[styles.border, props.borderStyle, errBorderStyle]}
      >
        <TextInput
          ref={textRef}
          style={[styles.input, props.inputStyle]}
          returnKeyType="done"
          autoComplete="off"
          autoCorrect={false}
          value={props.value}
          numberOfLines={1}
          multiline={props.multiline}
          secureTextEntry={showPass}
          maxLength={props.maxLength}
          onBlur={handleOnBlur}
          onFocus={() => setFocus(true)}
          keyboardType={props.keyboardType}
          placeholderTextColor={colors.border1}
          placeholder={isFocus ? "" : props.placeholder}
          onSubmitEditing={Keyboard.dismiss}
          onChangeText={onChangeText}
        />
        {hasPassword && (
          <TouchableOpacity
            onPress={() => setShowPass(!showPass)}
            style={styles.btn_icon} >
            <Icon
              name={showPass ? "eye-off" : "eye"}
              color={colors.gray}
              size={24}
            />
          </TouchableOpacity>
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: "Inter",
    color: colors.black,
  },
  v_label: { flexDirection: "row", justifyContent: "space-between" },
  marginTop20: { marginTop: 20, flex: 1 },
  border: {
    marginTop: 8,
    minHeight: 42,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    borderColor: colors.black,
  },
  input: {
    flex: 1,
    width: "100%",
    height: "100%",
    color: colors.black,
    paddingHorizontal: 12,
  },
  input2: {
    flex: 1,
    fontFamily: "Roboto",
    fontSize: 14,
    color: colors.black,
    paddingVertical: 0,
    marginLeft: 3,
  },
  txtError: {
    fontSize: 13,
    color: colors.red,
    fontStyle: "italic",
    fontFamily: "Inter",
  },
  btn_icon: { position: "absolute", width: 24, height: 24, right: 10 },
});

export default memo(TextInput02);
