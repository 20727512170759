import React from "react";
import ForgotProvider from "./provider";
import EnterEmail from "./enter-email";
import EmailSent from "./email-sent";

function ForgotPassword() {

  return (
    <ForgotProvider>
      <>
        <EnterEmail />
        <EmailSent />
      </>
    </ForgotProvider>
  );
}

export default ForgotPassword;