import React, { useState } from "react";
import { TextInput02 } from "@src/components/textinput";
import { Button02 } from "@src/components/button";
import { useAuthService } from "@src/ducks/hooks";
import { View, Text } from "react-native";
import useStyles from "./styles.css";
import ErrorView from "../error";

import _ from "lodash";
import { useNavigation } from "@react-navigation/native";

function Login() {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const errorRef = React.useRef<any>(null);
  const [username, setEmail] = useState("");
  const [error, setError] = useState<any>({});
  const [password, setPassword] = useState("");
  const { onSignin, isLoading } = useAuthService();

  const onLogin = () => {
    const Err: any = {};
    if (!username) {
      Err.username = "Required";
    } else if (!password) {
      Err.password = "Required";
    }

    setError(Err);

    if (_.isEmpty(Err)) {
      onSignin({username, password});
    }
  };

  React.useEffect(() => {
    setError({});
    errorRef.current?.onClear();
  }, [username, password]);


  return (
    <View style={styles.container}>
      <View style={styles.v_details}>
        <Text style={styles.t_login}>Log In</Text>
        <Text style={styles.t_requirement}>Requirements</Text>
        <Text style={[styles.t_notes, { marginTop: 10}]}>1. You must be 18+ years of age.</Text>
        <Text style={styles.t_notes}>
        2. Your location is not restricted in participating and playing promotional games, competitions and sweepstakes rewards.
        </Text>
        <Text style={styles.t_notes}>3. You agree to Gameworkz Rewardz{" "}
          <Text onPress={() => navigation.navigate("TermsCondition")} 
            style={styles.t_terms}>Terms & Conditions.</Text></Text>
      </View>
      <ErrorView ref={errorRef} />
      <View style={styles.v_inputs}>
        <TextInput02 
          label="Email"
          value={username}
          placeholder="me@email.com"
          error={error?.username}
          onChangeText={setEmail}
          keyboardType="email-address" />
        <TextInput02 
          hasPassword
          label="Password"
          value={password}
          placeholder="Password"
          error={error?.password}
          onChangeText={setPassword} />
        <Button02 
          label="SUBMIT"
          onPress={onLogin}
          isLoading={isLoading}
          labelStyle={{color: "white"}}
          style={styles.btn_submit} />
        <Text suppressHighlighting
          onPress={() => navigation.navigate("ForgotPassword")}
          style={styles.t_forgot_password}>I forgot my password</Text>
      </View>
    </View>
  );
}

export default Login;