import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, marginTop: 40 },
    cardContainer: {
      minHeight: 51,
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    cardHeader: {
      minHeight: 51,
      paddingHorizontal: 10,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontFamily: "Montserrat-Medium",
      fontSize: 20,
      lineHeight: 22,
      color: colors.black,
    },
    v_info: {
      minHeight: 94,
      paddingHorizontal: 10,
      paddingBottom: 15,
      paddingTop: 15,
      borderTopWidth: 1,
      borderTopColor: colors.gray1
    },
    description: {
      fontFamily: "Montserrat",
      fontSize: 16,
      lineHeight: 20,
      color: colors.black,
      paddingBottom: 10,
    },
    v_separator: {
      height: 40,
    }
  },
  {
    [DEVICE_SIZES.LARGE_DEVICE]: {
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { width: "100%" },
    },
  }
);

export default useStyles;
