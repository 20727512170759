import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      alignItems: "center", 
      backgroundColor: colors.white
    },
    v_requirements: {width: "100%", height: 457, alignItems: "center", justifyContent: "center"},
    v_redeem_requirements: {width: "85%", height: 275, backgroundColor: "white",
      alignItems: "flex-start", justifyContent: "center", paddingHorizontal: "8%"},
    t_title: {
      fontFamily: "Montserrat", fontWeight: "500", fontSize: 24, 
      lineHeight: 29, color: colors.colorPrimary
    },
    t_notes: {
      fontFamily: "Montserrat", fontSize: 16, 
      lineHeight: 24, color: colors.black,
    },
    v_right_content: {flexDirection: "row", alignItems: "center"},
    btn_started: { backgroundColor: colors.colorPrimary, width: 130, height: 41, marginVertical: 50 },
    image_started: { width: "100%", height: 660 },
    image_bg: { width: "100%", height: "100%", position: "absolute" },
  },
  {
    [DEVICE_SIZES.XL]: {
      image_started: { width: "100%", height: 980 },
    },
    [DEVICE_SIZES.MD]: {
      v_requirements: { height: 355 },
      v_redeem_requirements: { width: "90%", paddingHorizontal: "4%", minHeight: 295},
      t_notes: { fontSize: 16, lineHeight: 24 },
      image_started: { width: "100%", height: 400, resizeMode: "stretch" },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_requirements: { height: 355 },
      v_redeem_requirements: { width: "90%", paddingHorizontal: "4%", minHeight: 295},
      t_title: { fontSize: 24, lineHeight: 26 },
      t_notes: { fontSize: 12, lineHeight: 17 },
      image_started: { width: "100%", height: 240, resizeMode: "stretch" },
    },
  }
);

export default useStyles;
