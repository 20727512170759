import React from "react";
import { selectedRedeemData } from "@src/ducks/slices/dashboard.slice";
import { useRoute } from "@react-navigation/native";
import { useInputHelper } from "@src/utils/inputs-helper";
import { useAppSelector } from "@src/ducks/ducksHook";
import _ from "lodash";
import { useDashboardService } from "@src/ducks/hooks";

type StateValue = {
  goldAmount: string;
  ids: any[];
  bankFirstName: string;
  bankLastName: string;
  bankName: string;
  bankSwiftCode: string;
  bankAccountNumber: string;
  bankIbanIbcCode: string;
  isTermsCheck: boolean;
  "paypalEmail": string;
  "paypalFirstName": string;
  "paypalLastName": string;
  isPaypalTermsCheck: boolean;
  redeemStatus: any;
}

const initialState = {
  goldAmount: "",
  bankFirstName: "",
  bankLastName: "",
  bankName: "",
  bankSwiftCode: "",
  bankAccountNumber: "",
  bankIbanIbcCode: "",
  "paypalEmail": "",
  "paypalFirstName": "",
  "paypalLastName": "",
  isTermsCheck: false,
  isPaypalTermsCheck: false,
  "redeemStatus": {
    "id": 1,
    "name": "NEW REQUEST"
  },
  ids: [],
} as StateValue;

interface ContextValue {
  state: any;
  onDispatch: (value: string) => (value: any) => void;
}

export const RequestFormContext = React.createContext<ContextValue>(
  {} as ContextValue
);

interface ProviderProps {
  children: React.ReactElement;
}

const RequestFormProvider = ({ children }: ProviderProps) => {
  const route = useRoute<any>();
  const goldAmount = route?.params?.amount;
  const { onResetDashboard } = useDashboardService();
  const requestsucces: any = useAppSelector(selectedRedeemData);
  const {state, onDispatch, onSetInitial } = useInputHelper({...initialState, goldAmount});

  React.useEffect(() => {
    onResetDashboard();
  },[goldAmount]);

  React.useEffect(() => {
    if(!_.isEmpty(requestsucces)){
      onSetInitial({...initialState });
    }
  },[requestsucces]);

  return (
    <RequestFormContext.Provider value={{ state, onDispatch }}>
      {children}
    </RequestFormContext.Provider>
  );
};

export default RequestFormProvider;
