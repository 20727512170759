import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles";

function Header({ item }: any) {
  const styles = useStyles();

  return (
    <View style={styles.v_header}>
      <View style={[styles.tab]}>
        <Text style={[styles.tabLabel]}>{item.label1}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab]}>
        <Text style={[styles.tabLabel]}>{item.label2}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab]}>
        <Text style={[styles.tabLabel]}>{item.label3}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab]}>
        <Text style={[styles.tabLabel]}>{item.label4}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab]}>
        <Text style={[styles.tabLabel]}>{item.label5}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab]}>
        <Text style={[styles.tabLabel]}>{item.label6}</Text>
      </View>
    </View>
    
  );
}

export default Header;