import { useState, useCallback } from "react";
import {
  authActions,
  selectedAuthSession,
} from "@src/ducks/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { CurrentApi } from "../request";

export const useFetchGet = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const session: any = useAppSelector(selectedAuthSession);

  const runRequest = useCallback(
    async (routes: string) => {
      try {
        setData(null);
        setLoading(true);
        CurrentApi.setToken(session.token);
        const result = await CurrentApi.get(`${routes}`);

        setData(result);
        setLoading(false);
      } catch (err: any) {
        const message = err?.error?.message || err.error || "Something went wrong";
        setError({ message });
        setLoading(false);

        if (message.includes("Unauthorized")) {
          dispatch(authActions.logout());
        }
      }
    },
    [session.token, dispatch]
  );

  return {
    loading,
    data,
    error,
    runRequest,
  } as any;
};
