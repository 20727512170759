import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    main: { flex: 1, marginTop: 50, paddingBottom: 40 },
    t_pleasecheck: {fontFamily: "Montserrat-Bold", fontSize: 24, lineHeight: 30, color: colors.black },
    t_pleasecheck2: { 
      display: "none", fontFamily: "Montserrat-Bold", width: "100%",
      fontSize: 24, lineHeight: 30, color: colors.black, marginTop: 20 },
    t_title: {fontFamily: "Montserrat-Bold", fontSize: 20, color: colors.colorPrimary },
    t_note: { fontFamily: "Montserrat", fontSize: 14, lineHeight: 25, color: colors.black, marginTop: 20 },
    t_subtitle: {  fontFamily: "Montserrat-Bold", fontSize: 16, color: colors.black },
    v_product_row: {width: "100%", marginTop: 50},

    // PRODUCT
    product_container: { flexDirection: "row", alignItems: "center", marginTop: 10 },
    item_banner: { width: 333, height: 161 },
    item_details: { paddingTop: 15, paddingLeft: 20, alignItems: "flex-start"},
    v_field: {flexDirection: "row", alignItems: "center" },
    v_field2: {flexDirection: "row", alignItems: "center", marginTop: 20 },
    t_field: {fontFamily: "Montserrat-Bold", fontSize: 20, color: colors.black, marginRight: 10},
    v_value: { width: 192, height: 34, borderWidth: 1, borderColor: colors.black,  justifyContent: "center" },
    t_value: {
      fontFamily: "Montserrat", 
      fontSize: 16, 
      lineHeight: 19, 
      marginLeft: 10,
      color: colors.black,
    },

    // DESCRIPTION
    description_container: { flexDirection: "row", alignItems: "center", justifyContent: "space-between"},
    t_convert: { fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.colorPrimary, marginTop: 20},
    v_flex_row_submit: {flexDirection: "row", alignItems: "center", marginTop: 15, justifyContent: "space-between"},
    btn_box: {
      width: 34, height: 34, borderWidth: 1, 
      borderColor: colors.black, backgroundColor: colors.gray1,
      alignItems: "center", justifyContent: "center"},
    t_agree: {fontFamily: "Montserrat", fontSize: 12, lineHeight: 14, color: colors.black, marginLeft: 15},
    btn_submit: { width: 175, height: 45, borderRadius: 3, backgroundColor: colors.green1, marginTop: 20 },
    label_submit: {fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.white },
    
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      main: { alignItems: "center",  paddingHorizontal: "12%", marginTop: 30 },
    },
    [DEVICE_SIZES.MD]: {
      main: { paddingHorizontal: 40 },
      v_product_row: { flexDirection: "column", alignItems: "center", marginTop: 30},
      t_pleasecheck: { display: "none" },
      t_pleasecheck2: { display: "flex", fontSize: 16, lineHeight: 18 },

      // PRODUCT
      product_container: { width: "100%", flexDirection: "column", marginTop: 0  },
      item_details: {width: "100%", paddingLeft: 0, marginTop: 10 },
      v_field: { alignItems: "center", width: "100%" },
      v_field2: { alignItems: "center", width: "100%", marginTop: 8 },
      t_field: {fontFamily: "Montserrat-Bold", fontSize: 20, color: colors.black, marginRight: 10},
      
      // DESCRIPTION
      description_container: { width: "100%", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"},
      v_flex_row_submit: {flexDirection: "column", alignItems: "flex-start" },
      btn_box: { width: 30, height: 30 },
      t_agree: { fontSize: 12, lineHeight: 14 },
      btn_submit: { marginTop: 25 }
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      main: { paddingHorizontal: 20, marginTop: 30 },
      v_product_row: { flexDirection: "column", alignItems: "center", marginTop: 30},
      t_pleasecheck: { display: "none" },
      t_pleasecheck2: { display: "flex", fontSize: 16, lineHeight: 18 },

      // PRODUCT
      product_container: { width: "100%", flexDirection: "column", marginTop: 0  },
      item_banner: { height: 152 },
      item_details: { width: "100%", paddingLeft: 0, marginTop: 10 },
      v_field2: { alignItems: "center", width: "100%", marginTop: 8 },
      t_field: { fontSize: 12  },
      t_value: { fontSize: 12  },

      // DESCRIPTION
      description_container: { width: "100%", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"},
      t_subtitle: { fontSize: 14 },
      v_flex_row_submit: {flexDirection: "column", alignItems: "flex-start" },
      btn_box: { width: 28, height: 28 },
      t_agree: { fontSize: 12, lineHeight: 14, maxWidth: 220 },
      btn_submit: { marginTop: 25 }
    },
  }
);

export default useStyles;
