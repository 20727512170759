import { Asset } from "expo-asset";
import { ImageRequireSource } from "react-native";

export interface imageType {
  // Assets
  favicon: ImageRequireSource;

  // Dashboard
  "welcome-rewardz": ImageRequireSource;
  "reward-logo": ImageRequireSource;
  "welcome": ImageRequireSource;
  "ic_menu": ImageRequireSource;
  "ic_howitworks": ImageRequireSource;
  "gift_card": ImageRequireSource;
  "promotion": ImageRequireSource;
  "feature_item": ImageRequireSource;
  "ic_instagram": ImageRequireSource;
  "ic_facebook": ImageRequireSource;
  "ic_youtube": ImageRequireSource;
  "ic_indeed": ImageRequireSource;
  "play_fortune": ImageRequireSource;
  "ic_mail": ImageRequireSource;
  "bg_policy": ImageRequireSource;
  "claim": ImageRequireSource;

  // REWARDS
  "gift-card": ImageRequireSource;
  "redeem-cash": ImageRequireSource;

  // REDEEM
  "play-redeem": ImageRequireSource;
  "redeem-bg": ImageRequireSource;
  "ic_gold": ImageRequireSource;

  // HOME
  "rewardz": ImageRequireSource;
  "logo": ImageRequireSource;
  "bg_dashboard": ImageRequireSource;

  //GIFT CARDS
  "gc50": ImageRequireSource;
  "gc100": ImageRequireSource;
  "amazon": ImageRequireSource;

  //FEATURES
  "swordmaster": ImageRequireSource;
  "candydreams": ImageRequireSource;
  "hot_blazer": ImageRequireSource;
  "sword_master": ImageRequireSource;
  "talismanoffortune": ImageRequireSource;
  "TC_tabela-v2": ImageRequireSource;
  "The-Great-Sevens-t": ImageRequireSource;

  // PROMOTIONAL
  "promo_swordmaster": ImageRequireSource;
}

const images: imageType = {
  // Assets
  favicon: require("../favicon.png"),
  
  // Dashboard
  "welcome-rewardz": require("./dashboard/welcome-rewardz.webp"),
  "reward-logo": require("./dashboard/reward-logo.png"),
  "welcome": require("./dashboard/welcome.webp"),
  "ic_menu": require("./dashboard/ic_menu.png"),
  "ic_howitworks": require("./dashboard/ic_howitworks.png"),
  "gift_card": require("./dashboard/gift_card.png"),
  "promotion": require("./dashboard/promotion.png"),
  "feature_item": require("./dashboard/feature_item.png"),
  "play_fortune": require("./dashboard/play_fortune.png"),
  "ic_mail": require("./dashboard/ic_mail.png"),
  "bg_policy": require("./dashboard/bg_policy.webp"),
  "claim": require("./dashboard/claim.png"),

  //SOCIAL MEDIA
  "ic_instagram": require("./social/ic_instagram.png"),
  "ic_facebook": require("./social/ic_facebook.png"),
  "ic_youtube": require("./social/ic_youtube.png"),
  "ic_indeed": require("./social/ic_indeed.png"),
  
  // REWARDS
  "gift-card": require("./rewards/gift-card.webp"),
  "redeem-cash": require("./rewards/redeem-cash.webp"),

  // REDEEM
  "play-redeem": require("./redeem/play-redeem.webp"),
  "redeem-bg": require("./redeem/redeem-bg.webp"),
  "ic_gold": require("./redeem/ic_gold.png"),

  // HOME
  "rewardz": require("./home/rewardz.png"),
  "logo": require("./home/logo.webp"),
  "bg_dashboard": require("./home/bg_dashboard.png"),

  //GIFT CARDS
  "gc50": require("./gift-cards/gc50.webp"),
  "gc100": require("./gift-cards/gc100.webp"),
  "amazon": require("./gift-cards/amazon.webp"),

  //FEATURES
  "swordmaster": require("./features/swordmaster.webp"),
  "candydreams": require("./features/candydreams.webp"),
  "hot_blazer": require("./features/hot_blazer.webp"),
  "sword_master": require("./features/sword_master.webp"),
  "talismanoffortune": require("./features/talismanoffortune.webp"),
  "TC_tabela-v2": require("./features/TC_tabela-v2.webp"),
  "The-Great-Sevens-t": require("./features/The-Great-Sevens-t.webp"),
  
  //PROMOTIONAL
  "promo_swordmaster": require("./promotional/promo_swordmaster.webp"),
} as imageType;

// image preloading
export const imageAssets = Object.keys(images).map((key) =>
  Asset.fromModule(images[key as keyof imageType]).downloadAsync()
);
export default images;
