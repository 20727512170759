import React from "react";
import { useRoute } from "@react-navigation/native";
import { Text, View } from "react-native";
import Description from "./description";
import useStyles from "./styles.css";
import Product from "./product";

const PromotionalProduct = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const type = route?.params?.type;

  if(type !== "promotional"){
    return null;
  }

  return (
    <View>
      <View style={styles.main}>
        <View style={{ width: "100%" }}>
          <Text style={styles.t_title}>You have selected to redeem a promotional product</Text>
          <Text style={[styles.t_note]}>Thank you for selecting a special promotional product, our team will get in touch with you once your request is processed.</Text>
          <Text style={[styles.t_note, { marginTop: 0}]}>You can keep track progress 
          visit <Text style={styles.t_semibold}>My Account</Text>. By selecting <Text style={styles.t_semibold}>SUBMIT</Text> the amount will be deducted from your Gwz Gold Coin total.</Text>
        </View>
        <View style={styles.v_product_row}>
          <Product />
          <Description />
        </View>
      </View>
    </View>
  );
};

export default PromotionalProduct;
