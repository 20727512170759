import React from "react";
import { selectedRedeemStatus } from "@src/ducks/slices/dashboard.slice";
import RequestVerification from "./sending-content/request-verification";
import RequestSent from "./sending-content/request-sent";
import PlayFortune8 from "../my-account/play-fortune8";
import { useAppSelector } from "@src/ducks/ducksHook";
import ConnectUs from "../dashboard/connect-with-us";
import PromotionalProduct from "./promotional-product";
import GiftCardProduct from "./gift-card-product";
import AccountDetails from "./account-details";
import RequestForm from "./request-form";
import InputAmount from "./input-amount";

const Screens = () => {
  const redeemStatus = useAppSelector(selectedRedeemStatus);

  if(redeemStatus === "REQUEST SUBMIT"){
    return <RequestVerification />;
  }else if(redeemStatus === "EMAIL VERIFIED"){
    return <RequestSent />;
  }else{
    return (
      <>
        <RequestForm />
        <InputAmount />
        <AccountDetails />
        <GiftCardProduct />
        <PromotionalProduct />
        <PlayFortune8 style={{paddingHorizontal: "12%", marginTop: 50}} />
        <ConnectUs />
      </>
    );
  }
};

export default React.memo(Screens);
