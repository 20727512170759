import React from "react";
import { View, Text, ViewStyle, Pressable } from "react-native";
import MenuDropdown, { MeasureValue } from "./Menu";
import useStyles from "./styles.css";

type DropdownProps = {
  name?: string;
  placeholder?: string;
  value?: string | number;
  color?: string;
  iconSize?: number;
  options?: any[];
  style?: ViewStyle;
  container?: ViewStyle;
  renderItem?: any;
  ItemSeparatorComponent?: any;
  onSelected: (item: any) => void;
  renderBase?: any;
};

function Dropdown(props: DropdownProps) {
  const styles = useStyles();
  const pressableRef = React.useRef<any>(null);
  const [isFocus, setFocus] = React.useState<boolean>(false);
  const [measure, setMesasure] = React.useState<MeasureValue>(
    {} as MeasureValue
  );

  const RenderBase = () => {
    if (typeof props.renderBase === "function") {
      return props.renderBase({
        value: props.value,
      });
    }

    return (
      <>
        <Text selectable={false} style={styles.t_label}>
          {props.value || props.placeholder}
        </Text>
        <View style={styles.v_cursor} >
          <View style={[styles.triangle]} />
        </View>
      </>
    );
  };

  React.useEffect(() => {
    if (pressableRef.current) {
      pressableRef.current?.measure(
        (
          fx: any,
          fy: any,
          width: number,
          height: any,
          px: number,
          py: number
        ) => {
          setMesasure({
            x: px,
            y: py,
            width,
            height,
          });
        }
      );
    }
  }, [pressableRef.current, isFocus]);

  return (
    <Pressable
      ref={pressableRef}
      onPress={() => setFocus(true)}
      style={[styles.container, props.style]}
    >
      {RenderBase()}
      <MenuDropdown
        visible={isFocus}
        measures={measure}
        value={props.value}
        options={props.options}
        container={props.container}
        renderItem={props.renderItem}
        onSelected={props.onSelected}
        onClose={() => setFocus(false)}
        ItemSeparatorComponent={props.ItemSeparatorComponent}
      />
    </Pressable>
  );
}

export default Dropdown;
