import React from "react";
import { PromotionalImages } from "@src/utils/mock-data";
import { useRoute } from "@react-navigation/native";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

function Product() {
  const styles = useStyles();
  const route = useRoute<any>();
  const productId = route?.params?.id;
  const productName = route?.params?.productName;
  const description = route?.params?.description;
  
  return (
    <View style={[styles.item_container]}>
      <Image style={styles.item_banner} source={PromotionalImages[productId]} resizeMode="stretch" />
      <View style={styles.item_details}>
        <Text style={styles.t_item_title} >
          {productName}
        </Text>
        <Text style={styles.t_item_subtitle} >
          <Text style={{fontFamily: "Montserrat-Bold"}}>Job:{" "}</Text>
          {description}
        </Text>
        <Text style={styles.t_item_gold}>Gwz Gold Required</Text>
      </View>
    </View>
  );
}

export default Product;