import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    main: {
      flex: 1,
      marginTop: 50,
      // alignItems: "center",
    },
    container: {
      flex: 1,  
      marginTop: 50,
      paddingBottom: 50,
    },
    paypal_container: { flex: 1, marginTop: 30, paddingBottom: 30  },
    t_title: {fontFamily: "Montserrat-Bold", fontSize: 20, color: colors.colorPrimary },
    t_paypal_title: {fontFamily: "Montserrat-Bold", fontSize: 20, color: colors.colorPrimary },
    t_note: { fontFamily: "Montserrat", fontSize: 14, color: colors.black, marginTop: 20, textAlign: "center"},
    t_subtitle: {  fontFamily: "Montserrat-Bold", fontSize: 16, color: colors.black, marginTop: 30},
    v_flex_row: {flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between"},
    v_field: {flexDirection: "row", alignItems: "center", marginTop: 20},
    ti_containerStyle: { height: 34},
    ti_inputStyle: {width: 249, fontSize: 12},
    v_flex_row_submit: {flexDirection: "row", alignItems: "center", marginTop: 40, justifyContent: "space-between"},
    t_field: {fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.black, marginRight: 20},
    btn_box: {width: 34, height: 34, borderWidth: 1, borderColor: colors.black, alignItems: "center", justifyContent: "center"},
    t_agree: {fontFamily: "Montserrat", fontSize: 12, lineHeight: 14, color: colors.black, marginLeft: 15},
    btn_submit: {width: 167, height: 48, borderRadius: 3, backgroundColor: colors.green1},
    label_submit: {fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.white },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      main: { alignItems: "center" },
      container: { width: 900 },
      paypal_container: { width: 900 },
    },
    [DEVICE_SIZES.MD]: {
      container: {paddingHorizontal: 30, width: "100%", paddingBottom: 10},
      paypal_container: {paddingHorizontal: 30, width: "100%", paddingBottom: 50},
      v_flex_row: {flexDirection: "column", width: "100%" },
      t_title: { textAlign: "center"},
      v_field: { alignItems: "center", width: "100%" },
      t_field: { marginRight: 10 },
      ti_containerStyle: { flex: 1 },
      v_flex_row_submit: {flexDirection: "column", alignItems: "flex-start" },
      btn_box: { width: 32, height: 32 },
      t_agree: { fontSize: 14, lineHeight: 18 },
      btn_submit: { marginTop: 25 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: {paddingHorizontal: 30, width: "100%", paddingBottom: 10},
      paypal_container: {paddingHorizontal: 30, width: "100%", paddingBottom: 50},
      t_title: { textAlign: "center"},
      v_flex_row: {flexDirection: "column", width: "100%" },
      v_field: { alignItems: "center", width: "100%" },
      t_field: { marginRight: 10 },
      ti_containerStyle: { flex: 1 },
      v_flex_row_submit: {flexDirection: "column", alignItems: "flex-start" },
      btn_box: { width: 32, height: 32 },
      t_agree: { fontSize: 14, lineHeight: 18 },
      btn_submit: { marginTop: 25 },
    },
  }
);

export default useStyles;
