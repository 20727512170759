import React from "react";
import { selectedDashboardLoading } from "@src/ducks/slices/dashboard.slice";
import { useNavigation, useRoute } from "@react-navigation/native";
import { selectedCoinBalance } from "@src/ducks/slices/auth.slice";
import { useDashboardService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Pressable, Text, View } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { Button01 } from "@src/components/button";
import { RequestFormContext } from "../provider";
import useStyles from "./styles.css";
import colors from "@assets/colors";

const Description = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const productID = route?.params?.id;
  const goldAmount = route?.params?.amount;
  const navigation = useNavigation<any>();
  const productName = route?.params?.productName;
  const { onRedeemRequest } = useDashboardService();
  const balance = useAppSelector(selectedCoinBalance);
  const loading = useAppSelector(selectedDashboardLoading);
  const { state, onDispatch } = React.useContext(RequestFormContext);

  const disabled = React.useMemo(() => {
    if(goldAmount === 0){
      return true;
    }else if(Number(goldAmount) > balance.GOLD){
      return true;
    }else{
      return false;
    }
  },[goldAmount, balance.GOLD]);

  const onSubmit = () => {
    onRedeemRequest({
      productID,
      goldAmount,
      "redeemType": {
        "id": 4,
        "name": "PROMOTION PRODUCT"
      },
      "redeemStatus": {
        "id": 1,
        "name": "NEW REQUEST"
      },
    });
  };

  return (
    <View style={styles.description_container}>
      <Text style={styles.t_subtitle}>Please check your selection is correct.</Text>
      <View style={styles.v_field}>
        <Text style={styles.t_field} >Product Name:</Text>
        <View style={styles.ti_productname}>
          <Text style={styles.t_value} >{productName}</Text>
        </View>
      </View>
      <View style={styles.v_field}>
        <Text style={styles.t_field} >Amount:</Text>
        <View style={styles.ti_amount}>
          <Text style={styles.t_value} >{goldAmount}</Text>
        </View>
      </View>
      <Button01
        isLoading={loading}
        onPress={onSubmit} style={styles.btn_submit} 
        disabled={!state.isPaypalTermsCheck || disabled}
        label="SUBMIT" labelStyle={styles.label_submit} />

      <Text style={styles.t_convert}>1 GWZ GOLD = US$1.00</Text>
      <View style={styles.v_flex_row_submit}>
        <View style={{flexDirection: "row", alignItems: "center" }}>
          <Pressable onPress={() => onDispatch("isPaypalTermsCheck")(!state.isPaypalTermsCheck)} 
            style={[styles.btn_box, state.isPaypalTermsCheck && { backgroundColor: colors.black}]}>
            {state.isPaypalTermsCheck && <Icon name="check" size={25} color="white" />}
          </Pressable> 
          <Text style={styles.t_agree}>I agree to Gameworkz Rewardz 
            <Text onPress={() => navigation.navigate("TermsCondition")} 
              style={{fontFamily: "Montserrat-Bold"}}> Terms & Conditions</Text></Text>
        </View>
      </View>
      <Button01 
        isLoading={loading}
        onPress={onSubmit} style={styles.btn_submit2} 
        disabled={!state.isPaypalTermsCheck || disabled}
        label="SUBMIT" labelStyle={styles.label_submit} />
    </View>
  );
};

export default Description;
