import React from "react";
import { Pressable, StyleSheet, Image } from "react-native";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";
import { Button03Props } from "./types";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const Button03 = (props: Button03Props) => {
  const animated = useSharedValue(1);

  const onPressIn = React.useCallback(() => {
    animated.value = withTiming(props.scale || 0.9, { duration: 100 });
  }, [props.scale]);

  const onPressOut = () => {
    animated.value = withTiming(1, { duration: 100 });
  };

  const animateStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: animated.value,
        },
      ],
    };
  }, []);

  return (
    <AnimatedButton
      ref={props.ref}
      onPress={props.onPress}
      disabled={props.disabled}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[styles.buttonStyle, props.style, animateStyle]}
    >
      {props.children || (
        <Image
          source={props.source}
          resizeMode="stretch"
          style={styles.imgStyle}
        />
      )}
    </AnimatedButton>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  imgStyle: { width: "100%", height: "100%" },
});

export default React.memo(Button03);
