import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import Description from "./description";
import Product from "./product";
import { useRoute } from "@react-navigation/native";

const GiftCardProduct = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const type = route?.params?.type;

  if(type !== "giftcard"){
    return null;
  }

  return (
    <View>
      <View style={styles.main}>
        <View style={{ width: "100%" }}>
          <Text style={styles.t_title}>You have selected to redeem a digital gift card.</Text>
          <Text style={[styles.t_note]}>A digital gift card will be sent to you via e-mail our team will get in touch with you once your request is processed, processing time can</Text>
          <Text style={[styles.t_note, { marginTop: 0}]}>take 1-3 weeks. You can keep track progress visit My Account. By selecting SUBMIT the amount will be deducted from your GWz Gold Coin total.</Text>
        </View>
        <View style={styles.v_product_row}>
          <Text style={styles.t_pleasecheck}>Please check your selection is correct.</Text>
          <Product />
          <Description />
        </View>
      </View>
    </View>
  );
};

export default React.memo(GiftCardProduct);
