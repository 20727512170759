import React from "react";
import { View, StyleSheet, Image } from "react-native";
import WelcomeContent from "./welcome-content";
import ConnectUs from "./connect-with-us";
import HowItWorks from "./how-it-works";
import images from "@assets/images";
import Features from "./features";
import Rewards from "./rewards";
import Footer from "./footer";

function Dashboard() {
  return (
    <View style={styles.container}>
      <View style={{width: "100%"}}>
        <Image style={styles.image_bg} source={images.bg_dashboard} resizeMode="stretch" />
        <WelcomeContent />
        <HowItWorks />
      </View>
      <Rewards />
      <Features />
      <ConnectUs />
      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {flex: 1, alignItems: "center", backgroundColor: "white"},
  image_bg: { width: "100%", height: "100%", position: "absolute" },
});

export default Dashboard;
