import { all, fork } from "redux-saga/effects";
import authWatcherSaga from "./auth.sagas";
import dashboardWatcherSaga from "./dashboard.sagas";

export default function* rootSaga() {
  yield all([
    fork(authWatcherSaga),
    fork(dashboardWatcherSaga),
  ]);
}
