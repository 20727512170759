import React from "react";
import { View, ScrollView, Text, Image} from "react-native";
import UnSignedHeader from "../header/UnSignedHeader";
import Footer from "@src/screens/dashboard/footer";
import useStyles from "./styles.css";
import images from "@assets/images";

export default function GamingPolicy() {
  const styles = useStyles();

  return (
    <>
      <UnSignedHeader />
      <View style={styles.flex1}>
        <Image style={styles.image_background} source={images.bg_policy} resizeMode="cover" />
        <ScrollView style={styles.flex1}>
          <View style={styles.container}>
            <Image style={styles.image_logo} source={images.logo} resizeMode="contain" />
            <Text style={styles.t_title}>RESPONSIBLE GAMING POLICY</Text>
            <View style={styles.v_pdf}>
              <Text style={[styles.t_note_gamingpolicy]}>
            Fortune 8 Responsible Social Gaming Policy</Text>
              <Text style={[styles.t_note_gamingpolicy]}>
            Date created: 25/10/23</Text>
              <Text style={styles.t_note_title}>Purpose</Text>
              <Text style={[styles.t_note]}>As a matter of our customer care policy, we are committed to provide our players a responsible and supportive environment to enhance their social gaming experience.</Text>
              <Text style={[styles.t_note]}>We realise that there are players who are at risk of certain potential negative effects associated with social gaming. We believe that we share a responsibility with our customers to help our customers enjoy our games in a safe and responsible manner. Therefore, we have put together the following measures to help customers keep their social gaming experience enjoyable and safe.</Text>
              <Text style={styles.t_note_title}>1. Introduction</Text>
              <Text style={[styles.t_note]}>(i) This Fortune 8 Responsible Gaming Policy (RSG) provides Fortune 8 registered players the information, control tools and procedures, and some resources for responsible gaming. (ii) The RSG Policy forms a part of the Fortune 8 Terms and Conditions, and has the same meaning in this RSG Policy. We may update the RSG Policy from time to time, as we feel necessary. Any amendments to the RSG Policy will be published on the Fortune 8 Website, such changes will be binding and effective immediately.</Text>
              <Text style={[styles.t_note]}>(iii) If we update the RSG Policy in a manner that would limit your rights, we will inform you at the time you visit our Websites and Applications and will require you to confirm your acceptance prior to playing any games. If you disagree with the RSG Policy or the Terms and Conditions, you must stop using our Website or Applications.</Text>
              <Text style={[styles.t_note_title]}>2. Self Assessment
                <Text style={styles.t_note}> - Helping you Stay in Control Although most people enjoy social gaming in a safe and responsible way, for some it can become a problem. If you are concerned with your or someone else’s social gaming, please consider the following questions to better understand if your gaming is problematic.
                </Text></Text>
              <Text style={[styles.t_note]}>2.1 Does social gaming keep you from performing necessary tasks, such as work or school? </Text>
              <Text style={[styles.t_note]}>2.2 Do you miss appointments, gatherings, or other deadline related tasks due to your social gaming habits?</Text>
              <Text style={[styles.t_note]}>2.3 Have you ever been criticised by others by your social gaming habits?</Text>
              <Text style={[styles.t_note]}>2.4 How do you react to criticism of your social gaming habits?</Text>
              <Text style={[styles.t_note]}>2.5 Have you seen relationships with family, friends or significant others deteriorate due to your social gaming habits.</Text>
              <Text style={[styles.t_note]}>2.6 Have your social gaming habits replaced other hobbies of yours?</Text>
              <Text style={[styles.t_note]}>2.7 Do you ever lie about or hide your social gaming habits?</Text>
              <Text style={[styles.t_note]}>2.8 Are you ashamed, embarrassed or depressed about your social gaming habits?</Text>
              <Text style={[styles.t_note]}>2.9 When you are stressed or upset, do you turn to social gaming to help you feel better?</Text>
              <Text style={[styles.t_note]}>2.10 Do you lose sleep over social gaming?</Text>
              <Text style={[styles.t_note]}>2.11 Are you borrowing money to pay your bills, or expenses and cannot pay your bills?</Text>
              <Text style={[styles.t_note]}>2.12 Are you playing to avoid or get away from a stress, and feel depressed during or after playing?</Text>
            
              <Text style={[styles.t_note_title]}>3. The more questions you answer ‘yes’ to,
                <Text style={[styles.t_note]}> the more likely you are to develop or already have developed a problem with social gaming. The following are some general guidelines that can help you enjoy our games safely and responsibly:
                </Text></Text>
              <Text style={[styles.t_note]}>Social gaming is meant to be fun and is only a form of entertainment, it should not be treated as a source of generating an income. Make sure that you are still enjoying yourself while playing our games.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>3.1 Set limits on the amount of time you spend playing our games.</Text>
              <Text style={[styles.t_note]}>3.2 Set limits on the amount of money you spend playing our games.</Text>
              <Text style={[styles.t_note]}>3.3 Consider whether you need to spend some time away from our games.</Text>
              <Text style={[styles.t_note]}>3.4 Avoid playing games when you are upset, emotional or intoxicated.</Text>
              <Text style={[styles.t_note]}>3.5 Remember that the game results are random, understand how the games work and remember this is only for fun.</Text>
              <Text style={[styles.t_note]}>3.6 Refer to the questions in section 2 if you believe your social gaming habits have become a problem.</Text>
              <Text style={[styles.t_note_title]}>4. Support Services If you are concerned about your gaming habits or you believe you have developed or likely to develop a problem with social gaming, we encourage you to contact the following (or similar ) organisations.</Text>
              <Text style={[styles.t_note]}>(i) Computer Gaming Addicts Anonymous - an organisation to support individuals with problems resulting from excessive gaming.</Text>
              <Text style={[styles.t_note]}>(ii) Substance Abuse and Mental Health Services or 24/7 Helpline located in your local area or country whom specialises in providing treatment referrals for individuals or families facing mental health and/or substance abuse disorders.</Text>
              <Text style={[styles.t_note]}>(iii) Financial Counselling located in your local area or country that provides services such as (but not limited to) consumer credit counselling, bankruptcy counselling and debt management.</Text>
              <Text style={[styles.t_note_title]}>5. How we can help you enjoy our game responsibly.</Text>
              <Text style={[styles.t_note]}>We have put in place account options to help you enjoy our games safely and responsibly. If you would like to request Account Time-Out, Self Exclusion or Permanent Account Closing please e- mail info@gameworkz.fun with the details of your request.</Text>
              <Text style={[styles.t_note, { marginTop: 10}]}>(i) Account Time-Out If you feel like you need to take a break from our games, you can request a Time-Out of your Account. During an Account Time-Out you will be unable to access your account in anyway. Account Time-Out cannot be reversed. Once approved, your account will only be accessible upon the expiration of the chosen amount of time. An Account Time-Out can be requested from two (2) days to one (1) week, fourteen (14) days or thirty (30) days. When the chosen Time-Out period has elapsed, your Account will become accessible once again.</Text>
              <Text style={[styles.t_note, { marginTop: 10}]}>(ii) Self Exclusion Self Exclusion is a voluntary and confidential program that gives you the opportunity to take a break from your Account for a longer period of time. During this period you will not be able to access your account. This means that we will use our best efforts to prevent you from opening or reopening on Account with Us and you will not be permitted to earn rewards.</Text>
              <Text style={[styles.t_note]}>Self Exclusion must last a minimum of six (6) months. There is no limit to the length that self exclusion can last. Any person who opts for self-exclusion will be unable to open an Account or access their Account during the prescribed amount of time. It cannot be cancelled or changed in any way. When self-exclusion is approved and confirmed, you will not receive any marketing communications from us for the duration of the self-exclusion.</Text>
              <Text style={[styles.t_note, { marginTop: 10}]}>Where an indefinite self exclusion is in effect, a minimum of six (6) months must pass before the account can be reactivated upon request. A minimum of one (1) week cooling off period will apply before the account is reactivated from an indefinite self exclusion.</Text>
              <Text style={[styles.t_note, { marginTop: 10}]}>(iii) Permanent Account Closing You may choose to permanently close your account. Such request has to be made in writing if you would like to close your Account, please send me an email to info@gameworkz.fun in order to do so.</Text>
              <Text style={[styles.t_note_title]}>6. Protect of Players and Minors</Text>
              <Text style={[styles.t_note]}>We do not recommend the use of our Website or Applications or playing games on our Website of Applications if:</Text>
              <Text style={[styles.t_note]}>1. You are being treated and recovering from an addiction or some form of dependency.</Text>
              <Text style={[styles.t_note]}>2. You are experiencing depressive or suicidal thoughts and are currently being treated for it.</Text>
              <Text style={[styles.t_note]}>3. You have recently experienced or are going through a traumatic event in your life and undergoing some financial difficulty.</Text>
              <Text style={[styles.t_note, { marginTop: 10}]}>You must be the age of majority (legal age) in your jurisdiction to play any of the games offered on our website and applications.</Text>
              <Text style={[styles.t_note]}>We recommend parents do the following in order best prevent access to their Account by a minor.</Text>
              <Text style={[styles.t_note, { marginTop: 10}]}>1. Never disclose your Account username or password.</Text>
              <Text style={[styles.t_note]}>2. Never leave your device unattended while logged into our Website or Applications.</Text>
              <Text style={[styles.t_note]}>3. Never use the “Remember Me” function for our Website or Applications.</Text>
              <Text style={[styles.t_note]}>4. Don not disclose any of your banking information to your children.</Text>
              <Text style={[styles.t_note, { marginTop: 10}]}>If any of the devices that you use to enjoy our games are shared with anyone who is a minor, we recommend using Parental control software such as Netnanny, Cybersitter or Cyberpatrol to prevent any minors from accessing our Website or Applications.</Text>
           
            </View>
          </View>
          <Footer />
        </ScrollView>
      </View>
    </>
  );
}
