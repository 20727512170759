import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      paddingVertical: 30,
      paddingHorizontal: "12%",
      backgroundColor: colors.gray2
    },
    v_row: {flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between"},
    v_address: {alignItems: "flex-start", width: "50%"},
    v_field: {flexDirection: "row", alignItems: "center", marginTop: 20},
    t_field: {fontFamily: "Montserrat", fontSize: 12, color: colors.black, marginRight: 10},
    t_field_value: {fontFamily: "Montserrat", fontSize: 12, color: colors.border1 },
    t_title: {fontFamily: "Montserrat-Bold", fontSize: 24, color: colors.colorPrimary},
    t_edit: {fontFamily: "Montserrat-Bold", fontSize: 18, color: colors.red2, marginLeft: 5},
    
    t_note: {fontFamily: "Montserrat", fontSize: 12, color: colors.colorPrimary },
    v_personalinfo: {flexDirection: "row", marginTop: 20},
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      container: {paddingHorizontal: 20, alignItems: "center", paddingBottom: 50},
      v_row: {width: "100%", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"},
      t_note: {textAlign: "center", fontSize: 14 },
      v_personalinfo: { flexDirection: "column", alignItems: "center", marginTop: 20, maxWidth: 330},
      t_edit: { alignSelf: "flex-end", fontSize: 14, marginLeft: 0, marginTop: 5 },
      v_address: { marginTop: 20, width: "100%" },
    },
  }
);

export default useStyles;
