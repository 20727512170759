import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
    },
    flex1: { flex: 1 },
    image_background: {width: "100%", height: "100%", position: "absolute"},
    image_logo: {width: 120, height: 104, marginTop: 60},
    t_title: { fontFamily: "Montserrat-Medium", fontSize: 32, lineHeight: 34, color: colors.white, },
    t_note_title: { fontFamily: "Montserrat-Medium", color: colors.violet, fontSize: 14, lineHeight: 18, marginTop: 20 },
    t_howitworks: { fontFamily: "Montserrat-Bold", color: colors.white, fontSize: 18, lineHeight: 20, marginTop: 25 },
    t_note: { fontFamily: "Montserrat", fontSize: 18, lineHeight: 24, color: colors.white },
    t_note_bold: { fontFamily: "Montserrat-Bold" },
    v_pdf: {
      padding: 15,
      width: "68%", 
      minHeight: 600, 
      marginTop: 50,
      maxWidth: 977,
      marginBottom: 50,
      backgroundColor: colors.violet4
    },
    v_loading: { height: 200 },
    containerStyle: { backgroundColor: colors.white3}
  },
  {
    [DEVICE_SIZES.LG]: {
    },
    [DEVICE_SIZES.MD]: {
      t_title: { fontSize: 47, lineHeight: 55 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { height: 279, paddingHorizontal: 20},
      t_title: { fontSize: 22, lineHeight: 30 },
      t_note_title: { fontSize: 10, lineHeight: 14 },
      t_note: { fontSize: 10, lineHeight: 14 },
      v_pdf: { width: "100%" },
    },
  }
);

export default useStyles;
