import React from "react";
import ConnectUs from "@src/screens/dashboard/connect-with-us";
import PlayFortune8 from "../dashboard-home/play-fortune8";
import { useDashboardService } from "@src/ducks/hooks";
import { ScrollView, View } from "react-native";
import Rewards from "../dashboard/rewards";
import Transaction from "./transactions";
import Footer from "../dashboard/footer";
import useStyles from "./styles.css";
import Personal from "./personal";
import Account from "./account";

const MyAccount = () => {
  const styles = useStyles();
  const { onDashboardRequest } = useDashboardService();

  const onSroll = (y: number) => {
    const decimal = Math.random() * 1;
    window.scrollTo(0, y + decimal);
  };

  React.useEffect(() => {
    onDashboardRequest();
  },[]);

  return (
    <ScrollView>
      <View style={styles.container}>
        <Account />
        <View style={styles.v_height50} />
        <Personal />
        <Transaction />
        <Rewards />
        <View style={styles.v_height2} />
        <PlayFortune8 />
        <ConnectUs />
      </View>
      <Footer onConnectPress={() => onSroll(3000)} />
    </ScrollView>
  );
};

export default MyAccount;
