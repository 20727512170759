import React from "react";
import { Text, View, ScrollView, ActivityIndicator } from "react-native";
import { useRoute, useNavigation } from "@react-navigation/native";
import Footer from "@src/screens/dashboard/footer";
import { useFetchPost } from "@src/utils/api/hooks";
import { Button01 } from "@src/components/button";
import useStyles from "./styles.css";
import SVGIcon from "@assets/svg";
import _ from "lodash";

function EmailVerification() {
  const styles = useStyles();
  const route = useRoute<any>();
  const code = route?.params?.code;
  const userid = route?.params?.userid;
  const navigation = useNavigation<any>();
  const { loading, data, error, runRequest } = useFetchPost();

  React.useEffect(() => {
    const params = {
      "userId": userid,
      "verifyCode": code
    };
    runRequest("/api/v1/redeem/user/verify", params);
  },[]);

  return (
    <ScrollView>
      {loading && <View style={[styles.container]}>
        <ActivityIndicator animating size={40} color="black" />
        <Text style={[styles.t_success]} >Account verifying...</Text>
      </View>}

      {!_.isEmpty(error) && <View style={[styles.container]}>
        <Text style={[styles.t_success]} >Verification Failed</Text>
        <Text style={[styles.t_verify]} >{error.message}</Text>
      </View>}
      
      {!_.isEmpty(data) && <View style={[styles.container]}>
        <SVGIcon name="success" />
        <Text style={[styles.t_success]} >Account Verified</Text>
        <Text style={[styles.t_verify]} >You’ve successfully verify your email for the following</Text>
        <Text style={[styles.t_verify]} >Gameworkz Rewardz & Gameworkz Fortune 8</Text>
        <Button01
          onPress={() => navigation.navigate("Signin")}
          label="LOG IN NOW" style={styles.btn_dashboard}
          labelStyle={styles.label_dashboard} />
      </View>}
      <Footer />
    </ScrollView>
  );
}

export default EmailVerification;
