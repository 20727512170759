// DUCKS pattern
import { createAction, createSlice } from "@reduxjs/toolkit";
import { LoginInput, ErrorValue, SessionValue, SignupInput } from "../types";
import { getAmountBalanceByCoinType } from "@src/utils/filter-helper";
import type { RootState } from "@src/ducks/store";
import { createSelector } from "@reduxjs/toolkit";
interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  session: SessionValue;
  tier: any;
  error: ErrorValue;
  errorMessage: string;
}

export const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  session: { token: ""} as SessionValue,
  error: {} as ErrorValue,
  errorMessage: "",
  tier: {}
} as AuthState;

// Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    loginGoogleRequest: (state) => {
      state.loading = true;
    },
    loginFBRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.session = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
      state.isLoggedIn = true;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    signupRequest: (state) => {
      state.loading = true;
    },
    signupSuccess: (state, action) => {
      state.session = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
      state.isLoggedIn = true;
    },
    signupFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    logout: () => {
      return initialState;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    updateWallet: (state, action) => {
      state.session["userBalanceList"] = action.payload;
    },
    updateTier: (state, action) => {
      state.tier = action.payload;
    },
  },
});

// Actions
export const authActions = {
  loginRequest: createAction(
    `${authSlice.name}/loginRequest`,
    (params: LoginInput) => ({
      payload: params,
    })
  ),
  loginGoogleRequest: createAction(
    `${authSlice.name}/loginGoogleRequest`,
    (params: string) => ({
      payload: params,
    })
  ),
  loginFBRequest: createAction(
    `${authSlice.name}/loginFBRequest`,
    (params: string) => ({
      payload: params,
    })
  ),
  loginSuccess: authSlice.actions.loginSuccess,
  loginFailure: authSlice.actions.loginFailure,

  signupRequest: createAction(
    `${authSlice.name}/signupRequest`,
    (params: SignupInput) => ({
      payload: params,
    })
  ),
  signupSuccess: authSlice.actions.signupSuccess,
  signupFailure: authSlice.actions.signupFailure,

  logout: authSlice.actions.logout,
  setErrorMessage: authSlice.actions.setErrorMessage,
  updateWallet: authSlice.actions.updateWallet,
  updateTier: authSlice.actions.updateTier,
};

// Selectors
export const selectAuthLoggingIn = (state: RootState) => state.auth.loading;
export const selectAuthLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectAuthLogInFailed = (state: RootState) => state.auth.error;
export const selectAuthSession = (state: RootState) => state.auth.session || {};
export const selectErrorMessage = (state: RootState) => state.auth.errorMessage;
export const selectedAuthSession = createSelector(
  (state: RootState) => state.auth.session,
  (session) => ({
    userId: session?.id,
    token: session?.token,
  })
);
export const selectedInvalidUser = createSelector(
  (state: RootState) => state.auth.session,
  (session) => {
    const userDetail = session.userDetail || {};

    if(!session.firstName){
      return true;
    }else if(!session.lastName){
      return true;
    }else if(!session.phoneNumber){
      return true;
    }
    // else if(!session.dateOfBirth){
    //   return true;
    // }else if(!userDetail.gender){
    //   return true;
    // }
    else if(!userDetail.country){
      return true;
    }else if(!userDetail.street){
      return true;
    }else if(!userDetail.city){
      return true;
    }else{
      return false;
    }
  }
);
export const selectedAuthName = createSelector(
  (state: RootState) => state.auth.session,
  (session) => `${(session.firstName || "").trim()} ${(session.lastName || "").trim()}`
);
export const selectedTierID = createSelector(
  (state: RootState) => state.auth.tier,
  (tier) => tier.tierID || 0
);
export const selectedCoinBalance = createSelector(
  (state: any) => state.auth.session,
  (state) => getAmountBalanceByCoinType(state.userBalanceList || []),
);

// Reducer
export default authSlice.reducer;
