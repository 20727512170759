import React from "react";
import { minTableSize } from "@src/utils/screensize-helper";
import { Text, View, FlatList, ActivityIndicator } from "react-native";
import { selectedCoinBalance, selectedInvalidUser, selectedTierID } from "@src/ducks/slices/auth.slice";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useFetchGet } from "@src/utils/api/hooks";
import { Button01 } from "@src/components/button";
import Dropdown from "@src/components/dropdown";
import { AuthContext } from "@src/AuthProvider";
import useStyles from "./styles.css";
import Items from "./items";
import _ from "lodash";

function GiftCards() {
  const styles = useStyles();
  const isMobile = minTableSize();
  const navigation = useNavigation<any>();
  const tierId = useAppSelector(selectedTierID);
  const [ error, setError ] = React.useState("");
  const [ amount, setAmount ] = React.useState("");
  const [ card, setCard ] = React.useState<any>({});
  const { data, runRequest } = useFetchGet();
  const balance = useAppSelector(selectedCoinBalance);
  const { onErrorMessage } = React.useContext(AuthContext);
  const isUserNotValid = useAppSelector(selectedInvalidUser);

  const onRedeem = () => {
    if(!card.name){
      setError("Selecte gift card.");
    }else if(!amount){
      setError("Selecte amount is required.");
    }else if(Number(amount) > balance.GOLD){
      setError("INSUFICIENT BALANCE.");
    }else if(isUserNotValid){
      onErrorMessage("INCOMPLETE USER PROFILE");
    }else if(tierId <= 1){
      onErrorMessage("Your account must be atleast emerald tier.");
    }else{
      const params = {
        type: "giftcard",
        id: card.id,
        name: card.name,
        amount: amount,
      };
      navigation.navigate("RequestForm", params);
    }
  };

  React.useEffect(() => {
    if(error){
      setError("");
    }
  },[card, amount]);

  React.useEffect(() => {
    runRequest("/api/v1/redeem/giftCardList/get");
  },[]);

  return (
    <View style={styles.container}>
      <Text style={styles.t_title} >REDEEM YOUR GIFT CARD</Text>
      <Text style={styles.t_subtitle} >Enjoy your rewards from Gameworkz Fortune 8 and have a shopping spree with your gift cards.</Text>
      <View style={styles.v_banners}>
        <FlatList
          data={data || []}
          numColumns={isMobile ? 1 : 3}
          key={Math.random().toString()}
          keyExtractor={(item, index) => index.toString()}
          ItemSeparatorComponent={() => <View style={styles.divider} />}
          ListEmptyComponent={<View>
            <ActivityIndicator animating size={30} color="black" />
          </View>}
          renderItem={({item, index}) => {
            return <Items key={index.toString()} item={item} />;
          }} />
        <View style={{ marginTop: 30 }}>
          <Text style={styles.t_minimum}>Minimum gift card amount $50.00</Text>
          <Text style={styles.t_minimum}>Maximum gift card amount $500.00</Text>
          <Text style={styles.t_minimum}>Redemption is limited to 1 time per day per user.</Text>
          <Text style={styles.t_goldvalue}>1 GWz GOLD COIN = US$1.00</Text>
        </View>
        {!_.isEmpty(error) && <Text style={styles.t_error}>{error}</Text>}

        <View style={styles.v_cards}>
          <View style={styles.v_giftcard_row1}>
            <Text style={[styles.t_select_gift]}>SELECT YOUR GIFT CARD</Text>
            <View style={styles.v_dropdown_row1}>
              <Dropdown
                options={data || []}
                placeholder="Select"
                value={card?.name}
                onSelected={setCard}
                ItemSeparatorComponent={() => <View style={{height: 0}} />}
              />
            </View>
          </View>
          <View style={styles.v_width} />
          <View style={styles.v_giftcard_row2}>
            <Text style={[styles.t_select_gift]}>SELECT AMOUNT</Text>
            <View style={styles.v_dropdown_row2}>
              <Dropdown
                options={["50", "100", "200", "500"]}
                placeholder="Select"
                value={amount}
                onSelected={setAmount}
                ItemSeparatorComponent={() => <View style={{height: 0}} />}
              />
            </View>
          </View>
          <View style={styles.v_width} />
          <Button01 onPress={onRedeem} style={styles.btn_redeem2} label="Redeem Gift Card" />
        </View>
        <Button01 onPress={onRedeem} style={styles.btn_redeem} label="Redeem Gift Card" />
        <Text style={styles.t_fees}>Fees and charges apply. Read our{" "}
          <Text onPress={() => navigation.navigate("TermsCondition")} 
            style={styles.t_terms}>Terms and Conditions</Text> 
          {" "}for details.</Text>
      </View>
    </View>
  );
}

export default GiftCards;
