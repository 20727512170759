import React from "react";
import { selectAuthSession } from "@src/ducks/slices/auth.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

const PersonalDetails = () => {
  const styles = useStyles();
  const session = useAppSelector(selectAuthSession);

  return (
    <>
      <Text style={styles.t_title}>REQUEST FORM</Text>
      <View style={styles.v_height} />
      <Text style={styles.t_subtitle}>REWARDS WILL BE AWARDED TO:</Text>
      <View style={styles.v_height} />
      <View style={styles.v_row_fields}>
        <View>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >FIRST NAME:</Text>
            <Text style={styles.t_field_value}>{session.firstName}</Text>
          </View>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >LAST NAME:</Text>
            <Text style={styles.t_field_value}>{session.lastName}</Text>
          </View>
        </View>
        <View style={styles.v_row}>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >EMAIL ADDRESS:</Text>
            <Text style={styles.t_field_value}>{session.emailAddress}</Text>
          </View>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >USER ID:</Text>
            <Text style={styles.t_field_value}>{session.id}</Text>
          </View>
        </View>
      </View>
      <View style={styles.v_height50} />
    </>
  );
};

export default PersonalDetails;
