import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    tabsContainer: { borderWidth: 1, borderColor: colors.gray1 },
    v_header: { flexDirection: "row", alignItems: "center"},
    tab: {
      flex: 1,
      height: 40,
      // minWidth: 141,
      // maxWidth: 181,
      alignItems: "center",
      // paddingHorizontal: 30,
      justifyContent: "center",
      backgroundColor: colors.colorPrimary,
    },
    tabLabel: {
      fontSize: 14,
      lineHeight: 16,
      color: colors.white,
      textAlign: "center",
      fontFamily: "Montserrat-Medium",
    },
    t_value: {
      fontSize: 14,
      lineHeight: 16,
      color: colors.black,
      textAlign: "center",
      fontFamily: "Montserrat-Medium",
    },
    divider: {width: 1, height: 40, backgroundColor: colors.gray1},
  },
  {
    [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
      tab: { height: 48 },
      tabLabel: {
        fontSize: 24,
        lineHeight: 17.79,
      },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      tab: { height: 35 },
      tabLabel: { fontSize: 6 },
      t_value: { fontSize: 8, paddingHorizontal: 4 },
    },

  }
);

export default useStyles;
