import * as Font from "expo-font";

// fonts preloading
export const fontAssets = [
  {
    Inter: require("./Inter.ttf"),
  },
  {
    "Inter-Medium": require("./Inter-Medium.ttf"),
  },
  {
    "Inter-Bold": require("./Inter-Bold.ttf"),
  },
  {
    Montserrat: require("./Montserrat.ttf"),
  },
  {
    "Montserrat-Medium": require("./Montserrat-Medium.ttf"),
  },
  {
    "Montserrat-Bold": require("./Montserrat-Bold.ttf"),
  },
  {
    "Montserrat-ExtraBold": require("./Montserrat-ExtraBold.ttf"),
  },
].map((x: any) => Font.loadAsync(x));
