import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 326, 
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.violet1, 
    },
    v_center: { 
      flex: 1, flexDirection: "row", 
      alignItems: "center", justifyContent: "center", marginTop: 20
    },
    reward_logo: {width: 260, height: 44}, 
    v_right_content: {flexDirection: "row", alignItems: "flex-start"},
    v_row: { flex: 1, minWidth: 222, maxWidth: 222 },
    v_copyright: { 
      flexDirection: "row", 
      alignItems: "center", 
      justifyContent: "center", height: 60},
    reward_logo_below: { 
      width: 126, height: 29, 
      marginRight: 20, marginBottom: 7, 
      display: "none"
    },
    t_copyright: {
      fontSize: 14,
      lineHeight: 21,
      color: colors.white,
      fontFamily: "Inter",
    },
    t_note_title: {
      fontSize: 14,
      fontFamily: "Inter-Bold", 
      color: colors.white
    },
    t_note_subtitle: {
      fontSize: 14,
      marginTop: 5,
      lineHeight: 21,
      fontFamily: "Inter", 
      color: colors.white
    },
    width100: { width: 100 },
    width70: { width: 70 },
  },
  {
    [DEVICE_SIZES.MD]: {
      // container: { paddingHorizontal: 20 }
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { height: 190, paddingHorizontal: 20 },
      reward_logo_below: { display: "flex" },
      reward_logo: { display: "none" },
      v_row: { minWidth: 120, maxWidth: 120 },
      t_note_title: { fontSize: 10, lineHeight: 15 },
      t_note_subtitle: { fontSize: 10, lineHeight: 15 },
      t_copyright: { fontSize: 10 },
      width100: { width: 0 },
      width70: { width: 20 },
    },
  }
);

export default useStyles;
