import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      paddingTop: 50,
      paddingBottom: 60,
      alignItems: "center",
      backgroundColor: "white"
    },
    t_title: {
      fontFamily: "Montserrat-Bold", fontSize: 32, 
      lineHeight: 38, color: colors.colorPrimary, textAlign: "center"
    },
    t_subtitle: {
      fontFamily: "Montserrat", fontSize: 16, 
      lineHeight: 25, color: colors.black1, 
      marginTop: 15, textAlign: "center", maxWidth: 882,
    },
    v_right_content: {flexDirection: "row", alignItems: "center"},
    btn_started: { backgroundColor: colors.colorPrimary, width: 130, height: 41, marginVertical: 50 },

    // ITEMS
    v_banners: { alignItems: "center", marginTop: 50},
    item_banner: { width: "100%", height: 300 },
    // item_container: { width: "39%", maxWidth: 575, backgroundColor: 'red' },
    item_container: { width: 575, marginHorizontal: 10 },
    item_details: { paddingTop: 15, paddingLeft: 15, alignItems: "flex-start"},
    t_item_title: {
      fontFamily: "Montserrat", 
      fontWeight: "600",
      fontSize: 24, 
      lineHeight: 36, 
      color: colors.black1,
    },
    t_item_subtitle: {
      // width: 400,
      fontFamily: "Montserrat", 
      fontWeight: "400",
      fontSize: 16, 
      lineHeight: 24, 
      color: colors.black1,
    },
    divider: { width: 30 },
  },
  {
    [DEVICE_SIZES.MD]: {
      t_title: { fontSize: 44, lineHeight: 50},
      item_container: { width: 250 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { paddingHorizontal: 25, marginTop: 30, paddingBottom: 0 },
      t_title: { fontSize: 20, lineHeight: 24 },
      t_subtitle: { fontSize: 16, lineHeight: 20 },
      divider: { width: 0 },
      v_banners: { alignItems: "center", marginTop: 0 },
      item_details: { paddingLeft: 0, marginTop: 10 },
      item_container: { width: "100%", marginTop: 30, marginHorizontal: 0 },
      item_banner: { width: 289, height: 143, alignSelf: "center" },
      t_item_title: { fontFamily: "Montserrat-Medium", fontSize: 16, lineHeight: 21 },
      t_item_subtitle: { fontSize: 16, lineHeight: 18 },
    },
  }
);

export default useStyles;
