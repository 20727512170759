import React from "react";
import { selectedDashboardError, selectedRedeemData } from "@src/ducks/slices/dashboard.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { ScrollView, View } from "react-native";
import { AuthContext } from "@src/AuthProvider";
import RequestFormProvider from "./provider";
import Footer from "../dashboard/footer";
import useStyles from "./styles.css";
import Screens from "./screens";
import _ from "lodash";

const RequestFormMain = () => {
  const styles = useStyles();
  const scrollRef = React.useRef<any>();
  const { onErrorMessage } = React.useContext(AuthContext);
  const requestsucces: any = useAppSelector(selectedRedeemData);
  const requestformfailed: any = useAppSelector(selectedDashboardError);

  const onScrollTop = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  React.useEffect(() => {
    if(!_.isEmpty(requestformfailed)){
      onErrorMessage(requestformfailed.message);
    }
  },[requestformfailed]);

  React.useEffect(() => {
    if(!_.isEmpty(requestsucces)){
      onScrollTop();
    }
  },[requestsucces]);

  return (
    <RequestFormProvider>
      <ScrollView ref={scrollRef} >
        <View style={styles.main}>
          <Screens />
          <Footer />
        </View>
      </ScrollView>
    </RequestFormProvider>
  );
};

export default RequestFormMain;
