import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, backgroundColor: colors.white },
    v_height50: { height: 70 },
    v_height2: { height: 40 },
    
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      v_height50: { height: 30 },
    },
  }
);

export default useStyles;
