import React, { useState } from "react";
import { useInputHelper } from "@src/utils/inputs-helper";
import { TextInput02 } from "@src/components/textinput";
import { useRoute } from "@react-navigation/native";
import { Button02 } from "@src/components/button";
import { ResetContext } from "../provider";
import { View, Text } from "react-native";
import useStyles from "./styles.css";
import _ from "lodash";

const initialState = {
  password: "",
  confirmpassword: "",
};

function NewPassword() {
  const styles = useStyles();
  const route = useRoute<any>();
  const userId = route?.params?.userid;
  const verifyToken = route?.params?.token;
  const errorRef = React.useRef<any>(null);
  const [error, setError] = useState<any>({});
  const {state, onDispatch} = useInputHelper(initialState);
  const { data, onReset, loading } = React.useContext(ResetContext);

  const onUpdatePassword = () => {
    const Err: any = {};
    if (!state.password) {
      Err.password = "Required";
    } else if (state.password.length < 6) {
      Err.password = "Password must be atleast 6 characters";
    } else if (!state.confirmpassword) {
      Err.confirmpassword = "Required";
    } else if (state.password !== state.confirmpassword) {
      Err.confirmpassword = "Password do not match";
    }

    setError(Err);

    if (_.isEmpty(Err)) {
      onReset({...state, userId, verifyToken});
    }
  };

  React.useEffect(() => {
    setError({});
    errorRef?.current?.onClear();
  }, [state]);

  if(!_.isEmpty(data)){
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.v_details}>
        <Text style={styles.t_login}>Update Password</Text>
        <Text style={styles.t_requirement}>Please enter your new password</Text>
      </View>
      <View style={styles.v_inputs}>
        <TextInput02
          hasPassword
          label="Enter New Password"
          value={state.password}
          placeholder="Your Password"
          error={error?.password}
          onChangeText={onDispatch("password")} />
        <TextInput02
          hasPassword
          label="Confirm New Password"
          value={state.confirmpassword}
          placeholder="Your Password"
          error={error?.confirmpassword}
          onChangeText={onDispatch("confirmpassword")} />
        <Button02 
          label="SUBMIT"
          onPress={onUpdatePassword}
          isLoading={loading}
          labelStyle={{color: "white"}}
          style={styles.btn_submit} />
      </View>
    </View>
  );
}

export default NewPassword;