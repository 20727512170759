import React from "react";
import { Image, View } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";

function Items({ item }: any) {
  const styles = useStyles();
  
  return (
    <View style={[styles.item_container]}>
      <Image style={styles.item_banner} source={images["amazon"]} resizeMode="stretch" />
    </View>
  );
}

export default React.memo(Items);