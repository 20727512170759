import React from "react";
import { Text, View, Pressable, ActivityIndicator } from "react-native";
import { selectedAuthSession } from "@src/ducks/slices/auth.slice";
import { fileName } from "@src/utils/transform-helper";
import { useAppSelector } from "@src/ducks/ducksHook";
import Icon from "@expo/vector-icons/MaterialIcons";
import { RequestFormContext } from "../provider";
import { deleteUploadID } from "@src/utils/api";
import useStyles from "./styles.css";

const FileUploaded = ({onRefresh}: any) => {
  const styles = useStyles();
  const session = useAppSelector(selectedAuthSession);
  const [loading, setLoading ] = React.useState(false);
  const { state } = React.useContext(RequestFormContext);

  const onDelete = async (id: number) => {
    try{
      setLoading(true);
      await deleteUploadID(id, session.token);
      console.log("deleteUploadID");
      onRefresh();
      setLoading(false);
    }catch(e){
      console.log("eee",e);
      setLoading(false);
      onRefresh();
    }
  };


  return (
    <View style={styles.v_upload_box}>
      <Text style={styles.t_upload_file}>FILE UPLOADED</Text>
      <View style={styles.v_upload_field}>
        {state.ids.map((item: any, index: number) => {
          return ( 
            <View key={`${index}`} style={styles.v_uploaded} >
              <Text numberOfLines={1} style={styles.t_file} >{fileName(item.filePath)}</Text>
              {loading ? <ActivityIndicator animating size={18} color="black"/> : 
                <Pressable onPress={() => onDelete(item.id)}>
                  <Icon name="close" size={18} color="black" />
                </Pressable>
              }
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default FileUploaded;
