import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      height: 39,
      // width: "100%",
      borderWidth: 1,
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 10,
      borderColor: colors.border1,
      backgroundColor: colors.white,
    },
    v_cursor: {
      width: 38, height: "100%", 
      backgroundColor: colors.colorPrimary, 
      alignItems: "center", 
      justifyContent: "center"},
    triangle: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderLeftWidth: 11,
      borderRightWidth: 11,
      borderBottomWidth: 11 * 2,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      borderBottomColor: colors.gray1,
      transform: [{ rotate: "180deg"}]
    },
    t_label: {
      flex: 1,
      fontFamily: "Montserrat",
      fontWeight: "600",
      fontSize: 12,
      lineHeight: 15,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { height: 30 },
      triangle: {
        borderLeftWidth: 9,
        borderRightWidth: 9,
        borderBottomWidth: 9 * 2,
      }
    },
  }
);

export default useStyles;
