import React from "react";
import { selectedDashboardLoading } from "@src/ducks/slices/dashboard.slice";
import { useNavigation, useRoute } from "@react-navigation/native";
import { selectedCoinBalance } from "@src/ducks/slices/auth.slice";
import { useDashboardService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Pressable, Text, View } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { Button01 } from "@src/components/button";
import useStyles from "./styles.css";
import colors from "@assets/colors";
import { AuthContext } from "@src/AuthProvider";
import { RequestFormContext } from "../provider";

const Description = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const giftCardID = route?.params?.id;
  const goldAmount = route?.params?.amount;
  const navigation = useNavigation<any>();
  const { onRedeemRequest } = useDashboardService();
  const [accept, setAccept] = React.useState(false);
  const balance = useAppSelector(selectedCoinBalance);
  const { onErrorMessage } = React.useContext(AuthContext);
  const loading = useAppSelector(selectedDashboardLoading);
  const { state } = React.useContext(RequestFormContext);

  const disable = React.useMemo(() => {
    if(goldAmount === 0){
      return true;
    }else if(Number(goldAmount) > balance.GOLD){
      return true;
    }else{
      return false;
    }
  },[goldAmount, balance.GOLD]);

  const onSubmit = () => {

    if(state.ids.length < 3){
      onErrorMessage("Please provide 3 forms of proof of ID");
      return;
    }

    onRedeemRequest({
      giftCardID,
      goldAmount,
      "redeemType": {
        "id": 3,
        "name": "GIFT CARD"
      },
      "redeemStatus": {
        "id": 1,
        "name": "NEW REQUEST"
      },
    });
  };

  return (
    <View style={styles.description_container}>
      <View>
        <Text style={styles.t_convert}>1 GWZ GOLD = US$1.00</Text>
        <View style={styles.v_flex_row_submit}>
          <View style={{flexDirection: "row", alignItems: "center" }}>
            <Pressable onPress={() => setAccept(!accept)} 
              style={[styles.btn_box, accept && { backgroundColor: colors.black}]}>
              <Icon name="check" size={25} color={accept ? "white" : "transparent"} />
            </Pressable> 
            <Text style={styles.t_agree}>I agree to Gameworkz Rewardz 
              <Text onPress={() => navigation.navigate("TermsCondition")} 
                style={{fontFamily: "Montserrat-Bold"}}> Terms & Conditions</Text></Text>
          </View>
        </View>
      </View>
      <Button01 
        isLoading={loading}
        disabled={!accept || disable}
        onPress={onSubmit} style={styles.btn_submit} 
        label="SUBMIT" labelStyle={styles.label_submit} />
    </View>
  );
};

export default Description;
