import React from "react";
import { useNavigation } from "@react-navigation/native";
import { useDashboardService } from "@src/ducks/hooks";
import { Text, View, Image } from "react-native";
import { Button01 } from "@src/components/button";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";

function RequestSent() {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onResetDashboard } = useDashboardService();

  React.useEffect(() => {
    return () => {
      onResetDashboard();
    };
  },[]);

  return (
    <View style={styles.container}>
      <Image style={styles.image_bg} source={images["bg_dashboard"]} resizeMode="repeat" resizeMethod="scale" />
      <View style={[styles.image_bg, {backgroundColor: colors.violet2}]} />
      <View style={styles.v_details}>
        <Image style={styles.i_logo} source={images["logo"]} resizeMode="contain"/>
        <Text style={styles.t_title} >YOUR REQUEST IS IN PROGRESS</Text>
        <Text style={[styles.t_notes, { marginTop: 10}]} >Thank you for using Gameworkz Rewardz. 
          {"\n"}Please wait patiently as we process your request.
          {"\n"}Processing time will take 1-3 weeks</Text>
        <Text style={[styles.t_notes, { marginTop: 30}]} >To track the progress of your request go to My Account
          {"\n"}and view your transaction log.</Text>
        <Button01 onPress={() => navigation.navigate("Home")} 
          label="BACK HOME" style={styles.btn_redeem} labelStyle={styles.label_redeem} />
      </View>
    </View>
  );
}

export default RequestSent;
