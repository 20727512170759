import React from "react";
import { useNavigation } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import Records from "./records";

const Transaction = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();

  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.t_title}>Your Transaction  History</Text>
        <View style={{ marginTop: 25}}>
          <Records />
        </View>
        <View style={styles.v_contact}>
          <Text style={styles.t_notes}>If something does not look right please contact support immediately to rectify the issue. </Text>
          <Button01 label="Contact Us"
            onPress={() => navigation.navigate("ContactUs")} 
            style={styles.btn_contact}
            labelStyle={styles.label_contact} />
        </View>
      </View>
    </View>
  );
};

export default Transaction;