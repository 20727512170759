import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      alignItems: "center", 
    },
    t_title: {
      fontFamily: "Montserrat-Bold", fontSize: 48,
      lineHeight: 57, color: colors.colorPrimary, textAlign: "center"
    },
    t_subtitle: {
      fontFamily: "Inter", fontSize: 18, 
      lineHeight: 27, color: colors.black1, 
      marginTop: 15, textAlign: "center"
    },
    v_right_content: {flexDirection: "row", alignItems: "center"},
    btn_started: { backgroundColor: colors.colorPrimary, width: 130, height: 41, marginVertical: 50 },
    view_started: { width: "100%", height: 657, marginTop: 60 },
    image_started: { width: "100%", height: "100%", resizeMethod: "stretch" },
    image_bg: { width: "100%", height: "100%", position: "absolute" },
    v_content: { width: 960, paddingVertical: 50},

    v_gwz_float: {
      width: "51.59%", 
      height: 110, 
      justifyContent: "center", 
      backgroundColor: colors.white2, 
      paddingLeft: 20, 
      position: "absolute", 
      top: 473, 
      right: 0
    },
    t_welcome: {fontFamily: "Montserrat-ExtraBold", fontSize: 24, lineHeight: 30, color: colors.colorPrimary},
    t_enjoy: {fontFamily: "Montserrat-Medium", fontSize: 14, lineHeight: 18},

    v_logo: {position: "absolute", top: 415, left: "14%"},
    i_logo: {  width: 122, height: 100 },
    v_claim: {width: 341, height: 125, position: "absolute", top: 715, left: "9%"},
    i_claim: {  width: "100%", height: "100%" },
  },
  {
    [DEVICE_SIZES.XL]: {
      view_started: { width: "100%", height: 900, marginTop: 70 },
      v_gwz_float: { top: 630 },
      v_logo: { top: 600,  left: "15%" },
    },
    [DEVICE_SIZES.LG]: {
      v_claim: {width: 230, height: 86, position: "absolute", top: 535, left: "10%"},
    },
    [DEVICE_SIZES.MD]: {
      container: { width: "100%",},
      t_title: { fontSize: 44, lineHeight: 50},
      v_content: { width: "100%", paddingVertical: 30, paddingHorizontal: 30},

      view_started: { width: "100%", height: 367, marginTop: 40 },
      v_gwz_float: { display: "none"},
      v_logo: { display: "none"},
      v_claim: { display: "none"},
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_content: { width: "100%", paddingHorizontal: 20, paddingVertical: 20 },
      t_title: { fontSize: 24, lineHeight: 24 },
      t_subtitle: { fontSize: 14, lineHeight: 16 },
      view_started: { width: "100%", height: 262, marginTop: 7 },
      image_started: { transform: [{scaleX: 1.5}] },
      v_gwz_float: { display: "none"},
      v_logo: { display: "none"},
    },
  }
);

export default useStyles;
