import Request from "./Request";
import { API_BASE_URL } from "@env";

// API CORE
export const CurrentApi = new Request(API_BASE_URL);

export const setToken = (token: string) => {
  CurrentApi.setToken(token);
};

export const callGet = async (routes: string, token?: string) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.get(`${routes}`);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPost = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }
    CurrentApi.setContentType("");

    const result = await CurrentApi.post(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPatch = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.patch(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPut = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.put(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callFormData = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }
    CurrentApi.setContentType("multipart/form-data");

    const result = await CurrentApi.post(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};
