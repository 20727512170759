import AsyncStorage from "@react-native-async-storage/async-storage";
import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import {
  REHYDRATE,
  PERSIST,
  REGISTER,
  persistReducer,
  persistStore,
} from "redux-persist";
import rootReducer from "./rootReducer";
import logger from "redux-logger";
import rootSaga from "./sagas";

const persistConfig = {
  key: "f8-cashback",
  storage: AsyncStorage,
  whitelist: ["auth"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [REHYDRATE, PERSIST, REGISTER],
      },
    })
      .concat(sagaMiddleware)
      .concat(logger),
});
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
