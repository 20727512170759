import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {flexDirection: "row", alignItems: "center", paddingHorizontal: 40,
      justifyContent: "center", width: "100%", height: 90 },
    t_rows: { fontFamily: "Montserrat-Medium", fontSize: 14, lineHeight: 18, color: colors.black},
    divider1: {width: 30},
    divider2: {width: 4},
    btn_rows: {flexDirection: "row", alignItems: "center", height: 24, minWidth: 30},
    btn_arrow: { alignItems: "center", justifyContent: "center", minWidth: 100},
    btn_hide: { display: "none" },
  },
  {
    [DEVICE_SIZES.LARGE_DEVICE]: {
      t_rows: {fontSize: 11, lineHeight: 12},
      divider1: {width: 10},
      btn_arrow: { width: 17, height: 17 }
    },
  }
);

export default useStyles;
