import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    main: { flex: 1, marginTop: 50, paddingBottom: 40 },
    t_title: {fontFamily: "Montserrat-Bold", fontSize: 20, color: colors.colorPrimary },
    t_note: { fontFamily: "Montserrat", fontSize: 14, lineHeight: 25, color: colors.black, marginTop: 20 },
    v_product_row: {width: "100%", flexDirection: "row", marginTop: 50},
    t_semibold: {fontFamily: "Montserrat-Medium", fontWeight: "600"},

    // DESCRIPTION 
    description_container: { flex: 1, marginLeft: 50 },
    v_field: {flexDirection: "row", alignItems: "center", marginTop: 20},
    t_subtitle: {  fontFamily: "Montserrat-Bold", fontSize: 16, color: colors.black },
    t_field: {fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.black, marginRight: 10},
    ti_productname: { height: 34, width: 311, borderWidth: 1, borderColor: colors.black,  justifyContent: "center" },
    ti_amount: { height: 34, width: 101, borderWidth: 1, borderColor: colors.black,  justifyContent: "center" },
    t_value: {
      fontFamily: "Montserrat", 
      fontSize: 16, 
      lineHeight: 19, 
      marginLeft: 10,
      color: colors.black,
    },
    btn_submit: { width: 175, height: 45, borderRadius: 3, backgroundColor: colors.green1, marginTop: 20 },
    btn_submit2: { 
      display: "none", width: 175, height: 45, alignSelf: "center",
      borderRadius: 3, backgroundColor: colors.green1, marginTop: 20 },
    label_submit: {fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.white },
    t_convert: {  fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.colorPrimary, marginTop: 20},
    v_flex_row_submit: {flexDirection: "row", alignItems: "center", marginTop: 30, justifyContent: "space-between"},
    btn_box: {
      width: 34, height: 34, borderWidth: 1, 
      borderColor: colors.black, backgroundColor: colors.gray1,
      alignItems: "center", justifyContent: "center"},
    t_agree: {fontFamily: "Montserrat", fontSize: 12, lineHeight: 14, color: colors.black, marginLeft: 15},

    // PRODUCT
    item_container: { width: 320 },
    v_banners: { marginTop: 50},
    item_banner: { width: "100%", height: 320 },
    item_details: { paddingTop: 15, paddingLeft: 15, alignItems: "flex-start"},
    t_item_title: {
      fontFamily: "Montserrat-Bold", 
      fontSize: 12, 
      lineHeight: 14, 
      color: colors.black,
    },
    t_item_subtitle: {
      fontFamily: "Montserrat", 
      fontSize: 12, 
      lineHeight: 14, 
      color: colors.black,
      marginTop: 15,
      maxWidth: 190
    },
    t_item_gold: {
      fontFamily: "Montserrat-Bold", 
      fontSize: 12, 
      marginTop: 24,
      lineHeight: 14, 
      color: colors.black,
    },
    divider: { height: 60 },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      main: { alignItems: "center",  paddingHorizontal: "12%" },
    },
    [DEVICE_SIZES.MD]: {
      main: { paddingHorizontal: 40 },
      v_product_row: { flexDirection: "column", alignItems: "center", marginTop: 30},
      item_container: { width: 340 },
      description_container: { maxWidth: 340, marginLeft: 0, marginTop: 40},
      item_details: { paddingLeft: 0 },
      v_field: { alignItems: "center", width: "100%" },
      t_field: { marginRight: 10 },
      v_flex_row_submit: {flexDirection: "column", alignItems: "flex-start" },
      btn_box: { width: 32, height: 32 },
      t_agree: { fontSize: 14, lineHeight: 18 },
      btn_submit: { display: "none", marginTop: 25 },
      btn_submit2: { display: "flex", marginTop: 25 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      main: { paddingHorizontal: 20 },
      v_product_row: { flexDirection: "column", alignItems: "center", marginTop: 30},
      item_container: { width: 284 },
      item_banner: { height: 223 },
      item_details: { paddingLeft: 0 },
      description_container: { maxWidth: 284, marginLeft: 0, marginTop: 40},
      t_subtitle: { fontSize: 14 },
      v_field: { alignItems: "center", width: "100%" },
      t_field: { marginRight: 10 },
      v_flex_row_submit: {flexDirection: "column", alignItems: "flex-start" },
      btn_box: { width: 32, height: 32 },
      t_agree: { fontSize: 14, lineHeight: 18 },
      btn_submit: { display: "none", marginTop: 25 },
      btn_submit2: { display: "flex", marginTop: 25 },
    },
  }
);

export default useStyles;
