import { useDeviceSize, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

export const mobileSize = () => {
  const device_size = useDeviceSize();

  return maxSize(DEVICE_SIZES.SM).includes(device_size);
};

export const tabletSize = () => {
  const device_size = useDeviceSize();

  return DEVICE_SIZES.MD === device_size;
};

export const minTableSize = () => {
  const device_size = useDeviceSize();

  return maxSize(DEVICE_SIZES.MD).includes(device_size);
};

