import { combineReducers } from "@reduxjs/toolkit";
import auth from "./slices/auth.slice";
import dashboard from "./slices/dashboard.slice";

const reducer = combineReducers({
  auth,
  dashboard,
});

export default reducer;
