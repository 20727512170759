import React, { useContext, useState } from "react";
import { TextInput02 } from "@src/components/textinput";
import { Button01 } from "@src/components/button";
import { View, Text } from "react-native";
import useStyles from "./styles.css";

import _ from "lodash";
import { ContactContext } from "../provider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthSession } from "@src/ducks/slices/auth.slice";

function Fields() {
  const styles = useStyles();
  const errorRef = React.useRef<any>(null);
  const [name, setName] = useState("");
  const [error, setError] = useState<any>({});
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const session = useAppSelector(selectedAuthSession);
  const { data, onContact, loading } = useContext(ContactContext);

  const onSubmit = () => {
    const Err: any = {};
    if (!name) {
      Err.name = "Required";
    } else if (!email) {
      Err.email = "Required";
    }

    setError(Err);

    if (_.isEmpty(Err)) {
      const params = {
        "email": email,
        "fullName": name,
        "message": message,
        "userId": session.userId
      };
      onContact(params);
    }
  };

  React.useEffect(() => {
    setError({});
    errorRef.current?.onClear();
  }, [name, email]);

  if(!_.isEmpty(data)){
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.v_details}>
        <Text style={styles.t_title}>Get in touch with Gameworkz Rewardz</Text>
        <Text style={[styles.t_notes, { marginTop: 10}]}>Let us know how we can help</Text>
      </View>
      <View style={styles.v_inputs}>
        <TextInput02 
          label="Name"
          value={name}
          placeholder="Your full name"
          error={error?.name}
          onChangeText={setName}
          keyboardType="email-address" />
        <TextInput02 
          label="Email"
          value={email}
          placeholder="me@email.com"
          error={error?.email}
          onChangeText={setEmail}
          keyboardType="email-address" />
        <View>
          <TextInput02 
            label="Message"
            value={message}
            multiline
            placeholder="Your message..."
            error={error?.message}
            borderStyle={styles.borderStyle}
            inputStyle={{paddingTop: 10}}
            onChangeText={setMessage} />
        </View>
        <Button01 
          label="SUBMIT"
          onPress={onSubmit}
          isLoading={loading}
          style={styles.btn_submit} />
      </View>
    </View>
  );
}

export default Fields;