import React from "react";
import NewPassword from "./new-password";
import ForgotProvider from "./provider";
import Success from "./success";

function ResetPassword() {

  return (
    <ForgotProvider>
      <>
        <NewPassword />
        <Success />
      </>
    </ForgotProvider>
  );
}

export default ResetPassword;