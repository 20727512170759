import React from "react";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import { Button02 } from "@src/components/button";
import { Text, View, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";

function HowItWorks(props: any) {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const onPress = () => {
    if(typeof props.onPress === "function"){
      props.onPress();
    }else{
      navigation.navigate("Signin");
    }
  };

  return (
    <View style={styles.container} >
      <LinearGradient
        colors={[colors.violet6, colors.violet5]}
        style={[styles.linear_style]}
      />
      <View style={styles.v_details} onLayout={props.onLayout}>
        <Image style={styles.i_howitworks} source={images.ic_howitworks} resizeMode='contain' />
        <View style={styles.divider} />
        <View style={styles.v_info}>
          <Text style={styles.t_title} >How it works</Text>
          <Text style={[styles.t_subtitle, styles.t_subtitle1]} >Redeem rewards with Gameworkz Rewardz.</Text>
          <Text style={[styles.t_subtitle, styles.t_subtitle2]} >Follow these 3 easy steps.</Text>
          <Text style={[styles.t_subtitle, styles.mart10]} >1. Play Feature Games in Fortune 8</Text>
          <Text style={styles.t_subtitle} >2. Earn GWz Gold Coins </Text>
          <Text style={styles.t_subtitle} >3. Go to Redeem and select your reward.</Text>
          <Text style={styles.t_terms} >Terms and conditions apply</Text>
          <Button02 
            label={props.label || "LOG IN NOW"}
            style={styles.btn_signup}
            bgfocus={colors.red1}
            labelStyle={styles.label_signup} 
            onPress={onPress} />
        </View>
      </View>
    </View>
  );
}

export default HowItWorks;
