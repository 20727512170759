import React from "react";
import { useNavigation } from "@react-navigation/native";
import { Text, View, Image } from "react-native";
import { Button01 } from "@src/components/button";
import useStyles from "./styles.css";
import images from "@assets/images";

import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  interpolate,
  Extrapolation,
} from "react-native-reanimated";

const AnimatedImage = Animated.createAnimatedComponent(Image);

function WelcomeContent(props: any) {
  const styles = useStyles();
  const claim = useSharedValue(0);
  const logo = useSharedValue(0);
  const navigation = useNavigation<any>();

  const claimStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      claim.value,
      [0, 1],
      [200, 0],
      Extrapolation.CLAMP
    );

    const opacity = interpolate(
      claim.value,
      [0, 0.7, 1],
      [0, 0, 1],
      Extrapolation.CLAMP
    );

    return {
      opacity: opacity,
      transform: [{ translateY }],
    };
  }, []);

  const logoStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      logo.value,
      [0, 1],
      [300, 0],
      Extrapolation.CLAMP
    );

    const opacity = interpolate(
      logo.value,
      [0, 0.65, 1],
      [0, 0, 1],
      Extrapolation.CLAMP
    );

    return {
      opacity: opacity,
      transform: [{ translateY }],
    };
  }, []);

  React.useEffect(() => {
    claim.value = withTiming(1, { duration: 500 });
    logo.value = withTiming(1, { duration: 1200 });
  }, []);

  return (
    <View style={styles.container} onLayout={props.onLayout}>
      <Text style={styles.t_title} >Welcome to Gameworkz Rewardz</Text>
      <Text style={styles.t_subtitle} >
        Gameworkz Rewardz offers real value in entertainment.{"\n"}
        Add excitement to your game play with real rewards.
      </Text>
      <View style={styles.view_started}>
        <Image style={styles.image_started} source={images["welcome-rewardz"]} resizeMode="stretch" />
        <View style={styles.v_logo_claim}>
          <View style={styles.v_logo}>
            <AnimatedImage style={[styles.i_logo, logoStyle]} source={images["logo"]} resizeMode="stretch" />
          </View>
          <View style={styles.v_claim}>
            <AnimatedImage style={[styles.i_claim, claimStyle]} source={images["claim"]} resizeMode="stretch" />
          </View>
          <Text style={styles.t_joinnow} >Join now and start playing for real prizes. </Text>
          <Button01 onPress={() => navigation.navigate("Signin")} 
            style={styles.btn_signup}
            labelStyle={styles.label_signup}
            label="LOG IN NOW " />
        </View>
      </View>
    </View>
  );
}

export default WelcomeContent;
