import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      marginTop: 64,
      marginBottom: 100,
      alignItems: "center", 
    },
    v_details: { maxWidth: 850, alignItems: "center" },
    t_login: {
      fontSize: 32, 
      lineHeight: 38,
      textAlign: "center",
      color: colors.black,
      fontFamily: "Montserrat-Bold", 
    },
    t_requirement: {
      fontSize: 18, 
      marginTop: 15,
      lineHeight: 27,
      color: colors.black, 
      textAlign: "center",
      fontFamily: "Montserrat-Bold",
    },

    t_notes: {
      fontSize: 14, 
      marginTop: 5,
      lineHeight: 21,
      color: colors.black, 
      textAlign: "center",
      fontFamily: "Montserrat", 
    },
    v_inputs: {width: 500, maxWidth: 500, marginTop: 25},
    btn_submit: { marginTop: 35, height: 41, backgroundColor: colors.violet },
    t_forgot_password: {
      fontSize: 16, 
      marginTop: 20,
      lineHeight: 25,
      color: colors.black, 
      textAlign: "left",
      fontFamily: "Montserrat-Medium", 
    },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      container: { paddingHorizontal: 25 },
      v_details: { maxWidth: "100%" },
      v_inputs: { width: "100%" },
    },
  }
);

export default useStyles;
