import React from "react";
import { ActivityIndicator, Pressable, StyleSheet, Text } from "react-native";
import { Button03Props } from "./types";
import colors from "@assets/colors";

const Button02 = (props: Button03Props) => {
  const { onPress, style, disabled, children } = props;
  const [focus, setFocus] = React.useState<boolean>(false);
  const labelfocus = { color: props.color || colors.white };
  const bgfocus = { backgroundColor: props.bgfocus || colors.green1 };

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      onHoverIn={() => setFocus(true)}
      onHoverOut={() => setFocus(false)}
      style={[styles.buttonStyle, style, focus && bgfocus]}
    >
      {props.isLoading ? (
        <ActivityIndicator
          animating
          size="small"
          color={props.color || "white"}
        />
      ): 
        children || (
          <Text selectable={false} style={[styles.labelStyle, props.labelStyle, focus && labelfocus]}>
            {props.label || "Next"}
          </Text>
        )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    height: 35,
    borderRadius: 4,
    alignItems: "center",
    paddingHorizontal: 20,
    justifyContent: "center",
    backgroundColor: colors.white,
  },
  labelStyle: {
    fontFamily: "Inter-Medium",
    fontSize: 12,
    color: colors.black,
  },
});

export default React.memo(Button02);
