import React, { useState } from "react";
import { View, Text, Pressable } from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import useStyles from "./styles.css";

const FAQItem = ({ item }: any) => {
  const styles = useStyles();
  const [visible, setVisible] = useState(false);

  return (
    <View style={styles.cardContainer}>
      <Pressable onPress={() => setVisible(!visible)} style={styles.cardHeader}>
        <Text selectable={false} style={styles.title}>{item.title}</Text>
        <Icon size={24} name={visible ? "chevron-up" : "chevron-down"} />
      </Pressable>

      {visible && <View style={styles.v_info}>
        <Text style={styles.description}>{item.description}</Text>
      </View>}
    </View>
  );
};

export default React.memo(FAQItem);
