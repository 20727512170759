import React from "react";
import Success from "./success.svg";
import Menu from "./menu.svg";
import { SvgProps } from "react-native-svg";

export interface IconProps extends SvgProps {
  name: "success" | any;
  size?: number;
  stroke?: string;
  fill?: string;
}

const SVGIcon = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "success":
    return <Success width={width || 96} height={height || 95} {...props} />;
  case "menu":
    return <Menu width={width || 26} height={height || 27} {...props} />;
  default:
    return null;
  }
};

export default SVGIcon;
