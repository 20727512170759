import { useFetchPost } from "@src/utils/api/hooks";
import React, { useCallback } from "react";

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  loading: boolean;
  data: any;
  error: any;
  onForgot: (value: any) => void;
}

export const ForgotContext = React.createContext<ContextValue>(
  {
    error: { message: ""}
  } as ContextValue
);

const ForgotProvider = ({ children }: ProviderProps) => {
  const { loading, data, error, runRequest } = useFetchPost();

  const onForgot = useCallback((params: any) => {
    runRequest("/api/v1/redeem/user/changePasswordRequest?email="+params.email, params);
  }, [error]);

  return (
    <ForgotContext.Provider value={{ loading, data, error, onForgot }}>
      {children}
    </ForgotContext.Provider>
  );
};

export default ForgotProvider;
