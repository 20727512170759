import React from "react";
import { View, ScrollView, Text, Image} from "react-native";
import UnSignedHeader from "../header/UnSignedHeader";
import Footer from "@src/screens/dashboard/footer";
import useStyles from "./styles.css";
import images from "@assets/images";

export default function TermsCondition() {
  const styles = useStyles();

  return (
    <>
      <UnSignedHeader />
      <View style={styles.flex1}>
        <Image style={styles.image_background} source={images.bg_policy} resizeMode="cover" />
        <ScrollView style={styles.flex1}>
          <View style={styles.container}>
            <Image style={styles.image_logo} source={images.logo} resizeMode="contain" />
            <Text style={styles.t_title}>GAMEWORKZ REWARDZ TERMS & CONDITIONS</Text>
            <View style={styles.v_pdf}>
              <Text style={[styles.t_note_title, { marginTop: 0, fontFamily: "Montserrat-Bold"}]}>
              TERMS + CONDITIONS</Text>
              <Text style={styles.t_note_title}>1. ACCEPTANCE OF TERMS</Text>
              <Text style={[styles.t_note]}>Welcome to GameWorkz (GWz) Fortune8. The phrase “GWz Fortune8” refers to the Fortune8 App (“App”), website (https://play.fortune8.online) (www.gameworkz.fun) (www.fortune8.online), any GameWorkz software application that is installed anywhere on your computer system or mobile device, and a system of portals, online applications, and social media applications employed to enhance your online experience.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>This page and the documents referred to herein tell you the Terms & Conditions of Use (“Terms of Use”) on which you may use Fortune8. Please read these Terms & Conditions of Use carefully before using Fortune8. These terms may have changed since your last visit. If you do not agree to the Terms of Use, please refrain from using Fortune8 applications.</Text>
              <Text style={styles.t_note_title}>2. SCOPE OF SERVICE</Text>
              <Text style={[styles.t_note]}>GameWorkz Members and guests shall be referred to in the singular as “User” or “you” and in the plural as “Users”. You must be 18+ years of age or older to use Fortune8. You are responsible for obtaining any computer or mobile device and Internet service necessary to access Fortune8 and for paying any fees for the equipment and service you select. You are responsible for ensuring that all persons who access Fortune8 through your computer or mobile device are aware of all these terms and conditions and comply with them.</Text>
              <Text style={styles.t_note_title}>3. INFORMATION ABOUT GAMEWORKZ & FORTUNE8</Text>
              <Text style={[styles.t_note]}>GameWorkz and Fortune8 are owned and operated by AZ Consulting EOOD (AZ) and, as such, constitute an agreement between the players and the Fortune 8 website and App.</Text>
              <Text style={styles.t_note_title}>4. ACCESSING FORTUNE8</Text>
              <Text style={[styles.t_note]}>Accessing Fortune8 is permitted on a temporary basis, and AZ reserves the right, at its sole discretion, to withdraw or change Fortune8 without notice. AZ will not be liable if Fortune8 is unavailable at any time or for any reason.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>A. From time to time, AZ may restrict access to some parts or all of Fortune8 to Users.</Text>
              <Text style={[styles.t_note]}>B. If AZ suspects any fraudulent behaviour, AZ reserves the right to suspend or terminate any or all of your accounts. A verified account is required for loyalty prize redemption. One account per user. Multiple users may not share the same account.</Text>
              <Text style={[styles.t_note]}>C. You may establish only one Fortune8 account per person. In the event AZ discovers that you have opened more than one account per person, in addition to any other rights that AZ may have, AZ reserves the right to suspend or terminate any or all of your accounts and suspend or terminate any or all of your account's ability to win some or all of the prizes, and withhold or revoke the awarding of any prizes.</Text>
              <Text style={[styles.t_note]}>D. You may not use multiple devices to access Fortune8 simultaneously. In the event AZ discovers that you are accessing Fortune8 on multiple devices simultaneously, in addition to any other rights that AZ may have, AZ reserves the right to suspend or terminate any or all of your accounts and suspend or terminate any or all of your account's ability to win some or all of the prizes, and withhold or revoke the awarding of any prizes.</Text>
              <Text style={[styles.t_note]}>E. When using Fortune8, you must comply with the provisions of acceptable conduct as set out in these Terms & Conditions of Use.</Text>
              <Text style={[styles.t_note]}>F. Use of Fortune8 may not be legal to use in certain countries. The fact that Fortune8 is accessible in a country or appears in the official language of any such country shall not be construed as a representation or warranty with respect to the legality of your use of Fortune8 in that country.</Text>
              <Text style={styles.t_note_title}>5. FORTUNE8 CONTENT</Text>
              <Text style={[styles.t_note]}>Silver Coins is the virtual social gameplay currency that enables you to play the Standard Play Games. Silver Coins have no monetary value and cannot under any circumstance be redeemed for prizes.</Text>
              <Text style={[styles.t_note]}>Bonus Gold Coins are coins subject to Bonus Gold Coin Rules. We may give you Gold Coins free of charge when you sign up to Fortune8, or as a bonus when you purchase Silver Coins. You may win more Gold Coins when you play Promotional Play Games. YOU CANNOT PURCHASE GOLD COINS.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Users have a personal, non-transferable, non-exclusive right to access and use the Content of Fortune8 subject to these Terms & Conditions of Use. The term “Content” means all information, text, images, data, links, software, or other material accessible through Fortune8, whether created by GameWorkz or provided by another person for display on Fortune8. At times, AZ may act as an intermediary between customers and other companies who provide advertising and other content to enhance the user experience. AZ may direct customers to websites that promote or sell products and services. AZ is not responsible for any issues related to those products and services, including but not limited to shipping, billing, payments, the quality of the product and service, or any misrepresentation of the product and service.</Text>
              <Text style={[styles.t_note]}>The Content may contain typographical errors, other inadvertent errors, or inaccuracies. AZ reserves the right to make changes to document names and content, descriptions or specifications of products and services, or other information without obligation to issue any notice of such changes.</Text>
              <Text style={[styles.t_note]}>You may view, copy, download, and print Content that is available on Fortune8, subject to the following conditions:</Text>
              <Text style={[styles.t_note]}>A. The Content may be used solely for your own informational purposes. No part of Fortune8 or its Content may be reproduced or transmitted in any form, by any means, electronic or mechanical, including photocopying and recording for any other purpose;</Text>
              <Text style={[styles.t_note]}>B. The Content may not be modified; and</Text>
              <Text style={[styles.t_note]}>C. Copyright, trademark, and other proprietary notices may not be removed.</Text>
              <Text style={[styles.t_note]}>Nothing contained on Fortune8 should be construed as granting, by implication, estoppel, or otherwise, any license or right to use Fortune8 or any Content displayed on Fortune8, through the use of framing or otherwise, except: (i) as expressly permitted by these Terms & Conditions of Use; or (ii) with AZ prior written permission or the permission of such third party that may own the trademark or copyright of material displayed on Fortune8.</Text>
              <Text style={styles.t_note_title}>6. OFFERS AND SERVICES DESCRIPTIONS</Text>
              <Text style={[styles.t_note]}>Any products, service, discount prices, promotions, coupons and bonuses that are displayed on Fortune8 by third-party companies (“Offer” or “Offers”) for the benefit of Users are generally available for a designated period of time. AZ cannot verify all information provided by third-party companies, and errors in gathering, reporting, and displaying such information may occur. Fortune8 Users understand and agree that AZ shall not be liable related to the accuracy or availability of any Offers. Any prices listed on Offers are the responsibility of the advertiser and may represent a comparative price estimate and may or may not represent the prevailing price in every geographic area on any particular day. Please note that taxes and other charges may be applicable and applied by the relevant gaming company in connection with any Offer, and AZ refers you to their terms and conditions. If an Offer is not as described, contact the company that is responsible for fulfilling the Offers using the customer service link on their website.</Text>
              <Text style={styles.t_note_title}>7. BONUS GOLD COIN RULES</Text>
              <Text style={[styles.t_note]}>NO PURCHASE OR PAYMENT IS NECESSARY TO PARTICIPATE. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE A PARTICIPANT’S CHANCES OF WINNING.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Fortune8 gives away free-of-charge Bonus Gold Coins as set out in these Rules. Gold Coins can be used to participate in games of Promotional Play only and through playing offers a chance to win further Gold Coins. Once qualified by meeting our play-through requirements, these gold coins convert to GWz Gold Coins, which are redeemable for real GWz Rewardz. You cannot purchase Bonus Gold Coins they can only be obtained as a bonus when purchasing silver coins or through promotional giveaways.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>These bonus gold rules are to be read in conjunction with these Terms and Conditions (T&Cs) and the GWz Rewardz T&Cs. Terms that are defined in the GWz Rewardz T&Cs have the same meaning in these rules.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>We may give you Bonus Gold Coins free of charge when you sign up to Fortune8 or as a bonus when you purchase Silver Coins. YOU CANNOT PURCHASE BONUS GOLD COINS.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>It is the sole responsibility of a Participant to determine whether promotional play using bonus gold coins is legal and compliant with all regulations in the jurisdiction in which the Participant resides.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Participation constitutes the Participant’s full and unconditional agreement to these Bonus Gold Rules and AZ’s decisions, which are final and binding in all matters related to these rules.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Redeeming a GWz Reward is contingent upon fulfilling all requirements set out in these bonus gold rules.</Text>
              <Text style={styles.t_note_title}>HOW TO COLLECT BONUS GOLD COINS</Text>
              <Text style={[styles.t_note]}>To Participate, you must have an active Fortune8 Account.</Text>
              <Text style={[styles.t_note]}>Receive Bonus Gold Coins as a free bonus when purchasing Silver Coins. Each Participant may receive Bonus Gold Coins as a bonus upon the purchase of specifically marked packs of Silver Coins. Silver Coins can be used for standard games played in Fortune8. Silver Coins cannot be redeemed for GWz Rewardz. The number of Bonus Gold Coins a Participant will receive as a bonus for each relevant</Text>
              <Text style={[styles.t_note]}>Silver Coin purchase will be stated in the website purchase window. All purchases of Silver Coins are final, and no refunds will be given.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Receive Bonus Gold Coins when entering no-cost giveaway contests on the Fortune8 Facebook page. AZ regularly holds no-cost giveaway contests, which Participants can enter by following the instructions provided in the contest. These giveaways may require sharing the Facebook page posts or answering various game-related questions to be eligible for entry. The number of bonus gold coins given away will be stated on the applicable no-cost contest post and will be credited to each contest winner’s customer account.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>The sum of bonus and qualified gold coins a participant has will be displayed in their gold coin account on the Fortune8 app/website. The number of bonus gold coins to be allocated to participants can be changed at any time by AZ at its sole discretion.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Using any automated or other system(s) to acquire Bonus Gold Coins or play the games is prohibited and will result in disqualification and loss of eligibility to Participate in the games.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Bonus Gold Coins are only valid for sixty (60) days from the date a Participant last logged on to their Customer Account and will then automatically expire.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Bonus and qualified Gold Coins may also be forfeited if a Participant’s Customer Account is closed for any reason or at AZ’s discretion.</Text>
              <Text style={styles.t_note_title}>USING GOLD COINS TO PLAY GAMES</Text>
              <Text style={[styles.t_note]}>Participants with Bonus or qualified GWz Gold Coins can use those Coins to play games within Fortune8 for a chance to win additional Gold Coins. Gold Coins must be qualified to be redeemed for GWz Rewardz and gifts.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Playthrough requirement(s) means the multiplier connected to specific Bonus Gold Coins that a Participant must play through before the Bonus Gold Coins become qualified and convert to GWz Gold Coins that may be redeemed. For example, if a Participant receives 10 Bonus Gold Coins and those Gold Coins have a Playthrough Requirement multiplier of 10x, a Participant must play games totalling 100 Bonus Gold Coins prior to those Gold Coins converting to GWz Gold Coins and being eligible for GWz Rewardz redemptions.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Within Fortune8, there are different games. The number of gold coins required to play each game will be detailed within the game. A Participant may be able to increase the amount of Gold Coins they play the game with so they can increase their potential redeemable GWz Rewardz balance.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Only games played with Gold Coins allow qualified GWz Gold Coins to be redeemed for GWz Rewardz. Each Gold Coin that has been qualified through gameplay (rather than collected using one of the methods described in these Rules) can be redeemed for a reward with a value equivalent to US$1.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>AZ’s decisions as to the administration and operation of the GWz Rewardz program, the game, and the number of qualified GWz Gold Coins are final and binding.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Unless AZ requires otherwise, any Bonus Gold Coins allocated to a Participant are required to be played a minimum of 10 times before they are eligible to be redeemed for GWz Rewardz. AZ may, in its sole discretion, require that any Bonus Gold Coins allocated to a Participant must be played a greater number of times (not exceeding 20) in any combination of Gold Coins games before they are eligible to be redeemed for GWz rewardz.</Text>
              <Text style={styles.t_note_title}>GWz Rewardz</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>A Participant’s qualified and unqualified Gold Coin balance can be found via this link and by logging in with your registered Fortune8 credentials: https://fortune8.world/welcome</Text>
              <Text style={[styles.t_note]}>Where a Participant has chosen to redeem GWz Coins for cash, AZ reserves the right to require the use of the same payment method for the redemption as was used to purchase the Silver Coins package or a specific payment method at AZ’s discretion.</Text>
              <Text style={[styles.t_note]}>Where a Participant has chosen to redeem GWz Coins for gift cards, the gift card voucher will be allocated to the email address that the Participant has registered against their Customer Account.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>AZ will only process a reward redemption if the redeemed value of the reward is US$100 or more (i.e. a minimum of 100 eligible GWz Coins). Consequently, a Participant’s Customer Account must have a balance of at least 100 qualified GWz Coins before requesting a reward redemption. AZ reserves the right to set maximum daily and monthly redemption limits.</Text>
              <Text style={[styles.t_note]}>TO BE ELIGIBLE FOR GWz COINS REWARD REDEMPTION:</Text>
              <Text style={[styles.t_note]}>1. (a) A PARTICIPANT MUST BE AN ELIGIBLE PARTICIPANT; AND</Text>
              <Text style={[styles.t_note]}>2. (b) THE PARTICIPANT’S DETAILS MUST MATCH THOSE OF THE PARTICIPANT’S CUSTOMER ACCOUNT.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>The maximum value of Gold Coins won on any one spin or play via a Participant’s participation in Gold enabled Games is US$2,500. Any single win valued in excess of US$2,500 will be reduced to a maximum value of US$2,500.</Text>
              <Text style={[styles.t_note]}>AZ reserves the right, in its sole discretion, to limit a Participant’s redemption of qualified GWz Coins to US$500 per day and $2,500 per month.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>AZ is not responsible for any taxes or fees associated with a reward redemption. Participants are responsible for all applicable taxes and fees associated with reward redemptions.</Text>
              <Text style={[styles.t_note]}>AZ is not responsible for any foreign exchange transaction fees, charges or related costs that may be incurred as a result of, or in relation to, a reward redemption, including but not limited to any losses or additional costs arising from foreign exchange fluctuations.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Qualified GWz Coins are not transferable, and no substitution will be made except as provided herein at AZ’s sole discretion. AZ reserves the right to substitute the listed reward of equal or greater value for any reason owing to circumstances outside AZ’s reasonable control.</Text>
              <Text style={styles.t_note_title}>8. INDEMNIFICATION</Text>
              <Text style={[styles.t_note]}>BY USING FORTUN8, YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS AZ, ITS PARENT COMPANY, AFFILIATES, AND SUBSIDIARY COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, CONSULTANTS, AGENTS, SUPPLIERS, ADVERTISERS, GAMING COMPANY PARTNERS, AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS FROM ANY AND ALL THIRD PARTY CLAIMS, LIABILITY, DAMAGES AND COSTS (INCLUDING, BUT NOT LIMITED TO, LAWYERS' FEES AND ALL COSTS AND EXPENSES AZ MAY INCUR IN THE DEFENCE OF SUCH CLAIMS (INCLUDING ANY VAT THEREON) ARISING FROM YOUR BREACH OF THESE TERMS OF USE OR PRIVACY POLICIES, OR YOUR INFRINGEMENT, OR INFRINGEMENT BY ANY OTHER USER OF YOUR ACCOUNT, OF ANY INTELLECTUAL PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY.</Text>
              <Text style={styles.t_note_title}>9. INFORMATION ABOUT YOU AND YOUR USE OF FORTUNE8</Text>
              <Text style={[styles.t_note]}>AZ processes information about you in accordance with AZ’s privacy policy. By using Fortune8, you consent to such processing.</Text>
              <Text style={[styles.t_note]}>You may use Fortune8 as a guest without volunteering personally identifiable information (“Personally Identifiable Information” or “PII”). Please refer to the AZ Privacy Policy for additional information on AZ’s practices for processing PII.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>If you choose to provide information by becoming GameWorkz members or by participating in an Offer, service, event, or promotion offered by AZ, you agree that you will provide accurate, complete, and up-to-date information.</Text>
              <Text style={styles.t_note_title}>10. DOWNLOADING THE GWz FORTUNE8 APP</Text>
              <Text style={[styles.t_note]}>If you choose to download or install the GameWorkz Fortune8 App you shall be subject to these terms and the End User License Agreement (“EULA”). The terms under which the App is downloaded or installed onto your computer or mobile device shall be governed by the EULA, and in the case of any inconsistency between the EULA and these Terms & Conditions of Use, the EULA will prevail.</Text>
              <Text style={styles.t_note_title}>11. INTERACTIVE SERVICES - CONTENT SUBMITTED BY USERS</Text>
              <Text style={[styles.t_note]}>AZ may occasionally provide interactive services such as Social Media, bulletin boards, forums and chat rooms (“Interactive Services”). Interactive Services are strictly subject to these Terms & Conditions of Use.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Any material uploaded to Fortune8 or submitted for inclusion on Fortune8’s Interactive Services shall be considered non-confidential and non-proprietary, and AZ has the right to use, copy, distribute and disclose to third parties any such material for any purpose. AZ also has the right to disclose your identity to any third party, claiming that any material posted or uploaded by you to Fortune8’s Interactive Services constitutes a violation of their intellectual property rights or their right to privacy.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>When you submit any material to Fortune8’s Interactive Services, you grant us the non-exclusive royalty-free and fully sub-licensable right to use, remove, modify, adapt, publish, translate, create, deduct works from, distribute and display such content throughout the world in any media. Further, you grant Fortune8, its parent company, subsidiaries and affiliates the right to use the name, images, and other information you submit in connection with such content if AZ chooses.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>You agree that the rights you grant in the clause above are irrevocable during the entire period of protection of your intellectual property rights associated with such content and material. You agree to waive your rights to be identified as the author of such content and material and your right to object to derogatory treatment of such material. You agree to perform all further acts necessary to perfect any of the above rights granted by you to AZ at AZ’s request.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>You represent and warrant that you control all of the rights to the content you post.</Text>
              <Text style={[styles.t_note]}>A. Liability</Text>
              <Text style={[styles.t_note]}>AZ is not responsible or liable for the conduct of users or for views, opinions, and statements expressed in content submitted for public display through Fortune8 or on Interactive Services. AZ does not pre- screen information posted to Interactive Services. With respect to Interactive Services, AZ is acting as a passive conduit for such distribution and is not responsible for the content. Any opinions, advice, statements, services, Offers, or other information displayed on Interactive Services as expressed or made available by Users are those of the respective author(s) or distributor(s) and not of AZ. AZ neither endorses nor guarantees any content's accuracy, completeness, or usefulness on Fortune8’s Interactive Services.</Text>
              <Text style={[styles.t_note]}>B. Monitoring</Text>
              <Text style={[styles.t_note]}>AZ has the right, but not the obligation, to monitor content submitted to Interactive Services to determine compliance with these Terms of Use and any other applicable rules that AZ may establish. AZ has the right, at its sole discretion, to edit or remove any material submitted to or posted in any online forum provided through Fortune8. Without limiting the foregoing, AZ has the right to remove any material that AZ, in its sole discretion, finds to be in violation of these Terms & Conditions of Use or otherwise objectionable, and you are solely responsible for the Content that you post on Interactive Services.</Text>
              <Text style={styles.t_note_title}>12. PROHIBITED CONDUCT</Text>
              <Text style={[styles.t_note]}>By accessing Fortune8 or Interactive Services provided through Fortune8, you agree to abide by the following standards of conduct. You may only use Fortune8 for lawful purposes. You agree that you will not, and will not authorize or facilitate any attempt by another person to use Fortune8 or Interactive Service:</Text>
              <Text style={[styles.t_note]}>A. To transmit any Content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, offensive, obscene, pornographic, lewd, lascivious, or otherwise objectionable, as determined by AZ.</Text>
              <Text style={[styles.t_note]}>B. To send, knowingly receive, upload, download, use or reuse material that does not comply with AZ’s content standards as set out in these Terms & Conditions of Use or use a name or language that AZ deems offensive in its sole discretion.</Text>
              <Text style={[styles.t_note]}>C. To transmit or procure the sending of any unsolicited or unauthorized advertising or promotional material or any other forms of similar solicitation (spam) or unlawfully promote offers or services.</Text>
              <Text style={[styles.t_note]}>D. To promote, solicit, or participate in any multi-level marketing or pyramid schemes.</Text>
              <Text style={[styles.t_note]}>E. To exploit, harm, or attempt to harm children under 18+ years of age in any way.</Text>
              <Text style={[styles.t_note]}>F. To engage in disruptive activity, such as sending multiple messages to monopolize Interactive Services.</Text>
              <Text style={[styles.t_note]}>G. Introduce any data, send, or upload any material that contains viruses, worms, Trojan horses, time bombs, keystroke loggers, spyware, adware or other harmful programs or similar code designed to adversely affect the operation of any computer software or hardware. To obtain unauthorized access to any computer system through Fortune8.</Text>
              <Text style={[styles.t_note]}>H. To invade the privacy of any person, including but not limited to posting personally identifying or otherwise private information about a person without his or her consent (or a parent’s consent in the case of a child under 18+ years of age).</Text>
              <Text style={[styles.t_note]}>I. To solicit personal information from children under 18+ years of age.</Text>
              <Text style={[styles.t_note]}>J. In any way that breaches any applicable local or international law or regulation.</Text>
              <Text style={[styles.t_note]}>K. In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</Text>
              <Text style={[styles.t_note]}>L. To encourage conduct that would constitute a criminal or civil offence.</Text>
              <Text style={[styles.t_note]}>M. To reproduce, duplicate, copy or resell any part of GameWorkz in contravention of these Terms & Conditions of Use.</Text>
              <Text style={[styles.t_note]}>You also agree not to access without authority, interfere with, damage or disrupt:</Text>
              <Text style={[styles.t_note]}>A. any part of Fortune8;</Text>
              <Text style={[styles.t_note]}>B. any equipment or network on which Fortune8 is stored;</Text>
              <Text style={[styles.t_note]}>C. any software used in the provision of Fortune8; or</Text>
              <Text style={[styles.t_note]}>D. any equipment network or software owned or used by any third party.</Text>
              <Text style={styles.t_note_title}>13. CONTENT STANDARDS</Text>
              <Text style={[styles.t_note]}>These content standards apply to all material you contribute to Fortune8 and any Interactive Services associated with Fortune8 (“Contributions”).</Text>
              <Text style={[styles.t_note]}>You must comply with the letter and spirit of the following standards. The standards apply to each part of any contribution as well as to its whole. In its sole discretion, AZ will decide if Contributions are not in compliance with the following standards.</Text>
              <Text style={[styles.t_note]}>CONTRIBUTIONS MUST:</Text>
              <Text style={[styles.t_note]}>A. be accurate (where they state facts);</Text>
              <Text style={[styles.t_note]}>B. be genuinely held (where they state opinions); and</Text>
              <Text style={[styles.t_note]}>C. comply with applicable law in any country from which they are posted.</Text>
              <Text style={[styles.t_note]}>CONTRIBUTIONS MUST NOT:</Text>
              <Text style={[styles.t_note]}>A. contain any material that is defamatory to any person;</Text>
              <Text style={[styles.t_note]}>B. contain any material which is obscene, offensive, hateful or inflammatory;</Text>
              <Text style={[styles.t_note]}>C. promote sexually explicit material;</Text>
              <Text style={[styles.t_note]}>D. promote violence;</Text>
              <Text style={[styles.t_note]}>E. promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</Text>
              <Text style={[styles.t_note]}>F. infringe any copyright, database right or trademark of any other person;</Text>
              <Text style={[styles.t_note]}>G. be likely to deceive any person;</Text>
              <Text style={[styles.t_note]}>H. be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</Text>
              <Text style={[styles.t_note]}>I. promote any illegal activity;</Text>
              <Text style={[styles.t_note]}>J. be threatening, abusing or invading another's privacy, or cause annoyance, inconvenience or needless anxiety;</Text>
              <Text style={[styles.t_note]}>K. be likely to harass, upset, embarrass, alarm or annoy any other person;</Text>
              <Text style={[styles.t_note]}>L. be used to impersonate any person, or to misrepresent your identity or affiliation with any person;</Text>
              <Text style={[styles.t_note]}>M. give the impression that they emanate from us, if this is not the case, and</Text>
              <Text style={[styles.t_note]}>N. advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</Text>
              <Text style={styles.t_note_title}>14. CHILDREN</Text>
              <Text style={[styles.t_note]}>Fortune8 is intended for an adult audience only and is not in any way targeted at children underage 18+. AZ does not knowingly solicit market to or collect information from anyone under 18+.</Text>
              <Text style={styles.t_note_title}>15. INTELLECTUAL PROPERTY RIGHTS</Text>
              <Text style={[styles.t_note]}>Unless otherwise noted, all Content on Fortune8 is the property of AZ, its parent company, affiliates, or licensors. It is protected from unauthorized copying and dissemination by copyright laws, trademark laws, international conventions and treaties, and other intellectual property laws. All such rights are reserved. Offer names are trademarks or registered trademarks of their respective owners.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>AZ does not claim ownership of Content submitted by Users, but Users understand and agree that such Content may be made publicly accessible through Fortune8. By submitting such Content, you agree to grant us a worldwide, royalty-free, perpetual, irrevocable, non-exclusive license to use, distribute, reproduce, modify, adapt, create derivative works from, and publicly perform or display such Content. This license shall remain in effect until AZ deletes the Content from its systems.</Text>
              <Text style={styles.t_note_title}>16. NOTICE AND TAKE DOWN</Text>
              <Text style={[styles.t_note]}>Contributions to Interactive Services are not moderated by us. AZ will not be responsible as author, editor, or publisher of any contribution submitted to Interactive Services, and AZ expressly excludes AZ’s liability for any loss or damage arising from the use of Interactive Services by any person in contravention of these Terms & Conditions of Use.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>If AZ has any reason to believe or is given notice that any contribution submitted to Interactive Services is in breach of the Content standards listed in these Terms of Use, AZ shall, without notice to you, withdraw the Contribution, and AZ reserves the right to terminate your Fortune8 account. Further, AZ expressly excludes AZ’s liability to you for removing any contribution you make to Interactive Services.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>If you wish to complain about any Contribution posted to Interactive Services, please contact AZ at support@fortune.world. AZ will review the Contribution and decide whether it complies with the Content standards set out in these Terms & Conditions of Use. AZ will deal with any Contribution which, in AZ’s opinion, violates AZ’s Content standards. AZ will inform you of the outcome of its review within a reasonable time of receiving your complaint. Please precisely identify the Content about which you are notifying us, including the page upon which it is posted (a screenshot of the Content you are complaining about would also be helpful). You should also provide AZ with details of your complaint. If you are alleging copyright infringement, please provide AZ evidence that you own the copyright.</Text>
              <Text style={styles.t_note_title}>17. SECURITY</Text>
              <Text style={[styles.t_note]}>When you register to participate in an online photo, video, or discussion forum, promotion, or other service promoted on Fortune8, you may be required to establish a login identifier and a password. You are responsible for protecting your login and password from unauthorized use (including but not limited to preventing use by persons under the age of 18+), and you are responsible for all activity that occurs on your account. You agree to notify AZ immediately if you believe that your login or password has been or may be used without your permission so that appropriate action can be taken. AZ is not responsible for losses or damage caused by your failure to safeguard your login and password.</Text>
              <Text style={styles.t_note_title}>18. VIRUSES, HACKING AND OTHER OFFENCES</Text>
              <Text style={[styles.t_note]}>You agree to not misuse Fortune8 by knowingly introducing viruses, trojans, worms, logic bombs or other malicious or technologically harmful material. You agree not to attempt to gain unauthorized access to Fortune8, the server on which Fortune8 is stored or any server, computer or database connected to Fortune8. You agree not to attack Fortune8 via a denial-of-service or distributed denial- of-service attack.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>AZ will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, mobile device, computer programs, software, data or other proprietary material due to your use of Fortune8 or to your downloading of any material posted on or linked to Fortune8.</Text>
              <Text style={styles.t_note_title}>19. LIMITATION OF LIABILITY</Text>
              <Text style={[styles.t_note]}>The material and Offers displayed by Fortune8 are provided without any guarantees, conditions or warranties as to their accuracy. To the extent permitted by law, AZ, its parent company, affiliates, subsidiary companies and third parties connected to AZ hereby expressly exclude:</Text>
              <Text style={[styles.t_note]}>A. All conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity;</Text>
              <Text style={[styles.t_note]}>B. Any liability for any direct, indirect, or consequential loss or damage incurred by any User in connection with Fortune8 or in connection with the use, inability to use, or results of the use of Fortune8, any websites or mobile applications linked to it and any materials posted on it, including:</Text>
              <Text style={[styles.t_note]}>a. All conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity; and</Text>
              <Text style={[styles.t_note]}>b. Any liability for any direct, indirect, or consequential loss or damage incurred by any User in connection with Fortune8 or in connection with the use, inability to use, or results of the use of Fortune8, and any Offers or materials posted on Fortune8, including:</Text>
              <Text style={[styles.t_note]}>i. loss of income or revenue; 10</Text>
              <Text style={[styles.t_note]}>ii. loss of business;</Text>
              <Text style={[styles.t_note]}>iii. loss of profits or contracts;</Text>
              <Text style={[styles.t_note]}>iv. loss of anticipated savings;</Text>
              <Text style={[styles.t_note]}>v. loss of data;</Text>
              <Text style={[styles.t_note]}>vi. loss of goodwill;</Text>
              <Text style={[styles.t_note]}>vii. wasted management or office time, and</Text>
              <Text style={[styles.t_note]}>viii. whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable, provided that this condition shall not prevent claims for loss of or damage to your tangible property or any other claims for direct financial loss that are not excluded by any of the categories set out above.</Text>
              <Text style={[styles.t_note]}>This does not affect AZ’s liability for death or personal injury arising from AZ’s negligence, or AZ’s liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, or any other liability that cannot be excluded or limited under applicable law.</Text>
              <Text style={styles.t_note_title}>20. LINKS TO THIRD-PARTY WEBSITES AND MOBILE APPLICATIONS</Text>
              <Text style={[styles.t_note]}>Fortune8 may contain links to third-party websites and mobile applications that are provided to you as a convenience. Any third-party website or mobile application accessed from Fortune8 is independent of Fortune8, and AZ has no control over the content of such third-party websites or mobile applications. AZ is not responsible for the content of any linked third-party website or mobile application or for any loss or damage incurred in connection with your use of such third-party websites or mobile applications or dealings with the operators of such third-party websites or mobile applications.</Text>
              <Text style={styles.t_note_title}>21. LINKING TO FORTUNE8</Text>
              <Text style={[styles.t_note]}>You may link to Fortune8, provided that you do so in a way that is fair and legal and does not damage AZ's reputation or that of AZ advertisers or AZ partners or take advantage of such reputations, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on AZ’s part (or that of the advertisers) where none exists.</Text>
              <Text style={[styles.t_note]}>You must not establish a link from any website or mobile application that is not owned by you.</Text>
              <Text style={[styles.t_note]}>Fortune8 may not be framed on any other website or mobile application, nor may you create a link to any part of Fortune8 other than the home page. AZ reserves the right to withdraw linking permission without notice. The website or mobile application from which you are linking must comply in all respects with the content standards set out in these Terms of Use and other applicable laws.</Text>
              <Text style={[styles.t_note]}>If you wish to make use of any Fortune8 material other than as set out above, please contact AZ.</Text>
              <Text style={styles.t_note_title}>22. NO IMPLIED ENDORSEMENTS.</Text>
              <Text style={[styles.t_note]}>In no event shall any reference to any Offer be construed as an approval or endorsement by AZ of that third party or of any offer provided by a third party. Likewise, a link to any third-party website or mobile application does not imply that AZ endorses or accepts any responsibility for the content or use of such a website or mobile application. AZ does not endorse, warrant, or guarantee any Offer through Fortune8 and will not be a party to or in any way monitor any transaction involving any third-party providers of Offers. As with the purchase of an Offer through any medium or in any environment, you are responsible for exercising appropriate judgment and caution.</Text>
              <Text style={styles.t_note_title}>23. TERMINATION</Text>
              <Text style={[styles.t_note]}>AZ may terminate any User’s access to Fortune8, including access to any online photo, video, or Interactive Services, in its sole discretion, for any reason and at any time, with or without prior notice. It is AZ’s policy to terminate Users who violate these terms and conditions as deemed appropriate in AZ’s sole discretion. AZ also reserves the right to terminate accounts with offensive names or profile images. You agree that AZ is not liable to you or any third party for any termination of your access to Fortune8.</Text>
              <Text style={styles.t_note_title}>24. JURISDICTION AND APPLICABLE LAW</Text>
              <Text style={[styles.t_note]}>These Terms of Use shall be governed by and interpreted pursuant to the laws of Bulgaria, notwithstanding any principles of conflicts of law, and expressly excluding.</Text>
              <Text style={[styles.t_note]}>(i.) The United Nations Convention on Contracts for the International Sale of Goods,</Text>
              <Text style={[styles.t_note]}>(ii.) the 1974 Convention on the Limitation Period in the International Sale of Goods, and (iii.) the Protocol amending the 1974 Convention, done at Vienna on April 11, 1980.</Text>
              <Text style={[styles.t_note]}>Except where prohibited, you understand and agree that:</Text>
              <Text style={[styles.t_note]}>(i.) any and all complaints, disputes or claims shall be first referred to AZ’s internal complaint-handling system and be dealt with in accordance with the corresponding rules of Fortune8 and</Text>
              <Text style={[styles.t_note]}>(ii.) a complainant who is dissatisfied with the outcome of AZ’s investigating and resolving the complaint may refer the matter to the competent court of jurisdiction in Sofia, Bulgaria, and</Text>
              <Text style={[styles.t_note]}>Under Bulgarian law, a successful claimant is only awarded compensation for direct damages.</Text>
              <Text style={[styles.t_note]}>Notwithstanding the foregoing, AZ shall be entitled to seek injunctive relief, security, or other equitable remedies for any breach of these Terms & Conditions of Use from any court of competent jurisdiction as AZ sees fit.</Text>
              <Text style={styles.t_note_title}>25. SEVERABILITY</Text>
              <Text style={[styles.t_note]}>If, for any reason, any provision of the Terms & Conditions of Use or portion thereof, is found by a court of competent jurisdiction to be unlawful, void, or unenforceable, that part of the Terms of Use will be deemed severable and shall not affect the validity and enforceability of the remainder of these Terms & Conditions of Use which shall continue in full force and effect.</Text>
              <Text style={styles.t_note_title}>26. VARIATIONS</Text>
              <Text style={[styles.t_note]}>AZ may, from time to time, revise these Terms & Conditions of Use by amending this page. You are expected to check this page from time to time and take notice of any changes AZ made, as they are binding on you. Some of the provisions contained in these Terms of Use may also be superseded by provisions or notices published elsewhere in Fortune8.</Text>
              <Text style={styles.t_note_title}>27. ENTIRE AGREEMENT</Text>
              <Text style={[styles.t_note]}>YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS OF USE AND AGREE TO BE BOUND BY ITS TERMS. YOU FURTHER AGREE THAT THESE TERMS OF USE ARE THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN YOU AND AZ, AND SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT, ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS RELATING TO THE SUBJECT MATTER OF THESE TERMS OF USE WITH THE UNDERSTANDING THAT kz AZ MAY, AT ITS SOLE DISCRETION AND WITHOUT NOTICE, REVISE THESE TERMS AT ANY TIME BY UPDATING THIS PAGE.</Text>
              <Text style={styles.t_note_title}>28. FOR ADDITIONAL INFORMATION</Text>
              <Text style={[styles.t_note]}>If you have any questions about these Terms of Use, please feel free to contact AZ via our contact form.</Text>
              <Text style={[styles.t_note, { marginTop: 10}]}>Copyright © 2023, AZ Consulting EOOD All Rights Reserved.</Text>

            </View>
          </View>
          <Footer />
        </ScrollView>
      </View>
    </>
  );
}
