import { redeemStatusName, redeemTypeName } from "./filter-helper";

export const RedeemTransaction = (array: [] = []) => {
  return array.map((item: any) => {
    item.redeemStatusID = redeemStatusName(item.redeemStatusID);
    item.redeemTypeID = redeemTypeName(item.redeemTypeID);
    return item;
  });
};

export const fileName = (url: string) => {
  const decoded = decodeURI(url);

  // eslint-disable-next-line no-useless-escape
  return decoded.replace(/^.*(\\|\/|\:)/, "");
};