import React from "react";
import { selectedInvalidUser, selectedAuthName, selectedCoinBalance, selectedTierID } from "@src/ducks/slices/auth.slice";
import { useNavigation } from "@react-navigation/native";
import { TextInput02 } from "@src/components/textinput";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Button02 } from "@src/components/button";
import { Text, View, Image } from "react-native";
import { AuthContext } from "@src/AuthProvider";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";
import validator from "validator";
import _ from "lodash";

function RedeemYourRewards() {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const tierId = useAppSelector(selectedTierID);
  const fullname = useAppSelector(selectedAuthName);
  const balance = useAppSelector(selectedCoinBalance);
  const [error, setError] = React.useState<string>("");
  const [amount, setAmount] = React.useState<string>("");
  const isUserNotValid = useAppSelector(selectedInvalidUser);
  const { onErrorMessage } = React.useContext(AuthContext);

  const onRedeem = () => {
    if(!amount){
      setError("Required");
      onErrorMessage("Please enter the amount.");
    }else if(!validator.isNumeric(amount)){
      setError("Required");
      onErrorMessage("Invalild amount! Please check.");
    }else if(Number(amount) > balance.GOLD){
      setError("Required");
      onErrorMessage("INSUFICIENT BALANCE");
    }else if(Number(amount) < 100 || Number(amount) > 1000){
      setError("Required");
      onErrorMessage("Please enter amount between 100 to 1000 only.");
    }else if(isUserNotValid){
      onErrorMessage("INCOMPLETE USER PROFILE");
    }else if(tierId <= 1){
      onErrorMessage("Your account must be atleast emerald tier.");
    }else{
      navigation.navigate("RequestForm", { type: "redeem", amount: amount});
    }
  };

  return (
    <View style={styles.container}>
      <Image style={styles.image_bg} source={images["bg_dashboard"]} resizeMode="repeat" resizeMethod="scale" />
      <View style={[styles.image_bg, {backgroundColor: colors.violet2}]} />
      <View style={styles.v_details}>
        <Text style={styles.t_title}>Redeem Your Reward</Text>
        <Text style={[styles.t_user, { marginTop: 24 }]}>HELLO {fullname}</Text>
        <View style={styles.v_row_gold}>
          <Text style={styles.t_yourbalance}>YOUR TOTAL BALANCE AVAILABLE TO REDEEM IS</Text>
          <View style={styles.v_balance}>
            <Image style={styles.image_gold} source={images["ic_gold"]} resizeMode="contain" />
            <Text style={[styles.t_yourbalance, { color: colors.colorPrimary, marginLeft: 7}]} >{balance.GOLD}</Text>
          </View>
        </View>
        <Text style={[styles.t_notes, { marginTop: 25 }]}>Minimum cashback amount 100.00</Text>
        <Text style={styles.t_notes}>Maximum cashback amount 1000.00</Text>
        <Text style={styles.t_notes}>Redemption is limited to 1 time per day per user. </Text>
        <View style={styles.v_row_input}>
          <Text style={styles.t_yourbalance}>ENTER THE AMOUNT  YOU WANT TO REDEEM</Text>
          <TextInput02 
            value={amount}
            style={styles.ti_style}
            onChangeText={setAmount}
            keyboardType="number-pad"
            placeholder="Enter amount..."
            borderStyle={[styles.ti_borderStyle, !_.isEmpty(error) && { borderWidth: 2, borderColor: colors.red}]} />
          <Button02 onPress={onRedeem} style={styles.btn_redeem} labelStyle={styles.label_redeem} label="Redeem" />
        </View>
        <Text style={[styles.t_convert]}>1 GWZ GOLD COIN = US$1.00</Text>
        <Text style={styles.t_fees}>Fees and charges apply. Read our 
          <Text style={{color: colors.gold1}} onPress={() => navigation.navigate("TermsCondition")} > Terms and Conditions </Text>
          for details.</Text>
      </View>
    </View>
  );
}

export default RedeemYourRewards;
