import React from "react";
import { useNavigation } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import { Text, View } from "react-native";
import { ContactContext } from "./provider";
import useStyles from "./styles.css";
import SVGIcon from "@assets/svg";
import _ from "lodash";

function UpdateSuccess() {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { data } = React.useContext(ContactContext);

  if(_.isEmpty(data)){
    return null;
  }

  return (
    <View style={[styles.container]}>
      <SVGIcon name="success" />
      <Text style={[styles.t_success]} >Success</Text>
      <Text style={[styles.t_verify]} >You’ve successfully sent your message</Text>
      <Button01 
        onPress={() => navigation.navigate("Home")}
        label="Go to Home" style={styles.btn_dashboard}
        labelStyle={styles.label_dashboard} />
    </View>
  );
}

export default UpdateSuccess;
