import React from "react";
import { redeemUploadFiles, redeemUploadSave } from "@src/utils/api";
import { selectAuthSession } from "@src/ducks/slices/auth.slice";
import { redeemValidIDs } from "@src/utils/filter-helper";
import * as DocumentPicker from "expo-document-picker";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useRoute } from "@react-navigation/native";
import { useFetchGet } from "@src/utils/api/hooks";
import { Button01 } from "@src/components/button";
import { RequestFormContext } from "../provider";
import PersonalDetails from "./personal-details";
import Dropdown from "@src/components/dropdown";
import FileUploaded from "./file-uploaded";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import colors from "@assets/colors";
import moment from "moment";
import _ from "lodash";

const RequestForm = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const type = route?.params?.type;
  const session = useAppSelector(selectAuthSession);
  const [selected, setSelected] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { loading: loading2, data, runRequest } = useFetchGet();
  const { state, onDispatch } = React.useContext(RequestFormContext);

  const onFetchIDs = () => {
    runRequest("/api/v1/redeem/userDocument/get?userid=" + session.id);
  };

  const onSelecting = () => {
    DocumentPicker.getDocumentAsync({
      type: ["image/*", "application/pdf"],
    })
      .then((res: any) => {
        const file = res.assets[0];
        onUploadingBegin(file);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error", e);
      });
  };

  const onUploadingBegin = async (file: any) => {
    try{
      setLoading(true);
      const formdata = new FormData();
      formdata.append("files", file.file);

      const result = await redeemUploadFiles(formdata, session.token);
      const data = result?.body?.data[0];

      const params = {
        "documentType": {
          "id": redeemValidIDs(selected),
          "name": selected
        },
        "filePath": data.fileUrlDownload,
        "uploadedDate": moment().format(),
        "userID": session.id
      };
      await redeemUploadSave(params, session.token);
      onFetchIDs();
      setLoading(false);
      setSelected("");
    }catch(e){
      console.log("errorerror", e);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      onDispatch("ids")(data);
    }
  },[data]);

  React.useEffect(() => {
    onFetchIDs();
  },[]);

  return (
    <View>
      <View style={styles.container}>
        <PersonalDetails />
        <Text style={styles.t_note} >Please ensure the above information is correct. 2 factor authentication is required to proceed with your request and must match with the account details provided. </Text>
        <View style={styles.v_height50} />
        {type !== "promotional" && <View style={{paddingVertical: 30}}>
          <Text style={[styles.t_title, { fontFamily: "Montserrat-Medium"}]}>
          For security purposes you must provide 3 forms of proof of ID.
          </Text>
          <View style={styles.v_height} />
          <View style={styles.v_upload_details}>
            <View style={styles.v_uploadid}>
              <Text style={[styles.t_upload_file, { color: colors.colorPrimary }]}>UPLOAD YOUR ID</Text>
              <View style={{flexDirection: "row", marginTop: 10}}>
                <Dropdown
                  options={["DRIVERS LICENSE", "PASSPORT", "UTILITY BILL", "STUDENT ID", "OTHER"]}
                  placeholder="SELECT ID TYPE"
                  value={selected}
                  onSelected={setSelected}
                  ItemSeparatorComponent={() => <View style={{height: 2}} />}
                  style={styles.dropdown}
                />
                <Button01 
                  disabled={_.isEmpty(selected) || state.ids.length >= 3} 
                  onPress={onSelecting} 
                  style={styles.btn_upload} 
                  isLoading={loading || loading2}
                  label="UPLOAD FILE" />
              </View>
            </View>
            <FileUploaded onRefresh={onFetchIDs} />
          </View>
        </View>}
      </View>
    </View>
  );
};

export default RequestForm;
