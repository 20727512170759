import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,  
      marginTop: 29,
      paddingHorizontal: "12%"
    },
    v_field: {flexDirection: "row", alignItems: "center", marginTop: 20},
    t_field: {fontFamily: "Montserrat-Medium", fontSize: 14, color: colors.black, marginRight: 20},
    t_field_value: {fontFamily: "Montserrat", fontSize: 12, color: colors.black },
    t_title: {fontFamily: "Montserrat-Bold", fontSize: 24, color: colors.colorPrimary},
    t_subtitle: {fontFamily: "Montserrat-Medium", fontSize: 16, color: colors.black },
    t_note: {fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.colorPrimary },
    v_row_fields: {flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between"},
    v_row: {alignItems: "flex-start", width: "50%"},
    v_height: { height: 30 },
    v_height50: { height: 50 },
    v_upload_details: {flexDirection: "row", justifyContent: "space-between"},
    t_proofid: {fontFamily: "Montserrat-Bold", fontSize: 20, lineHeight: 24, color: colors.colorPrimary},
    v_uploadid: {width: "55%"},
    v_upload_box: {width: "40%"},
    btn_upload: {
      width: 134, height: 37, 
      borderRadius: 3, backgroundColor: colors.green1, 
      marginLeft: 20
    },
    t_upload_file: {fontFamily: "Montserrat", fontWeight: "600", 
      fontSize: 16, lineHeight: 20, color: colors.black },
    v_upload_field: { minWidth: 150, height: 121, marginTop: 10,
      paddingLeft: 20, paddingTop: 10, paddingRight: 20,
      borderWidth: 1, borderColor: colors.black},
    dropdown: { height: 37 },
    v_uploaded: { flexDirection: "row", alignItems: "center", marginTop: 5 },
    t_file: {fontFamily: "Montserrat", fontSize: 12, lineHeight: 20, color: colors.black, marginRight: 10 },

    
  },
  {
    [DEVICE_SIZES.MD]: {
      container: {paddingHorizontal: 20},
      v_row_fields: {flexDirection: "column", alignItems: "flex-start", width: "100%"},
      v_height: { height: 20 },
      v_height50: { height: 30 },
      v_row: { width: "100%" },
      v_upload_details: {flexDirection: "column", marginTop: 10 },
      t_proofid: { fontSize: 14, lineHeight: 17 },
      v_uploadid: {width: "100%"},
      v_upload_box: {width: "100%", marginTop: 40},
      v_upload_field: { height: 150 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: {paddingHorizontal: 20},
      v_row_fields: {flexDirection: "column", alignItems: "flex-start", width: "100%"},
      v_height: { height: 20 },
      v_height50: { height: 30 },
      v_row: { width: "100%" },
      v_upload_details: {flexDirection: "column", marginTop: 10 },
      t_proofid: { fontSize: 12, lineHeight: 14 },
      v_uploadid: {width: "100%"},
      v_upload_box: {width: "100%", marginTop: 40},
    },
  }
);

export default useStyles;
