type PayloadProps = {
  method: string;
  headers: any;
  body?: any;
  mode?: string;
  cache?: string;
  crossDomain?: boolean;
  credentials?: string;
};

class Request {
  private host: string;
  private token?: string;
  private contentType?: string | undefined = undefined;

  constructor(host: string) {
    this.host = host;
  }

  setContentType = (contentType: string) => {
    this.contentType = contentType;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  get = (route: string) => this._request(route, "GET", null);

  post = (route: string, body: any) => this._request(route, "POST", body);

  patch = (route: string, body: any) => this._request(route, "PATCH", body);

  put = (route: string, body: any) => this._request(route, "PUT", body);

  delete = (route: string) => this._request(route, "DELETE", null);

  _request = async (route: string, method: string, body: any | undefined) => {
    const payload: PayloadProps = {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    if (this.token) {
      payload.headers.Authorization = `Bearer ${this.token}`;
    }

    if (method !== "GET" && method !== "HEAD") {
      payload.body = JSON.stringify(body);
    }

    if (this.contentType === "multipart/form-data") {
      payload.headers = {
        Accept: "application/json",
      };
      payload.body = body;
    }

    const url = `${this.host}${route}`;

    return await this._sendHttpRequest(url, payload);
  };

  _sendHttpRequest = async (url: string, payload: any) => {
    payload.url = url;
    console.log("REQUEST PAYLOAD", payload);

    const response = await fetch(url, payload);
    console.log("REQUEST RESPONSE", url, response.ok, response.status);

    if (response.ok === false) {
      throw await response.json();
    }

    const result: any = await response.json();

    if (response.ok && result.statusCode === "BadRequest") {
      throw result;
    }

    return result;
  };
}

export default Request;
