import React from "react";
import { Pressable, useWindowDimensions } from "react-native";
import { MeasureValue } from "./rows-item";
import RowsOption from "./rows-option";
import useStyles from "./styles.css";
import SVGIcon from "@assets/svg";
import { useNavigation } from "@react-navigation/native";

const MenuButton = () => {
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const navigation = useNavigation<any>();
  const actionRef = React.useRef<any>(null);
  const [isFocus, setFocus] = React.useState<boolean>(false);
  const [measure, setMesasure] = React.useState<MeasureValue>({} as MeasureValue);

  const onSelected = (value: string) => {
    switch(value){
    case "Home":
      navigation.navigate("Home");
      break;
    case "My Account":
      navigation.navigate("MyAccount");
      break;
    case "Redeem":
      navigation.navigate("Redeem");
      break;
    case "Contact Us":
      navigation.navigate("ContactUs");
      break;
    }
  };

  React.useEffect(() => {
    if (actionRef.current) {
      actionRef.current?.measure(
        (
          fx: any,
          fy: any,
          width: number,
          height: any,
          px: number,
          py: number
        ) => {
          setMesasure({
            x: px,
            y: py,
            width,
            height,
          });
        }
      );
    }
  }, [actionRef.current, isFocus, width]);

  return (
    <>
      <Pressable 
        ref={actionRef}
        onPress={() => setFocus(true)}
        style={styles.btn_rows}>
        <SVGIcon name="menu" />
      </Pressable>
      <RowsOption
        visible={isFocus}
        measures={measure}
        options={["Home", "My Account", "Redeem", "Contact Us"]}
        onSelected={onSelected}
        onClose={() => setFocus(false)}
      />
    </>
  );
};

export default MenuButton;
