import React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { View, Image, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Button02 } from "@src/components/button";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";

const ExitArray = ["Signin", "Signup", "ForgotPassword", "ResetPassword", "FAQs", "TermsCondition", "PrivacyPolicy", "GamingPolicy"];

function UnSignedHeader() {
  const styles = useStyles();
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const routeName: any = route.name; // Get Nested Route Name
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const onSroll = (y: number) => {
    const decimal = Math.random() * 1;
    window.scrollTo(0, y + decimal);
  };

  return (
    <View style={styles.container}>
      <LinearGradient
        colors={[colors.violet6, colors.violet5]}
        style={[styles.linear_style]}
      />
      <View style={styles.v_center}>
        <Pressable onPress={() => {
          navigation.navigate(isLoggedIn ? "Home" : "Welcome");
          onSroll(0);
        }}>
          <Image style={styles.image_logo} source={images["reward-logo"]} resizeMode='contain'/>
        </Pressable>
        {ExitArray.includes(routeName) ? 
          <Button02 label="EXIT" 
            labelStyle={styles.label_exit}
            bgfocus="transparent"
            color={colors.green1}
            onPress={() => {
              navigation.navigate(isLoggedIn ? "Home" : "Welcome");
              onSroll(0);
            }}
            style={{backgroundColor: "transparent"}}/> :
          <View style={styles.v_right_content}>
            <View style={styles.v_buttons}>
              <Button02 onPress={() => onSroll(900)} 
                bgfocus={colors.transparent}
                color={colors.green1}
                style={styles.btn_style} 
                label="How It Works" labelStyle={styles.t_label} />
              <Button02 onPress={() => onSroll(1550)} 
                bgfocus={colors.transparent}
                color={colors.green1}
                style={styles.btn_style} 
                label="Rewards" labelStyle={styles.t_label} />
              <Button02 onPress={() => onSroll(2300)} 
                bgfocus={colors.transparent}
                color={colors.green1}
                style={styles.btn_style}
                label="Feature Games"
                labelStyle={styles.t_label} />
            </View>
            <View style={styles.divider} />
            <Button02 style={styles.btn_sign} onPress={() => navigation.navigate("Signin")}
              labelStyle={styles.label_sign} label="Log In" />
            <View style={styles.divider} />
          </View>}
      </View>
    </View>
  );
}

export default UnSignedHeader;