import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,  
      marginTop: 50,
      paddingBottom: 50,
      paddingHorizontal: 130
    },
    t_editprofile: {fontFamily: "Montserrat-Bold", fontSize: 36, color: colors.colorPrimary},
    t_personalinfo: {fontFamily: "Montserrat-Bold", fontSize: 20, color: colors.colorPrimary, marginTop: 20},
    v_row_details: {flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" },
    v_row_1: {},
    v_address: { alignItems: "flex-end" },
    v_field: {flexDirection: "row", alignItems: "center", marginTop: 20},
    t_field: {fontFamily: "Montserrat-Medium", fontSize: 20, color: colors.black, marginRight: 10},
    ti_containerStyle: { width: 327, height: 34 },
    ti_postcode: { width: 145, height: 34 },
    input_postcode: { width: 145 },
    ti_gender: { width: 141, maxWidth: 141, height: 34 },
    ti_dob: { width: 141, maxWidth: 141 },
    v_row_buttons: {flexDirection: "row", alignItems: "flex-end", marginTop: 40, justifyContent: "flex-end"},
    btn_cancel: {width: 200, borderRadius: 3},
    btn_save: {width: 200, borderRadius: 3, backgroundColor: colors.green},
    divider: { width: 20 },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      container: { paddingHorizontal: 20, marginTop: 30 },
      t_editprofile: { fontSize: 24 },
      t_personalinfo: { fontSize: 16 },
      v_row_details: {width: "100%", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginTop: 10},
      v_row_1: {width: "100%"},
      v_field: {width: "100%" },
      v_address: { alignItems: "flex-start", width: "100%" },
      t_field: { fontSize: 14, marginRight: 10 },
      ti_containerStyle: { flex: 1, height: 31 },
      ti_postcode: { height: 31 },
      v_row_buttons: { alignItems: "center", justifyContent: "center" },
      btn_cancel: { width: 112, height: 31 },
      btn_save: { width: 112, height: 31 },
      divider: { width: 0 },
    },
  }
);

export default useStyles;
