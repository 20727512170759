import React from "react";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { View, Image, Text, Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import useStyles from "./styles.css";
import images from "@assets/images";

function Footer({ onLayout }: any) {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const onHowItWorks = () => {
    navigation.navigate(isLoggedIn ? "Home" : "Welcome");
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      window.scrollTo(0, isLoggedIn ? 2640 : 900);
    }, 500);
  };

  const onFeatureGames = () => {
    navigation.navigate(isLoggedIn ? "Home" : "Welcome");
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      window.scrollTo(0, isLoggedIn ? 1500 : 2300);
    }, 500);
  };

  const onRewards = () => {
    if(isLoggedIn){
      navigation.navigate("Redeem");
    }else{
      navigation.navigate("Welcome");
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        window.scrollTo(0, 1550);
      }, 500);
    }
  };

  return (
    <View style={styles.container} onLayout={onLayout}>
      <View style={styles.v_center}>
        <Pressable onPress={() => navigation.navigate(isLoggedIn ? "Home" : "Welcome")}>
          <Image style={styles.reward_logo} source={images["reward-logo"]} resizeMode='contain'/>
        </Pressable>
        <View style={styles.width100} />
        <View style={styles.v_right_content}>
          <View style={styles.v_row}>
            <Text style={styles.t_note_title}>AZ CONSULTING EOOD</Text>
            <Text style={[styles.t_note_subtitle, { marginTop: 15}]}>7 Kukush Street</Text>
            <Text style={styles.t_note_subtitle}>Sofia 1345</Text>
            <Text style={styles.t_note_subtitle}>Bulgaria</Text>
          </View>
          <View style={styles.width70} />
          <View style={styles.v_row}>
            <Text style={styles.t_note_title}>Gameworkz</Text>
            <Text selectable={false} onPress={onHowItWorks} 
              style={[styles.t_note_subtitle, { marginTop: 15}]}>How It Works</Text>
            <Text selectable={false} onPress={onFeatureGames} 
              style={styles.t_note_subtitle}>Feature Games</Text>
            <Text selectable={false} onPress={onRewards} 
              style={styles.t_note_subtitle}>Rewards</Text>
          </View>
          <View style={styles.width70} />
          <View style={styles.v_row}>
            <Text selectable={false} style={styles.t_note_title}>Policies</Text>
            <Text selectable={false} onPress={() => navigation.navigate("TermsCondition")} 
              style={[styles.t_note_subtitle, { marginTop: 15}]}>Terms & Conditions</Text>
            <Text selectable={false} onPress={() => navigation.navigate("PrivacyPolicy")}
              style={styles.t_note_subtitle}>Privacy Policy</Text>
            <Text selectable={false} onPress={() => navigation.navigate("GamingPolicy")}
              style={styles.t_note_subtitle}>Responsible Social Gaming Policy</Text>
            <Text selectable={false} onPress={() => navigation.navigate("FAQs")}
              style={styles.t_note_subtitle}>FAQ’s</Text>
          </View>
        </View>
      </View>
      <View style={styles.v_copyright}>
        <Pressable onPress={() => navigation.navigate(isLoggedIn ? "Home" : "Welcome")}>
          <Image style={styles.reward_logo_below} source={images["reward-logo"]} resizeMode='contain'/>
        </Pressable>
        <Text style={styles.t_copyright}>Copyright © 2023 Gameworkz Rewardz</Text>
      </View>
    </View>
  );
}

export default React.memo(Footer);