import React from "react";
import { View, Text, Image, Linking, Pressable } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";

function ConnectUs(props: any) {
  const styles = useStyles();
  
  const onFacebook = () => {
    Linking.openURL("https://facebook.com/Fortune8Games");
  };
  const onInstagram = () => {
    Linking.openURL("https://instagram.com/fortune8games");
  };
  const onYoutube = () => {
    Linking.openURL("https://www.youtube.com/@Fortune8Games");
  };
  const onLinkedin = () => {
    Linking.openURL("https://www.linkedin.com/company/gameworkz");
  };

  return (
    <View style={[styles.container, props.containerStyle]}>
      <Text style={[styles.t_title, props.titleStyle]}>{props.title || "Connect With Us"}</Text>
      <View style={styles.v_row}>
        <Pressable onPress={onInstagram}>
          <Image style={styles.imageStyle} source={images.ic_instagram} resizeMode="contain" />
        </Pressable>
        <Pressable onPress={onFacebook}>
          <Image style={styles.imageFb} source={images.ic_facebook} resizeMode="contain" />
        </Pressable>
        <Pressable onPress={onYoutube}>
          <Image style={styles.imageYoutube} source={images.ic_youtube} resizeMode="contain" />
        </Pressable>
        <Pressable onPress={onLinkedin}>
          <Image style={styles.imageStyle} source={images.ic_indeed} resizeMode="contain" />
        </Pressable>
      </View>
    </View>
  );
}

export default ConnectUs;