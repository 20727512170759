import React from "react";
import { selectedAuthSession } from "@src/ducks/slices/auth.slice";
import { ActivityIndicator, FlatList, View } from "react-native";
import { RedeemTransaction } from "@src/utils/transform-helper";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useFetchPost } from "@src/utils/api/hooks";
import Pagination from "@src/components/pagination";
import RecordItem from "./record-item";
import useStyles from "./styles";
import Header from "./header";
import _ from "lodash";

const header = [
  { 
    label1: "Transaction #",
    label2: "GC Amount",
    label3: "Type",
    label4: "Date Lodged",
    label5: "Status",
    label6: "Date Completed",
    type: "Header",
  },
];

const rowsPerPage = 10;

const Records = () => {
  const styles = useStyles();
  const [page, setPage] = React.useState(0);
  const session = useAppSelector(selectedAuthSession);
  const { loading, data, runRequest } = useFetchPost();
  const [transaction, setTransaction] = React.useState<any>([]);

  const renderItem = ({ item, index }: { item: any; index: number }) => {
    return <RecordItem key={`doc_tab${index}`} item={item} />;
  };

  React.useEffect(() => {
    const params = {
      userID: session.userId,
      "sort":"requestDate,desc",
      "page": 1,
      "size": 50
    };
    runRequest("/api/v1/redeem/get", params);
  },[]);

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      const result = RedeemTransaction(data?.items ?? []);
      setTransaction(result);
    }
  }, [data]);

  return (
    <>
      <FlatList
        data={transaction.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        contentContainerStyle={[styles.tabsContainer]}
        keyExtractor={(_, index) => `doc_tab${index}`}
        ListHeaderComponent={<>
          <Header item={header[0]} />
          {loading && <View style={{padding: 15}}>
            <ActivityIndicator animating size={30} color="black" />
          </View>}
        </>}
        renderItem={renderItem}
      />
      <Pagination page={page} count={transaction.length} rowsPerPage={10} onPageChange={setPage} />
    </>
  );
};

export default Records;
