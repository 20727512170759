import React from "react";
import { View, ScrollView, Text, Image} from "react-native";
import UnSignedHeader from "../header/UnSignedHeader";
import "@react-pdf-viewer/core/lib/styles/index.css";
import Footer from "@src/screens/dashboard/footer";
import useStyles from "./styles.css";
import images from "@assets/images";

export default function TermsCondition() {
  const styles = useStyles();

  return (
    <>
      <UnSignedHeader />
      <View style={styles.flex1}>
        <Image style={styles.image_background} source={images.bg_policy} resizeMode="cover" />
        <ScrollView style={styles.flex1}>
          <View style={styles.container}>
            <Image style={styles.image_logo} source={images.logo} resizeMode="contain" />
            <Text style={styles.t_title}>GAMEWORKZ REWARDZ PRIVACY POLICY</Text>
            <View style={styles.v_pdf}>
              <Text style={[styles.t_azconsulting]}>
            AZ CONSULTING EOOD PRIVACY POLICY</Text>
              <Text style={styles.t_note_title}>1. OUR COMMITMENT TO PRIVACY</Text>
              <Text style={[styles.t_note]}>Welcome to the AZ Consulting EOOD (AZ) Fortune8 Privacy Policy ("Privacy Policy"). AZ, as the owner of Fortune8 and GameWorkz, is committed to respecting and protecting your privacy. AZ, as a Bulgarian-registered Company, means that the Terms of the privacy policy are governed by Bulgarian law and that are GDPR is applicable.</Text>
              <Text style={styles.t_note_title}>Scope of Privacy Policy</Text>
              <Text style={[styles.t_note]}>Our Privacy Policy sets out AZ's policies regarding the information we collect and how it is used, shared, and securely stored. It applies to all access to our Website and App, e.g., whether you are playing on a PC, a mobile device, or through a third-party application (e.g., Facebook). Please read this Privacy Policy closely before using any of our products. These Rules are to be read in conjunction with our Terms and Conditions, Rules Regarding Gold Coins and our Responsible Social Gaming Policy. Any terms defined in the Documentation and not defined in the Privacy Policy have the same meaning as set out in the Documentation. If you have any questions, please contact us at info@gameworkz.fun</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY, PLEASE DO NOT USE FORTUNE8 AND PLEASE DO NOT DOWNLOAD OR INSTALL FORTUNE8 OR VISIT THE FORTUNE8 WEBSITE. IF YOU HAVE ALREADY DOWNLOADED AND INSTALLED FORTUNE8 AND DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY, IMMEDIATELY TERMINATE YOUR USE OF FORTUNE8 BY UNINSTALLING IT.</Text>
              <Text style={styles.t_note_title}>2. THE INFORMATION FORTUNE8 MAY COLLECT</Text>
              <Text style={[styles.t_note]}>AZ does not require Users to register or submit PII in order to visit the Fortune8 Website, download the GameWorkz Fortune8 application, or playing the App as a guest. AZ may collect certain user information from you when you become a registered Member of Fortune8 including but not limited to: your name, email, post code, cell phone number, age, gender, birthday, Social Media photograph, Social Media profile information, Social Media friends, and contact lists.</Text>
              <Text style={[styles.t_note]}>AZ may collect and process the following information from Users:</Text>
              <Text style={[styles.t_note]}>A. Personally Identifiable Information ("PII")</Text>
              <Text style={[styles.t_note]}>AZ may collect and process any information you provide Fortune8, enter on the Fortune8 Website or provide to AZ in any other way through or via an AZ operated website that would allow AZ to identify you personally or contact you (collectively "Personally Identifiable Information" or "PII").</Text>
              <Text style={[styles.t_note]}>Examples of PII that AZ may collect from you, includes the following:</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>a. Your name;</Text>
              <Text style={[styles.t_note]}>b. Address;</Text>
              <Text style={[styles.t_note]}>c. phone number(s);</Text>
              <Text style={[styles.t_note]}>d. e-mail address;</Text>
              <Text style={[styles.t_note]}>e. screen name/alias</Text>
              <Text style={[styles.t_note]}>f. Your social network or third party service user identification number;</Text>
              <Text style={[styles.t_note]}>g. Your mobile device's unique identifier (UDID);</Text>
              <Text style={[styles.t_note]}>h. Transaction identifiers for purchases;</Text>
              <Text style={[styles.t_note]}>i. Your profile picture;</Text>
              <Text style={[styles.t_note]}>j. PII you provide about yourself when you send us an e-mail.</Text>
              <Text style={[styles.t_note]}>k. Any other relevant information included in your application, social network or other online profile; and</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>You can choose not to provide AZ with certain PII, but then you may not be able to become a Fortune8 Member, receive entries, claim prizes, or use certain AZ services or products.</Text>
              <Text style={styles.t_note_title}>B. Passive Gathering of Information Electronically</Text>
              <Text style={styles.t_note_title}>a. Cookies and other tracking technologies</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>AZ may obtain information about your general Internet usage by using cookie files, which are small information stored by your browser or on your computer or mobile device's hard drive. These cookies are designed not to contain or collect any PII, but they do contain information that is transferred to your computer or mobile device's hard drive, and they do enable Fortune8 to provide features such as personalization.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Web beacons are a type of tracking device and are a transparent image file. Web beacons enable AZ to track website usage information, such as the number of times a given web page has been viewed. Web beacons are invisible to you and (unlike cookies) are not placed on your computer.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>Web beacons may be used in association with cookies to understand how visitors interact with the pages and content on the pages of the Website. Fortune8 may use web beacons to track usage information and to compile information about how Fortune8 Users use different products and services and when they click through to offers shown on the Website. Web beacons may also be used to track whether you have opened an HTML email. When the email is opened, a part of the code that makes up the HTML page calls a web server to load the web beacon, which then generates a record showing that the email has been viewed. Web Beacons may also recognize when the email was opened, how many times it was forwarded, and which URLs (links within the email) were clicked. For the purposes of this privacy policy, "Cookies" shall mean cookies and all other tracking devices, including web beacons.</Text>
              <Text style={[styles.t_note]}>Most web browsers automatically accept Cookies, but you can usually change your browser to limit or prevent cookies. Cookies do not identify the individual user but the computer used. Users may have the opportunity to set their computers to accept all cookies, to notify them when a cookie is issued, or not to receive cookies at any time. Different browsers may address Cookies differently. If you set the browser not to accept cookies, it may result in certain personalized services not being provided to users of that computer. Unless you have adjusted your browser settings so that it will refuse cookies, Fortune8's system will issue Cookies as soon as you visit the Website.</Text>
              <Text style={[styles.t_note]}>Except for essential cookies, all Cookies will expire after the end of the session.
Cookies are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.</Text>
              <Text style={styles.t_note_title}>b. Click Stream Data</Text>
              <Text style={[styles.t_note]}>AZ may enable Fortune8 and third parties working with AZ to collect, log, and analyse information about your online activity as part of its functionality. This is known as "Click Stream Data" which includes, but is not limited to, information such as your IP address, operating system and browser type, clicks, URLs of web pages that you have viewed, the date that you viewed the web pages, web beacons, and cookie data. AZ and third parties working with AZ may use information collected for system administration, to report aggregate information to advertisers and to target promotions and other content to you to create a personally relevant experience, enhance your experience or research, analyse, or enhance Fortune8. This is statistical data about Fortune8's Users' browsing actions and patterns and does not identify any individual.</Text>
              <Text style={styles.t_note_title}>C. E-mail Communications</Text>
              <Text style={[styles.t_note]}>When you send an e-mail to Fortune8, either through the Fortune8 Website or otherwise, AZ may retain your e-mail address and the contents of the e-mail you send. When you register with Fortune8 and provide your email address, Fortune8 may contact you for promotional reasons, to let you know that you won a prize, or for other reasons related to your account with Fortune8.</Text>
              <Text style={styles.t_note_title}>D. Non-Personally Identifiable Information</Text>
              <Text style={[styles.t_note]}>Fortune8 may also ask you to provide AZ with Non-Personally Identifiable Information ("Non-PII"), data that, when taken alone, does not allow AZ to identify you. Non-PII may include but is not limited to:</Text>
              <Text style={[styles.t_note]}>a. Age or date of birth;</Text>
              <Text style={[styles.t_note]}>b. Gender;</Text>
              <Text style={[styles.t_note]}>c. First name;</Text>
              <Text style={[styles.t_note]}>d. Information about the hardware you may own; and</Text>
              <Text style={[styles.t_note]}>e. Gaming preferences and promotional preferences, and household characteristics like the presence of children or income bracket.</Text>
              <Text style={[styles.t_note]}>Your non-PII may be aggregated and used for Fortune8's internal purposes, for creating reports for third-party advertisers and partners, or otherwise shared with third parties for any lawful purpose without your permission. No third party will know who you are because of the use of your non-PII. AZ may enable Fortune8 and third parties working with AZ to collect, log, and analyse information about your online activity as part of its functionality. This is known as "Click Stream Data" which includes, but is not limited to, information such as your IP address, clicks, URLs of web pages that you have viewed, the date that you viewed the web pages, web beacons, and cookie data. AZ and third parties working with AZ may use information collected through Fortune8 to target promotions and other content to you to create a personally relevant experience, enhance your experience or research, analyse, or enhance Fortune8.</Text>
              <Text style={styles.t_note_title}>3. HOW AZ USES INFORMATION</Text>
              <Text style={[styles.t_note]}>AZ may use your PII and Non-PII for several purposes, including but not limited to the following:</Text>
              <Text style={[styles.t_note]}>A. As part of Members' profile and to manage Member accounts, pop-up application down-load, surveys or promotional activity;</Text>
              <Text style={[styles.t_note]}>B. To facilitate communication between Fortune8 and Users, including, without limitation, responding to Users' questions or comments, sending e-mails, offers, and other promotions, or contacting you for feedback regarding Fortune8, AZ advertisers or business partners;</Text>
              <Text style={[styles.t_note]}>C. To provide a report to Fortune8 advertisers or business partners that tells them who responded to a particular offer;</Text>
              <Text style={[styles.t_note]}>D. To research and analyse Users online activity;</Text>
              <Text style={[styles.t_note]}>E. To research, analyse, adjust, improve, and customize Fortune8 to meet better the needs and expectations of Users, advertisers, or business partners; and</Text>
              <Text style={[styles.t_note]}>F. To enforce AZ's agreements with you, prevent fraud and other prohibited or illegal activities for other legally permissible purposes and generally to ensure that AZ comply with applicable laws.</Text>
              <Text style={styles.t_note_title}>4. DISCLOSURE OF INFORMATION TO THIRD PARTIES</Text>
              <Text style={[styles.t_note]}>AZ may give, share, sell or trade your PII and Non-PII to or with any third parties under the following circumstances:</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>A. Fulfillment of Services</Text>
              <Text style={[styles.t_note]}>AZ may retain other companies and individuals to perform certain functions on AZ's behalf. Examples include companies that help administer surveys and promotional activities, third-party advertisers or promotional partners who provide a product or service, customer support specialists, web hosting and Internet service companies and data analysis firms. Third parties may be provided access to PII needed to perform their functions but may not use such information for any other purpose.</Text>
              <Text style={[styles.t_note]}>B. Disclosure to Third Party Advertisers and Promotional Partners</Text>
              <Text style={[styles.t_note]}>AZ may share your PII with third-party advertisers or promotional partners.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>C. AZ does disclose Users' non-PII information to its advertisers.</Text>
              <Text style={[styles.t_note]}>AZ may provide its advertisers with aggregate information about Users (for example, AZ may inform them that 500 men aged under 30 have clicked on their advertisement on any given day). AZ may also use such aggregate information to help advertisers reach the kind of audience they want to target (for example, women between the ages of 21 of 35 years old). AZ may make use of the personal data Fortune8 has collected from you to enable us to comply with advertisers' wishes by displaying their advertisement to that target audience.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>D. Compliance with Law and Fraud Protection</Text>
              <Text style={[styles.t_note]}>AZ may disclose any information, including PII, that AZ deems necessary, in its sole discretion and without your prior permission, to comply with any applicable law, regulation, legal process or governmental request. AZ may also exchange information, including, without limitation, PII, with other companies and organizations for credit fraud protection, risk reduction, and related activities or to protect the rights, property, and safety of AZ, its agents, employees, affiliates or others. AZ reserves the right to disclose PII for a User who AZ believes is in violation of AZ Terms & Conditions of Service, Privacy Policy or End User License Agreement, even without a summons, warrant, court order or other governmental request.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>E. Sale or Transfer of Information</Text>
              <Text style={[styles.t_note]}>If AZ sells or buys any business or assets, AZ may disclose your PII and Non-PII to the prospective seller or buyer of such business or assets. If AZ or substantially all its assets are acquired by a third party, PII and Non-PII held about Users will be one of the transferred assets.</Text>
              <Text style={styles.t_note_title}>5. OPTION TO LIMIT SHARING AND USE OF PII</Text>
              <Text style={[styles.t_note]}>If you want AZ to stop using your PII for its marketing purposes, please email us at support@fortune8.com with the subject line "Fortune8 Marketing".</Text>
              <Text style={[styles.t_note]}>If you choose to opt out of further direct marketing from third parties or Fortune8, AZ will keep your e-mail address, phone number, name, and physical address on file to honour your request. In addition, you may unsubscribe from specific marketing emails from either Fortune8 or third parties by following the instructions on how to "unsubscribe" in those e-mails. Please keep in mind that any changes you make to your email preferences may take up to ten (10) business days to process, and the sender of the e-mail is obligated to honour a request to unsubscribe. AZ is not responsible for any third parties' failure to honour an opt-out request. Please also note that AZ may continue to contact you to provide service to your account as long as you are a Fortune8 Member.</Text>
              <Text style={styles.t_note_title}>6. IMPORTANT DISCLOSURES, PRACTICES AND CONTACT INFORMATION</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>A. Updated Versions of Fortune8</Text>
              <Text style={[styles.t_note]}>AZ will periodically contact servers at Fortune8 to download and automatically install the latest version of Fortune8 on your computer or mobile device or prompt you to accept the latest version of Fortune8 on your computer or mobile device.</Text>
              <Text style={[styles.t_note]}>B. Your Account Information</Text>
              <Text style={[styles.t_note]}>When you register to become a Fortune8 Member, the information you provide to Fortune8 will be stored in a profile on your "My Account" page. The profile, or a portion thereof, may be viewed by AZ and anyone else to whom you provide the e-mail and password associated with your account or anyone with access to your computer when you log on to the Fortune8 Website. You are responsible for maintaining the confidentiality of your e-mail and password. You are entirely responsible for any and all activities that occur under your account, whether or not authorized by you. You agree to notify AZ of any unauthorized use of your account immediately.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>C. Third-Party Links and Third-Party Advertisements</Text>
              <Text style={[styles.t_note]}>The Fortune8 Website and App may contain links to third-party websites and apps. Please be aware that AZ cannot control and is not responsible for the information collection practices of such websites or apps, which may differ from those of Fortune8. AZ encourages you to review and understand the privacy policies of these websites or apps before providing any information to them. AZ may include banner, icon, or text advertisements for third parties (or websites or apps operated by third parties) that are not sponsors of and not affiliated with Fortune8. Should you click on any such advertisement, you may leave the Fortune8 Website or App and be taken to the advertiser's website or app. AZ is not responsible for the content, practices, or information practices (including but not limited to information practices relative to the gathering and use of PII) of the websites or apps these third parties operate. In addition, these advertisements do not constitute sponsorship, endorsement, or approval by AZ of the content, policies, practices, or products of these websites or apps. All Users should check the relevant website's privacy policy and Terms & Conditions of Use in order to understand their policies and practices regarding the collection and use of your information, including PII.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>D. Data Security</Text>
              <Text style={[styles.t_note]}>AZ has no control over the security of other websites or apps on the Internet that you might visit, even when a link may appear to those websites or apps from Fortune8. If you share your computer or use a computer that is accessed by the general public, remember to sign off and close your browser window when you have finished your session. This will help to ensure that others cannot access your PII.</Text>
              <Text style={[styles.t_note]}>AZ wants you to feel confident using Fortune8. However, no system can be completely secure. Therefore, although AZ takes commercially reasonable steps to secure your information, AZ does not promise, and you should not expect, that your PII, searches, or other communications will always remain secure. In the event of a breach of the confidentiality or security of your PII, AZ will notify you if reasonably possible and as reasonably necessary so that you can take appropriate protective steps. AZ may notify you under such circumstances using the e-mail address(es) Fortune8 has on record for you. Users should also take care with how they handle and disclose their PII.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>E. Children</Text>
              <Text style={[styles.t_note]}>Fortune8 is restricted to Users and not intended for persons under the age of 18+. AZ does not knowingly market to, solicit or collect information from anyone under 18+. If AZ becomes aware that any person submitting a registration or information to Fortune8 is under the age of 18+, AZ will attempt to delete the account and any related information as soon as possible. AZ requires that only person’s 18+ years of age or older use Fortune8. By using Fortune8, you hereby represent that you are at least 18+ years old.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>F. Changes to this Privacy Policy</Text>
              <Text style={[styles.t_note]}>AZ may occasionally update this Privacy Policy to reflect changes in AZ's practices. When Fortune8 posts changes to this Privacy Policy, AZ will revise the updated date at the top of this Privacy Policy. If AZ makes any material changes to its Privacy Policy, AZ will only notify Members by sending an e-mail to the e-mail address(s) provided to Fortune8 in the Member's account, profile, or registration and by prominently posting notice of the changes on the Fortune8 Website. AZ will give you an opportunity to opt out of any materially changed Privacy Policy or, alternatively, cancel your Fortune8 Membership. AZ recommends that you check the Fortune8 Website or the information section of the Gameworkz Fortune8 App from time to time to inform yourself of any changes in this Privacy Policy or any of AZ's other policies.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>G. Access to Information</Text>
              <Text style={[styles.t_note]}>AZ respects the privacy of your PII. If you are a Member and would like to review, update or delete your profile, please log in to your account and select "My Account" where you can review, modify or delete your membership. If you are not a Member but have provided PII to us and wish to delete such information, you may reach us via the GameWorkz Fortune8 App information section.</Text>
              <Text style={[styles.t_note, { marginTop: 10 }]}>H. Contacting Us</Text>
              <Text style={[styles.t_note]}>If you have any questions about AZ, this Privacy Policy or the Fortune8 Website, please feel free to contact Fortune8 via our Contact Us link.</Text>
            </View>
          </View>
          <Footer />
        </ScrollView>
      </View>
    </>
  );
}
