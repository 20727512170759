import React from "react";
import { View, ScrollView, Text, Image } from "react-native";
import UnSignedHeader from "../header/UnSignedHeader";
import ConnectUs from "../dashboard/connect-with-us";
import Footer from "@src/screens/dashboard/footer";
import useStyles from "./styles.css";
import images from "@assets/images";
import FaqList from "./faq";

export default function FAQs() {
  const styles = useStyles();
  
  const onSroll = () => {
    const decimal = Math.random() * 1;
    window.scrollTo(0, 60000 + decimal);
  };

  return (
    <>
      <UnSignedHeader />
      <View style={styles.flex1}>
        <Image style={styles.image_background} source={images.bg_policy} resizeMode="cover" />
        <ScrollView style={styles.flex1}>
          <View style={styles.container}>
            <Image style={styles.image_logo} source={images.logo} resizeMode="contain" />
            <View style={styles.v_pdf}>
              <Text style={[styles.t_title]}>FREQUENTLY ASKED QUESTIONS</Text>
              <Text style={[styles.t_howitworks]}>How It Works:</Text>
              <View style={{flexDirection: "row", marginTop: 20}}>
                <Text style={[styles.t_note, { marginRight: 7}]}>1.</Text>
                <Text style={[styles.t_note]}>
                  <Text style={styles.t_note_bold}>Play Your Favourite Games:</Text>
                  {" "}Enjoy our wide selection of games. Every spin, hand, or bet you make brings you closer to fantastic rewards.
                </Text>
              </View>
              <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={[styles.t_note, { marginRight: 7}]}>2.</Text>
                <Text style={[styles.t_note]}>
                  <Text style={styles.t_note_bold}>Earn GWz Gold Coins:</Text>
                  {" "}As you play, you'll have a chance to accumulate GWz Gold Coins. The more you play, the more chances you can earn. Watch your coins stack up and unlock amazing rewards.
                </Text>
              </View>
              <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={[styles.t_note, { marginRight: 7}]}>3.</Text>
                <Text style={[styles.t_note]}>
                  <Text style={styles.t_note_bold}>Redeem Rewards:</Text>
                  {" "}Head to the Rewards page to exchange your qualified GWz Gold Coins for gift cards or cash back. It's simple, easy, and incredibly rewarding!
                </Text>
              </View>
              <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={[styles.t_note, { marginRight: 7}]}>4.</Text>
                <Text style={[styles.t_note]}>
                  <Text style={styles.t_note_bold}>Level Up Your Experience:</Text>
                  {" "}Keep playing to climb through our loyalty tiers. The higher your tier, the better the rewards, and the more exclusive perks you unlock.
                </Text>
              </View>
              <View style={{flexDirection: "row", marginTop: 10}}>
                <Text style={[styles.t_note, { marginRight: 7}]}>5.</Text>
                <Text style={[styles.t_note]}>
                  <Text style={styles.t_note_bold}>Stay In the Loop:</Text>
                  {" "}Don't miss out on any of our fantastic promotions, tournaments, or special offers. Keep an eye on our website and your inbox for the latest updates.
                </Text>
              </View>
              <FaqList />
            
            </View>
          </View>
          <ConnectUs containerStyle={styles.containerStyle} />
          <Footer onConnectPress={onSroll} />
        </ScrollView>
      </View>
    </>
  );
}
