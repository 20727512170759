import React from "react";
import { selectedScrollPosition } from "@src/ducks/slices/dashboard.slice";
import { useNavigation, useRoute } from "@react-navigation/native";
import { View, ScrollView, StyleSheet } from "react-native";
import { useDashboardService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";
import ConnectUs from "../dashboard/connect-with-us";
import HowItWorks from "../dashboard/how-it-works";
import WelcomeContent from "./welcome-content";
import Features from "../dashboard/features";
import PlayFortune8 from "./play-fortune8";
import Footer from "../dashboard/footer";
import WhatsNew from "./whats-new";

function DashboardHome() {
  const route = useRoute<any>();
  const pos = route?.params?.position;
  const navigation = useNavigation<any>();
  const scrollRef = React.useRef<any>(null);
  const { onDashboardRequest } = useDashboardService();
  const position = useAppSelector(selectedScrollPosition);
  const onScroll = (y: number) =>  window.scrollTo(0, y);

  React.useEffect(() => {
    onScroll(position);
    if(pos){
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        onScroll(pos);
      }, 500);
    }
  },[position]);

  React.useEffect(() => {
    onDashboardRequest();
  },[]);

  return (
    <ScrollView ref={scrollRef}>
      <View style={styles.container}>
        <WelcomeContent />
        <WhatsNew />
        <Features />
        <HowItWorks label="REDEEM NOW" 
          onPress={() => navigation.navigate("Redeem")}  />
        <PlayFortune8 />
        <ConnectUs />
        <Footer />
      </View>
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  container: {flex: 1, alignItems: "center", backgroundColor: "white" },
});

export default DashboardHome;
