import React from "react";
import { Text, View } from "react-native";
import colors from "@assets/colors";
import useStyles from "./styles";
import moment from "moment";

function RecordItem({ item }: any) {
  const styles = useStyles();

  return (
    <View style={styles.v_header}>
      <View style={[styles.tab, { backgroundColor: colors.transparent}]}>
        <Text style={styles.t_value}>{item.id}</Text>
      </View>
      <View style={[styles.divider]} />
      <View style={[styles.tab, { backgroundColor: colors.transparent}]}>
        <Text style={styles.t_value}>{item.goldAmount}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab, { backgroundColor: colors.transparent}]}>
        <Text style={styles.t_value}>{item.redeemTypeID}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab, { backgroundColor: colors.transparent}]}>
        <Text style={styles.t_value}>{moment(item.requestDate).format("MM/DD/YYYY hh:mm A")}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab, { backgroundColor: colors.transparent}]}>
        <Text style={styles.t_value}>{item.redeemStatusID}</Text>
      </View>
      <View style={styles.divider} />
      <View style={[styles.tab, { backgroundColor: colors.transparent}]}>
        <Text style={styles.t_value}>{item.completedDate ? moment(item.completedDate).format("MM/DD/YYYY hh:mm A") : null}</Text>
      </View>
    </View>
    
  );
}

export default RecordItem;