import React, { useRef, useCallback } from "react";
import {
  View,
  Text,
  Modal,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from "react-native";
import colors from "@assets/colors";

const ITEM_HEIGHT = 33;
export interface MeasureValue {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface MenuDropdownProps {
  visible: boolean;
  accessible?: boolean;
  measures: MeasureValue;
  value: any;
  options: any;
  container?: any;
  onClose: () => void;
  renderItem?: any;
  ItemSeparatorComponent?: any;
  onSelected: (item: any) => void;
}

const MenuDropdown = (props: MenuDropdownProps) => {
  const flatlist = useRef(null);
  const { height: HEIGHT } = useWindowDimensions();
  const stylePosition = React.useMemo(() => {
    const { x, y, width, height } = props.measures;
    const base_height = props.options.length * (ITEM_HEIGHT);
    const pos_upward = y + base_height;
    const isGreaterThanHeight = pos_upward > HEIGHT;
    const positionStyle = {
      height: base_height,
      width: width,
      left: x,
      top: isGreaterThanHeight ? (y - base_height)  : y + height,
    };

    return positionStyle;
  }, [props.measures, HEIGHT]);

  const RenderItem = useCallback(
    ({ item, index }: any) => {
      const newRow = ["string", "number"].includes(typeof item) ? item : item?.name ?? "";

      return (
        <TouchableOpacity
          key={`${index}`}
          onPress={() => {
            props.onSelected(item);
            props.onClose();
          }}
          style={[
            styles.v_item,
            newRow === props.value && styles.v_item_active,
          ]}
        >
          {typeof props.renderItem === "function" ? (
            props.renderItem({ item: newRow })
          ) : (
            <>
              <Text style={styles.t_item}>{newRow}</Text>
            </>
          )}
        </TouchableOpacity>
      );
    },
    [props.value, props.onSelected]
  );

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={props.onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <TouchableWithoutFeedback
        accessible={props.accessible}
        onPress={props.onClose}
      >
        <View style={styles.modal}>
          <View style={[styles.dropdown, props.container, stylePosition]}>
            <FlatList
              ref={flatlist}
              data={props.options}
              getItemLayout={(item, index) => ({
                length: ITEM_HEIGHT,
                offset: ITEM_HEIGHT * index,
                index,
              })}
              ItemSeparatorComponent={() => typeof props.ItemSeparatorComponent === "function" ? props.ItemSeparatorComponent() :  <View style={styles.itemSeparator} />}
              keyExtractor={(_, index) => `${index}`}
              renderItem={RenderItem}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: { flexGrow: 1 },
  dropdown: {
    width: 400,
    position: "absolute",
    justifyContent: "center",
  },
  itemSeparator: {
    height: 5,
    backgroundColor: colors.transparent,
  },
  v_item: {
    height: ITEM_HEIGHT,
    alignItems: "flex-start",
    justifyContent: "center",
    paddingHorizontal: 9,
    borderWidth: 1,
    borderColor: colors.gray1,
    backgroundColor: colors.white
  },

  v_item_active: { backgroundColor: colors.gray1 },
  t_item: {
    fontSize: 12,
    lineHeight: 15,
    color: colors.black,
    fontWeight: "600",
    fontFamily: "Montserrat",
  },
});

export default MenuDropdown;
