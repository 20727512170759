import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    v_myaccount: {flexDirection: "row", alignItems: "flex-start", 
      justifyContent: "space-between", 
      paddingHorizontal: "12%", marginTop: 60
    },
    v_field: {flexDirection: "row", alignItems: "center", marginTop: 25},
    t_field: {fontFamily: "Montserrat-Medium", fontSize: 14, color: colors.black, marginRight: 10},
    t_field_value: {fontFamily: "Montserrat", fontSize: 12, color: colors.black },
    t_title: {fontFamily: "Montserrat-Bold", fontSize: 24, color: colors.colorPrimary},
    t_subtitle: {fontFamily: "Montserrat-Medium", fontSize: 16, color: colors.black },
    t_note: {fontFamily: "Montserrat-Medium", fontSize: 12, color: colors.colorPrimary },
    v_row_gwz: {flexDirection: "row", alignItems: "center"},
    v_row_bonus: {flexDirection: "row", alignItems: "center", marginTop: 25},
    t_gwzgold: {
      fontFamily: "Montserrat", fontWeight: "600", fontSize: 16,
      lineHeight: 17, color: colors.black
    },
    t_balance: {
      fontFamily: "Montserrat", fontSize: 14,
      lineHeight: 15, color: colors.black
    },
    v_balance: {width: 229, height: 34, backgroundColor: "white", marginLeft: 10, paddingHorizontal: 5,
      borderWidth: 1, borderColor: colors.black, flexDirection: "row", alignItems: "center", justifyContent: "flex-start"},
    image_gold: { width: 23, height: 20 },
    v_height: { height: 30 },
    v_height50: { height: 70 },
    btn_redeem: {
      width: 225, height: 45, marginTop: 25,
      borderRadius: 3, backgroundColor: colors.colorPrimary,
    },
    label_redeem: {fontFamily: "Montserrat", fontWeight: "600", 
      fontSize: 16, lineHeight: 20, color: colors.white },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      v_myaccount: {flexDirection: "column", alignItems: "flex-start", 
        justifyContent: "flex-start", marginTop: 30, paddingHorizontal: 20
      },
      v_field: { marginTop: 12 },
      t_field: { fontWeight: "600", fontSize: 12 },
      t_field_value: {fontFamily: "Montserrat-Medium" },

      v_row_gwz: {flexDirection: "column", alignItems: "flex-start", marginTop: 25},
      v_row_bonus: {flexDirection: "column", alignItems: "flex-start", marginTop: 25},
      t_gwzgold: {fontFamily: "Montserrat-Medium", fontSize: 12, lineHeight: 14 },
      v_balance: { marginLeft: 0, marginTop: 6 }
    },
  }
);

export default useStyles;
