import React from "react";
import ConnectUs from "../dashboard/connect-with-us";
import Footer from "@src/screens/dashboard/footer";
import { StyleSheet, View} from "react-native";
import ContactProvider from "./provider";
import Success from "./success";
import Fields from "./fields";

export default function ContactUs() {
  const onSroll = (y: number) => {
    const decimal = Math.random() * 1;
    window.scrollTo(0, y + decimal);
  };

  return (
    <ContactProvider>
      <View style={styles.container}>
        <Fields />
        <Success />
        <ConnectUs 
          titleStyle={{fontFamily: "Montserrat-Medium"}} 
          title="FOLLOW US TO RECEIVE BONUSES AND REWARDS" />
        <Footer onConnectPress={() => onSroll(3000)} />
      </View>
    </ContactProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
