import * as api from "./request";
import * as Types from "@src/ducks/types";
import { AUTH_USERNAME, AUTH_PASSWORD } from "@env";

export const getAuth = () =>
  api.callPost("/authenticate", {
    username: AUTH_USERNAME,
    password: AUTH_PASSWORD,
  });

export const login = (params: Types.LoginInput, token: string) =>
  api.callPost("/api/v1/user/login", { "partnerId": 1, ...params}, token);
  
export const register = (params: Types.SignupInput) =>
  api.callPost("/api/v1/redeem/user/create", params);

export const registerVerification = (params: Types.SignupInput) =>
  api.callPost("/api/v1/redeem/user/verify", params);

export const walletBalance = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/user/${params.userId}/wallet`, params.token);

export const getTierID = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/evo/avatartier/${params.userId}`, params.token);

export const redeemSave = (params: Types.SessionValue) =>
  api.callPost("/api/v1/redeem/save", params, params.token);

export const redeemUploadFiles = (params: any, token: any) =>
  api.callFormData("/api/v1/redeem/upload/files", params, token);

export const redeemUploadSave = (params: any, token: any) =>
  api.callPost("/api/v1/redeem/userDocument/save", params, token);

export const deleteUploadID = (id: number, token: any) =>
  api.callPost("/api/v1/redeem/userDocument/delete?id=" + id, {}, token);

