import React from "react";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import UnSignedHeader from "./UnSignedHeader";
import SignedHeader from "./SignedHeader";

function Header() {
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  return isLoggedIn ? <SignedHeader /> : <UnSignedHeader />;
}

export default Header;