import React from "react";
import ConnectUs from "../dashboard/connect-with-us";
import RedeemRewards from "./redeem-your-rewards";
import { View, StyleSheet } from "react-native";
import WelcomeContent from "./welcome-content";
import Footer from "@screens/dashboard/footer";
import Promotional from "./promotional";
import GiftCards from "./gift-cards";
import colors from "@assets/colors";
import { useDashboardService } from "@src/ducks/hooks";

function Redeem() {
  const { onDashboardRequest } = useDashboardService();
  const onSroll = (y: number) => {
    const decimal = Math.random() * 1;
    window.scrollTo(0, y + decimal);
  };

  React.useEffect(() => {
    onDashboardRequest();
  },[]);
  
  return (
    <View style={styles.container}>
      <WelcomeContent />
      <RedeemRewards />
      <GiftCards />
      <Promotional />
      <ConnectUs title=" " />
      <Footer onConnectPress={() => onSroll(60000)} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {flex: 1, alignItems: "center", backgroundColor: colors.white },
});

export default Redeem;
