import { SagaIterator } from "@redux-saga/core";
import { call, put, takeEvery } from "redux-saga/effects";

// Types
import { SessionValue } from "../types";

// API
import { getTierID, redeemSave, walletBalance } from "@src/utils/api";

// Slice
import { dashboardActions } from "../slices/dashboard.slice";
import { authActions } from "../slices/auth.slice";

function* handleInitialRequest(action: {
  type: typeof dashboardActions.dashboardRequest;
  payload: SessionValue;
}): SagaIterator {
  try {
    const balance = yield call(walletBalance, action.payload);
    yield put(authActions.updateWallet(balance.data));
    const tier = yield call(getTierID, action.payload);
    yield put(authActions.updateTier(tier));

    yield put(dashboardActions.dashboardSuccess({message: "success"}));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(dashboardActions.dashboardFailure({ message }));

    if (message.includes("Unauthorized")) {
      yield put(authActions.logout());
    }
  }
}

function* handleRedeemRequest(action: {
  type: typeof dashboardActions.redeemRequest;
  payload: SessionValue;
}): SagaIterator {
  try {
    const tier = yield call(getTierID, action.payload);
    if(tier.tierID < 2){
      throw { message: "Your account must be atleast emerald tier."};
    }

    const result = yield call(redeemSave, action.payload);

    yield put(dashboardActions.redeemSuccess(result));
  } catch (error: any) {
    console.log("error", error);
    const message = error.message || error.error || "Something went wrong";
    yield put(dashboardActions.redeemFailure(error));

    if (message.includes("Unauthorized")) {
      yield put(authActions.logout());
    }
  }
}

function* handleWalletBalance(action: {
  type: typeof dashboardActions.redeemSuccess;
  payload: SessionValue;
}): SagaIterator {
  try {
    if(action.payload.token){
      const balance = yield call(walletBalance, action.payload);
      yield put(authActions.updateWallet(balance.data));
    }
  } catch (error: any) {
    console.log("error", error);
    const message = error.message || error.error || "Something went wrong";

    if (message.includes("Unauthorized")) {
      yield put(authActions.logout());
    }
  }
}

// Watcher Saga
function* userWatcherSaga(): SagaIterator {
  yield takeEvery(dashboardActions.dashboardRequest.type, handleInitialRequest);
  yield takeEvery(dashboardActions.redeemRequest.type, handleRedeemRequest);
  yield takeEvery(dashboardActions.redeemSuccess.type, handleWalletBalance);
}

export default userWatcherSaga;
