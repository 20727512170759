import React from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Image,
} from 'react-native';
import { Button01Types } from './types';

const ButtonIcon = (props: Button01Types) => {
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={props.style}
      onPress={props.onPress}
      disabled={props.disabled}
    >
      {props.isLoading ? (
        <ActivityIndicator
          animating
          size="small"
          color={props.color || 'black'}
        />
      ) : (
        <Image
          resizeMode="contain"
          style={[styles.imgStyle, props.imgStyle]}
          source={props.source}
        />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  loadStyle: { alignItems: 'center', justifyContent: 'center' },
  imgStyle: { height: 24, width: 24 },
});

export default ButtonIcon;
