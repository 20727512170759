import React from "react";
import { Text, View, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import { Button01 } from "@src/components/button";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import { useFetchPost } from "@src/utils/api/hooks";
import { AuthContext } from "@src/AuthProvider";
import _ from "lodash";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedRedeemData } from "@src/ducks/slices/dashboard.slice";
import { useDashboardService } from "@src/ducks/hooks";

const CELL_COUNT = 6;

function RequestVerification() {
  const styles = useStyles();
  const timerId = React.useRef<any>(null);
  const [value, setValue] = React.useState("");
  const [timer, setTimer] = React.useState(180);
  const { onRedeemSuccess } = useDashboardService();
  const redeem = useAppSelector(selectedRedeemData);
  const {loading, data, runRequest} = useFetchPost();
  const {loading: loadingVerify, data: dataVerify, 
    error: errorVerify, runRequest: runRequestVerify} = useFetchPost();
  const { onErrorMessage } = React.useContext(AuthContext);
  const ref = useBlurOnFulfill({value, cellCount: CELL_COUNT});
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  const onResendCode = () => {
    if(timer > 0){
      return;
    }
    const params = {
      "requestId": redeem.id,
      "userId": redeem.userID,
    };
    runRequest("/api/v1/redeem/resendVerifyCode", params);
  };

  const onVerifyCode = () => {
    const params = {
      "requestId": redeem?.id,
      "userId": redeem?.userID,
      "verificationCode": value
    };
    runRequestVerify("/api/v1/redeem/request/emailVerification", params);
  };

  React.useEffect(() => {
    if (!timer) {
      timerId.current && clearInterval(timerId.current);
      setTimer(0);
      return;
    }

    timerId.current = setInterval(() => {
      setTimer((lastTimerCount) => lastTimerCount - 1);
    }, 1000);

    return () => clearInterval(timerId.current);
  }, [timer]);

  React.useEffect(() => {
    if (!_.isEmpty(errorVerify)) {
      onErrorMessage(errorVerify.message);
    }
    if (!_.isEmpty(data)) {
      setTimer(180);
    }
    if (!_.isEmpty(dataVerify)) {
      onRedeemSuccess(dataVerify);
    }
  }, [errorVerify, data, dataVerify]);

  return (
    <View style={[styles.container, { backgroundColor: "white"}]}>
      <View style={styles.v_details}>
        <Image style={styles.i_email} source={images["logo"]} resizeMode="contain"/>
        <Text style={[styles.t_verify]} >VERIFY YOUR ACCOUNT TO CONTINUE</Text>
        <Text style={[styles.t_notes2, {fontFamily: "Montserrat", marginTop: 10 }]}>
          We’ve sent you a code to  your email address.
          {"\n"}Please enter the code below</Text>
        <View style={{marginVertical: 40}}>
          <CodeField
            ref={ref}
            {...props}
            value={value}
            cellCount={CELL_COUNT}
            onChangeText={setValue}
            keyboardType="number-pad"
            textContentType="oneTimeCode"
            renderCell={({index, symbol, isFocused}) => (
              <Text
                key={index}
                style={[styles.cell, isFocused && styles.focusCell]}
                onLayout={getCellOnLayoutHandler(index)}>
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            )}
          />
        </View>
        <Text suppressHighlighting onPress={onResendCode} 
          style={[styles.t_didnotreceive]} >
          {timer > 0 ? "Resend in "+timer : "Didn’t receive a code?"}
        </Text>
        <Button01 
          label={timer > 0 ? "SUBMIT" : "Send Code Again"}
          onPress={timer > 0 ? onVerifyCode : onResendCode}
          style={styles.btn_sendcode}
          isLoading={loading || loadingVerify}
          labelStyle={styles.label_sendcode} />
      </View>
    </View>
  );
}

export default RequestVerification;
