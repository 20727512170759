import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 38,
      borderWidth: 1,
      flexDirection: "row",
      backgroundColor: colors.white,
    },
    errorStyle: {
      borderWidth: 1,
      borderColor: colors.red,
    },
    textError: {
      right: 10,
      fontSize: 10,
      color: colors.red,
      position: "absolute",
      alignSelf: "center",
      fontFamily: "Montserrat",
    },
    input: {
      flex: 1,
      fontSize: 16,
      paddingLeft: 7,
      color: colors.black,
      fontFamily: "Montserrat",
    },
    label: {
      fontSize: 14,
      lineHeight: 21,
      fontFamily: "Inter",
      color: colors.black,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      container: { height: 34 }
    },
    [DEVICE_SIZES.MD]: {
      input: {
        fontSize: 13,
      },
    },
    [DEVICE_SIZES.SM]: {
      input: {
        fontSize: 12,
      },
    },
    [DEVICE_SIZES.XS]: {
      input: {
        fontSize: 12,
      },
    },
  }
);

export default useStyles;
