import React from "react";
import { StyleSheet, View, ScrollView} from "react-native";
import Footer from "@src/screens/dashboard/footer";
import Login from "./login";

export default function SignIn() {
  return (
    <ScrollView>
      <View style={styles.container}>
        <Login />
        <Footer />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
