import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      alignItems: "center", 
      justifyContent: "center",
    },
    v_details: { flexDirection: "row", alignItems: "center", justifyContent: "center"},
    i_howitworks: {width: 380, height: 254, resizeMode: "stretch"},
    divider: { width: 50 },
    v_info: {width: "55%", alignItems: "center"},
    t_title: {
      fontFamily: "Montserrat", fontWeight: "700", fontSize: 30, 
      lineHeight: 40, color: colors.colorPrimary, textAlign: "center"
    },
    t_subtitle: {
      fontFamily: "Montserrat", 
      fontSize: 18,
      marginTop: 20,
      lineHeight: 27,
      textAlign: "center",
      color: colors.black,
    },
    btn_signup: {
      width: 262, 
      height: 44,
      marginTop: 30,
      backgroundColor: colors.red1, 
    },
    label_signup: { color: colors.white, fontSize: 24, lineHeight: 36 },
  },
  {
    [DEVICE_SIZES.LG]: {
      t_title: { fontSize: 24, lineHeight: 36 },
      t_subtitle: { fontSize: 16, lineHeight: 24 },
    },
    [DEVICE_SIZES.MD]: {
      i_howitworks: {width: 400, height: 254},
      t_title: { fontSize: 24, lineHeight: 36 },
      t_subtitle: { fontSize: 12, lineHeight: 18 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { paddingHorizontal: 20, paddingTop: 0},
      v_details: { flexDirection: "column" },
      i_howitworks: { width: "100%", height: 234 },
      v_info: { width: "80%", marginTop: 30 },
      divider: { width: 12 },
      t_title: { fontSize: 24, lineHeight: 28 },
      t_subtitle: { fontSize: 10, lineHeight: 15 },
      btn_signup: {
        width: 126, 
        height: 34,
        marginTop: 20,
      },
      label_signup: { fontSize: 10, lineHeight: 14 },
    },
  }
);

export default useStyles;
