import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import { dashboardActions } from "../slices/dashboard.slice";
import { selectedAuthSession } from "../slices/auth.slice";
import moment from "moment";


type ServiceOperators = {
  onDashboardRequest: () => void;
  onRedeemRequest: (params: any) => void;
  onRedeemSuccess: (params: any) => void;
  onResetDashboard: () => void;
  onUpdatePosition: (value: any) => void;
};

export const useDashboardService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const session = useAppSelector(selectedAuthSession);

  return {
    onDashboardRequest: useCallback(
      () => dispatch(dashboardActions.dashboardRequest(session)),
      [dispatch, session]
    ),
    onRedeemRequest: useCallback(
      (value) => dispatch(dashboardActions.redeemRequest({
        ...value, 
        token: session.token,
        userID: session.userId,
        userId: session.userId,
        "requestDate": moment().format(),
      })),
      [dispatch, session]
    ),
    onRedeemSuccess: useCallback(
      (params: any) => dispatch(dashboardActions.redeemSuccess({...params, ...session})),
      [dispatch, session]
    ),
    onResetDashboard: useCallback(
      () => dispatch(dashboardActions.resetRequest()),
      [dispatch]
    ),
    onUpdatePosition: useCallback(
      (value) => dispatch(dashboardActions.updateScrollPosition(value)),
      [dispatch]
    ),
  };
};

export default useDashboardService;
