import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      marginTop: 50,
      alignItems: "center", 
    },
    t_title: {
      fontFamily: "Montserrat-Bold", fontSize: 48, 
      lineHeight: 57, color: colors.colorPrimary, textAlign: "center"
    },
    t_subtitle: {
      fontFamily: "Montserrat-Medium", fontSize: 18, 
      lineHeight: 27, color: colors.black1, 
      marginTop: 15, textAlign: "center"
    },
    v_right_content: {flexDirection: "row", alignItems: "center"},
    btn_started: { backgroundColor: colors.colorPrimary, width: 130, height: 41, marginVertical: 50 },
    view_started: { width: 960, height: 639, marginTop: 30, justifyContent: "center" },
    image_started: { width: "100%", height: "100%" },

    v_logo_claim: {width: 320, position: "absolute", right: 95, alignItems: "center"},
    v_logo: {width: "100%", height: 180, alignItems: "center"},
    i_logo: {  width: 154, height: 132 },
    v_claim: {width: "100%", height: 100, alignItems: "center"},
    i_claim: {  width: "100%", height: 100 },
    t_joinnow: {fontFamily: "Inter-Bold", fontSize: 12, color: "white", textAlign: "center", marginTop: 30 },
    btn_signup: {width: 283, height: 41, borderRadius: 4, backgroundColor: colors.green1, marginTop: 15},
    label_signup: { fontSize: 12, lineHeight: 14 },
  },
  {
    [DEVICE_SIZES.MD]: {
      container: { width: "100%",},
      view_started: { width: "100%" },
      t_title: { fontSize: 44, lineHeight: 50},

      t_joinnow: { fontSize: 10, marginTop: 15 },
      btn_signup: {width: 112, height: 18, borderRadius: 4, marginTop: 10},
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { width: "100%", marginTop: 30 },
      t_title: { fontSize: 24, lineHeight: 24 },
      t_subtitle: { fontSize: 10, lineHeight: 16 },
      view_started: { width: "100%", height: 250 },

      v_logo_claim: {width: 127, right: 50,},

      v_logo: {display: "none"},
      v_claim: {width: "100%", height: 40 },
      i_claim: {  width: "100%", height: 40 },
      t_joinnow: { fontSize: 10, marginTop: 15 },
      btn_signup: {width: 112, height: 18, borderRadius: 4, marginTop: 10},
      label_signup: { fontSize: 10, lineHeight: 14 },
    },
  }
);

export default useStyles;
