import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {paddingVertical: 50, alignItems: "center"},
    t_title: {fontFamily: "Inter-Bold", fontSize: 18, lineHeight: 20, color: colors.colorPrimary, textAlign: "center"},
    v_row: {flexDirection: "row", width: 345, alignItems: "center", justifyContent: "space-around", marginTop: 30},
    imageStyle: {width: 40, height: 40, marginHorizontal: 15},
    imageFb: {width: 23, height: 40, marginHorizontal: 15},
    imageYoutube: {width: 49, height: 35, marginHorizontal: 15},
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      imageStyle: {width: 37, height: 37, marginHorizontal: 15},
      imageFb: {width: 17, height: 37, marginHorizontal: 15},
      imageYoutube: {width: 36, height: 25, marginHorizontal: 15},
    },
  }
);

export default useStyles;
