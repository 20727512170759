import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: 69, 
      backgroundColor: colors.white, 
    },
    linear_style: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_center: { 
      flex: 1,
      flexDirection: "row",
      alignItems: "center", 
      paddingHorizontal: 120,
      justifyContent: "space-between", 
    },
    image_logo: {width: 260, height: 44 },
    v_right_content: {flexDirection: "row", alignItems: "center"},
    v_buttons: { flexDirection: "row" },
    btn_style: { backgroundColor: colors.transparent },
    t_label: {fontFamily: "Montserrat-Medium", fontSize: 14, color: colors.white },
    btn_sign: { height: 35 },
    label_sign: { fontSize: 12 },
    divider: { width: 20 },
    label_exit: { color: colors.white, fontSize: 20},

  },
  {
    [DEVICE_SIZES.XL]: {
      v_center: { paddingHorizontal: 140 }
    },
    [DEVICE_SIZES.MD]: {
      v_center: { paddingHorizontal: 20 },
      image_logo: { width: 160, height: 30 },
      v_buttons: { display: "none" },
      label_sign: { fontSize: 12},
      btn_sign: { height: 30 },
      divider: { width: 12 },
      label_exit: { fontSize: 17 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_center: { paddingHorizontal: 20 },
      image_logo: { width: 145, height: 29 },
      v_buttons: { display: "none" },
      label_sign: { fontSize: 10},
      btn_sign: { height: 30 },
      divider: { width: 12 },
      label_exit: { fontSize: 17 },
    },
  }
);

export default useStyles;
