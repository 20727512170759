import { useFetchPost } from "@src/utils/api/hooks";
import React, { useCallback } from "react";

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  loading: boolean;
  data: any;
  error: any;
  onReset: (value: any) => void;
}

export const ResetContext = React.createContext<ContextValue>(
  {
    error: { message: ""}
  } as ContextValue
);

const ResetProvider = ({ children }: ProviderProps) => {
  const { loading, data, error, runRequest } = useFetchPost();
  
  const onReset = useCallback((params: any) => {
    runRequest("/api/v1/redeem/user/changePassword", params);
  }, [error]);

  return (
    <ResetContext.Provider value={{ loading, data, error, onReset }}>
      {children}
    </ResetContext.Provider>
  );
};

export default ResetProvider;
