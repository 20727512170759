import React from "react";
import { Text, View, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import LogoAnim from "./logo-anim";

function WelcomeContent() {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Image style={styles.image_bg} source={images.bg_dashboard} resizeMode="stretch" />
      <View style={styles.view_started}>
        <Image style={styles.image_started} source={images.welcome} resizeMode="cover" />
        <LogoAnim />
      </View>
      <View style={styles.v_content}>
        <Text style={styles.t_title} >Welcome to Gameworkz Rewardz</Text>
        <Text style={styles.t_subtitle} >
      At Gameworkz Rewardz, our goal is to make your gaming experience unforgettable. With our Rewards Hub, 
        you'll enjoy not only the thrill of the games but also the joy of being rewarded for your loyalty.
        Join us today and start reaping the benefits.
        </Text>
      </View>
    </View>
  );
}

export default WelcomeContent;
