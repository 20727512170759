import { SagaIterator } from "@redux-saga/core";
import { call, put, takeEvery, delay } from "redux-saga/effects";

// Types
import * as Types from "../types";

// API
import { getAuth, login, register } from "@src/utils/api";

// Slice
import { authActions } from "../slices/auth.slice";

function* handleSignin(action: {
  type: typeof authActions.loginRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const token = yield call(getAuth);
    const session = yield call(login, action.payload, token.token);

    if (session.data.isDeleted) {
      throw { code: 401, message:  "Your account is disabled. Please contact customer support"};
    }
    yield put(authActions.loginSuccess({...session.data, token: token.token}));
  } catch (error: any) {
    let message = error?.error?.message ?? "Something went wrong";
    message = message.replace("Invalid User/Email or Password", "Invalid Email or Password");

    yield put(authActions.loginFailure({ message }));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleSignup(action: {
  type: typeof authActions.signupRequest;
  payload: Types.SignupInput;
}): SagaIterator {
  try {
    const result = yield call(register, action.payload);
    const token = yield call(getAuth);

    yield put(authActions.signupSuccess({...result.data, token}));
  } catch (error: any) {
    const message = error?.error?.message ?? "Somthing went wrong";
    yield put(authActions.signupFailure({message}));
    yield delay(1000);
    yield put(authActions.signupFailure({}));
  }
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.loginRequest.type, handleSignin);
  yield takeEvery(authActions.signupRequest.type, handleSignup);
}

export default authWatcherSaga;
