// DUCKS pattern
import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { ErrorValue, SessionValue } from "../types";
import type { RootState } from "@src/ducks/store";

interface StateValue {
  loading: boolean;
  data: any;
  redeem: any;
  error: ErrorValue;
  position: number;
}

export const initialState: StateValue = {
  loading: false,
  error: {} as ErrorValue,
  position: 0,
} as StateValue;

// Slice
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    dashboardSuccess: (state, action) => {
      state.data = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
    },
    dashboardFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    redeemRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    redeemSuccess: (state, action) => {
      state.redeem = action.payload;
      state.loading = false;
    },
    redeemFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    updateScrollPosition: (state, action) => {
      state.position = action.payload;
    },

    resetRequest: () => {
      return initialState;
    }
  },
});

// Actions
export const dashboardActions = {
  dashboardRequest: createAction(
    `${dashboardSlice.name}/dashboardRequest`,
    (params: SessionValue) => ({
      payload: params,
    })
  ),
  dashboardSuccess: dashboardSlice.actions.dashboardSuccess,
  dashboardFailure: dashboardSlice.actions.dashboardFailure,

  redeemRequest: createAction(
    `${dashboardSlice.name}/redeemRequest`,
    (params: SessionValue) => ({
      payload: params,
    })
  ),
  redeemSuccess: dashboardSlice.actions.redeemSuccess,
  redeemFailure: dashboardSlice.actions.redeemFailure,

  updateScrollPosition: dashboardSlice.actions.updateScrollPosition,
  resetRequest: dashboardSlice.actions.resetRequest
};

// Selectors
export const selectedDashboardLoading = (state: RootState) => state.dashboard.loading;
export const selectedScrollPosition= (state: RootState) => state.dashboard.position;
export const selectedDashboardError = (state: RootState) => state.dashboard.error;
export const selectedDashboardData = (state: RootState) => state.dashboard.data;
export const selectedRedeemData= (state: RootState) => state.dashboard.redeem;
export const selectedRedeemStatus = createSelector(
  (state: RootState) => state.dashboard.redeem,
  (redeem) => redeem?.redeemStatus?.name ?? ""
);

// Reducer
export default dashboardSlice.reducer;
