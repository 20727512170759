import React from "react";
import { selectedDashboardLoading } from "@src/ducks/slices/dashboard.slice";
import { useNavigation } from "@react-navigation/native";
import { TextInput01 } from "@src/components/textinput";
import { useDashboardService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";
import Icon from "@expo/vector-icons/MaterialIcons";
import { Button01 } from "@src/components/button";
import { RequestFormContext } from "../provider";
import { Pressable, Text, View } from "react-native";
import { AuthContext } from "@src/AuthProvider";
import useStyles from "./styles.css";
import colors from "@assets/colors";
import _ from "lodash";

const Paypal = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [error, setError] = React.useState<any>({});
  const { onRedeemRequest } = useDashboardService();
  const loading = useAppSelector(selectedDashboardLoading);
  const { onErrorMessage } = React.useContext(AuthContext);
  const { state, onDispatch } = React.useContext(RequestFormContext);

  const onSubmit = () => {
    const newErr: any = {};

    const requiredFields = [
      "paypalFirstName",
      "paypalLastName",
      "paypalUserName",
      "paypalEmail",
    ];

    requiredFields.forEach((field) => {
      if (_.isEmpty(state[field])) {
        newErr[field] = "Required";
      }
    });

    if(state.ids.length < 3){
      onErrorMessage("Please provide 3 forms of proof of ID");
      newErr["id1"] = "Required";
    }

    setError(newErr);
    if (_.isEmpty(newErr)) {
      onRedeemRequest({
        ...state,
        "redeemType": {
          "id": 2,
          "name": "PAYPAL"
        },
      });
    }
  };

  return (
    <View>
      <View style={styles.paypal_container}>
        <Text style={styles.t_paypal_title}>PAYPAL</Text>
        <Text style={styles.t_subtitle}>ENTER YOUR ACCOUNT DETAILS </Text>
        <View style={styles.v_flex_row}>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >FIRST NAME:</Text>
            <TextInput01 placeholder="Firstname"
              value={state.paypalFirstName}
              error={error.paypalFirstName}
              onChangeText={onDispatch("paypalFirstName")}
              containerStyle={styles.ti_containerStyle} 
              inputStyle={styles.ti_inputStyle} />
          </View>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >LAST NAME:</Text>
            <TextInput01 placeholder="Last name"
              value={state.paypalLastName}
              error={error.paypalLastName}
              onChangeText={onDispatch("paypalLastName")}
              containerStyle={styles.ti_containerStyle}
              inputStyle={styles.ti_inputStyle}/>
          </View>
        </View>

        <View style={styles.v_flex_row}>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >PAYPAL USERNAME:</Text>
            <TextInput01 placeholder="Paypal username"
              value={state.paypalUserName}
              error={error.paypalUserName}
              onChangeText={onDispatch("paypalUserName")}
              containerStyle={styles.ti_containerStyle}
              inputStyle={styles.ti_inputStyle} />
          </View>
        </View>
        <View style={styles.v_field}>
          <Text style={styles.t_field} >EMAIL ADDRESS:</Text>
          <TextInput01 placeholder="Email address"
            value={state.paypalEmail}
            error={error.paypalEmail}
            onChangeText={onDispatch("paypalEmail")}
            keyboardType="email-address"
            containerStyle={styles.ti_containerStyle}
            inputStyle={styles.ti_inputStyle} />
        </View>
        <View style={styles.v_flex_row_submit}>
          <View style={{flexDirection: "row", alignItems: "center" }}>
            <Pressable onPress={() => onDispatch("isPaypalTermsCheck")(!state.isPaypalTermsCheck)} 
              style={[styles.btn_box, state.isPaypalTermsCheck && { backgroundColor: colors.black}]}>
              <Icon name="check" size={25} color="white" />
            </Pressable> 
            <Text style={styles.t_agree}>I agree to Gameworkz Rewardz 
              <Text onPress={() => navigation.navigate("TermsCondition")} 
                style={{fontFamily: "Montserrat-Bold"}}> Terms & Conditions</Text></Text>
          </View>
          <Button01 disabled={!state.isPaypalTermsCheck}
            isLoading={loading}
            onPress={onSubmit} style={styles.btn_submit} 
            label="SUBMIT" labelStyle={styles.label_submit} />
        </View>
      </View>
    </View>
  );
};

export default Paypal;
