import React from "react";
import { selectedAuthName } from "@src/ducks/slices/auth.slice";
import { useNavigation } from "@react-navigation/native";
import { View, Image, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Button02 } from "@src/components/button";
import { useAuthService } from "@src/ducks/hooks";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";
import MenuButton from "./menu";

function SignedHeader() {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onLogout } = useAuthService();
  const fullname = useAppSelector(selectedAuthName);

  const onRoute = (route: string) => {
    navigation.navigate(route);
  };

  return (
    <View style={styles.container}>
      <LinearGradient
        colors={[colors.violet6, colors.violet5]}
        style={[styles.linear_style]}
      />
      <View style={styles.v_center}>
        <Pressable onPress={() => onRoute("Home")}>
          <Image style={styles.image_logo} 
            source={images["reward-logo"]} 
            resizeMode='contain'/>
        </Pressable>
        <View style={styles.v_right_content}>
          <View style={styles.v_buttons}>
            <Button02 onPress={() => onRoute("Home")}
              bgfocus={colors.transparent}
              color={colors.green1}
              style={styles.btn_style} label="Home" labelStyle={styles.t_label} />
            <Button02 onPress={() => onRoute("MyAccount")}
              bgfocus={colors.transparent}
              color={colors.green1}
              style={styles.btn_style} label="My Account" labelStyle={styles.t_label} />
            <Button02 onPress={() => onRoute("Redeem")} 
              bgfocus={colors.transparent}
              color={colors.green1}
              style={styles.btn_style} label="Redeem" labelStyle={styles.t_label} />
            <Button02 onPress={() => onRoute("ContactUs")} 
              bgfocus={colors.transparent}
              color={colors.green1}
              style={styles.btn_style} label="Contact Us" labelStyle={styles.t_label} />
            <Button02 disabled style={styles.btn_style} label={fullname} labelStyle={styles.t_label} />
          </View>
          <Button02 style={styles.btn_sign} 
            onPress={onLogout}
            labelStyle={styles.label_sign} label="Log out" />
          <View style={styles.divider} />
          <MenuButton />
        </View>
      </View>
    </View>
  );
}

export default SignedHeader;