const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: any, orderBy: string) => {
  return order === "descending"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

export const applySortFilter = (array: any, comparator: any) => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el: any) => el[0]);
};

export const getAmountBalanceByCoinType = (array: any) => {
  const coinTypes = ["SILVER", "GOLD", "GOLD BONUS"];
  const balanceByCoinType: any = {};

  // Initialize balance for each coin type to 0
  coinTypes.forEach((coinType) => {
    balanceByCoinType[coinType] = 0;
  });

  // Calculate the balance for each coin type
  array.forEach((item: any) => {
    const coinTypeName = item.coinType.name;
    if (coinTypes.includes(coinTypeName)) {
      balanceByCoinType[coinTypeName] += item.amount;
    }
  });

  return balanceByCoinType;
};

export const redeemTypeName = (idType: number) => {
  switch(idType){
  case 1:
    return "Bank";
  case 2:
    return "Paypal";
  case 3:
    return "Gift Card";
  case 4:
    return "Promotion Product";
  }
};

export const redeemStatusName = (status: number) => {
  switch(status){
  case 1:
    return "Request submit";
  case 2:
    return "Email verified";
  case 3:
    return "Review in progress";
  case 4:
    return "Request approved";
  case 5:
    return "Funded to User";
  case 6:
    return "Request rejected";
  }
};

export const redeemValidIDs = (status: string) => {
  switch(status){
  case "PASSPORT":
    return 1;
  case "DRIVERS LICENSE":
    return 2;
  case "STUDENT ID":
    return 3;
  case "UTILITY BILL":
    return 4;
  case "OTHER":
    return 5;
  }
};
