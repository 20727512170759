import React from "react";
import { selectedCoinBalance, selectedInvalidUser, selectedTierID } from "@src/ducks/slices/auth.slice";
import { useNavigation } from "@react-navigation/native";
import { PromotionalImages } from "@src/utils/mock-data";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Button01 } from "@src/components/button";
import { Image, Text, View } from "react-native";
import { AuthContext } from "@src/AuthProvider";
import useStyles from "./styles.css";
import colors from "@assets/colors";

type ItemsProps = {
  item: any;
}

function Items(props: ItemsProps) {
  const { item } = props;
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const tierId = useAppSelector(selectedTierID);
  const balance = useAppSelector(selectedCoinBalance);
  const { onErrorMessage } = React.useContext(AuthContext);
  const isUserNotValid = useAppSelector(selectedInvalidUser);

  const onRedeem = () => {
    if(Number(item.goldAmount) > balance.GOLD){
      onErrorMessage("INSUFICIENT BALANCE.");
    }else if(isUserNotValid){
      onErrorMessage("INCOMPLETE USER PROFILE");
    }else if(tierId <= 1){
      onErrorMessage("Your account must be atleast emerald tier.");
    }else{
      const params = {
        type: "promotional",
        id: item.id,
        amount: item.goldAmount,
        productName: item.productName,
        description: item.description,
      };
      navigation.navigate("RequestForm", params);
    }
  };
  
  return (
    <View style={[styles.item_container]}>
      <Image style={styles.item_banner} source={PromotionalImages[item.id]} resizeMode="stretch" />
      <View style={styles.item_details}>
        <Text style={styles.t_item_title} >
          {item.productName}
        </Text>
        <Text style={styles.t_item_subtitle} >
          <Text style={{fontFamily: "Montserrat-Bold"}}>Job:{" "}</Text>
          {item.description}
        </Text>
        <Text style={styles.t_item_gold} >GWz Gold Amount: {item.goldAmount}</Text>
      </View>
      <Button01 onPress={onRedeem} label="Redeem" style={{width: 147, height: 29, backgroundColor: colors.colorPrimary, borderRadius: 5, marginTop: 15, alignSelf: "center"}} />

    </View>
  );
}

export default Items;