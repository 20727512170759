import React from "react";
import { Text, View, Image, Linking } from "react-native";
import { Button02 } from "@src/components/button";
import useStyles from "./styles.css";
import images from "@assets/images";

function PlayFortune8(props: any) {
  const styles = useStyles();
  const onOpenFortune8 = () => {
    Linking.openURL("https://play.fortune8.online");
  };

  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.v_details}>
        <Image style={styles.i_howitworks} source={images.play_fortune} resizeMode='stretch' />
        <View style={styles.divider} />
        <View style={styles.v_info}>
          <Text style={styles.t_title} >Join the Ultimate Gaming Adventure with  FORTUNE 8 GAMES</Text>
          <Text style={[styles.t_subtitle]} >Are you ready for an unforgettable gaming experience filled with excitement,
           entertainment, and incredible rewards? Look no further! At Gameworkz Fortune 8, 
          we've crafted the perfect destination for both casual players and gaming enthusiasts alike.</Text>
          <Button02 
            label="PLAY NOW"
            onPress={onOpenFortune8}
            style={styles.btn_signup}
            labelStyle={styles.label_signup} />
        </View>
      </View>
    </View>
  );
}

export default PlayFortune8;
