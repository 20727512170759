import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import Splash from "@src/components/loading";
import AppRouting from "./AppRouting";
import Linking from "./Linking";

const AppNavigator = () => {
  return (
    <NavigationContainer linking={Linking} fallback={<Splash />}>
      <AppRouting />
    </NavigationContainer>
  );
};

export default AppNavigator;
