import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      minHeight: 500,
      alignItems: "center", 
      justifyContent: "center",
      backgroundColor: "white",
    },
    i_email: { width: 115, height: 64 },
    t_verify: {
      fontSize: 16, 
      marginTop: 15,
      lineHeight: 21,
      color: colors.black, 
      textAlign: "center",
      fontFamily: "Montserrat-Bold", 
    },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      container: { paddingHorizontal: 25 },
      i_email: { width: 90, height: 50 },
      t_verify: { fontSize: 12, lineHeight: 14 }
    },
  }
);

export default useStyles;
