import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      paddingTop: 50,
      paddingBottom: 70,
      alignItems: "center",
      backgroundColor: "white"
    },
    t_title: {
      fontFamily: "Montserrat-Bold", fontSize: 32, 
      lineHeight: 38, color: colors.colorPrimary, textAlign: "center"
    },
    t_subtitle: {
      fontFamily: "Montserrat", fontSize: 18,
      paddingHorizontal: 30,
      lineHeight: 27, color: colors.black1, 
      marginTop: 15, textAlign: "center"
    },
    v_right_content: {flexDirection: "row", alignItems: "center"},
    btn_started: { backgroundColor: colors.colorPrimary, width: 130, height: 41, marginVertical: 50 },

    // ITEMS
    item_container: { width: 368, paddingHorizontal: 15 },
    v_banners: { marginTop: 50},
    item_banner: { width: "100%", height: 180 },
    item_details: { paddingTop: 15, paddingLeft: 15, alignItems: "flex-start"},
    t_item_title: {
      fontFamily: "Montserrat-Medium", 
      fontSize: 24, 
      lineHeight: 36, 
      color: colors.black1,
    },
    t_item_subtitle: {
      fontFamily: "Montserrat", 
      fontSize: 16, 
      lineHeight: 24, 
      color: colors.black1,
    },
    divider: { height: 30 },
  },
  {
    [DEVICE_SIZES.MD]: {
      t_title: { fontSize: 44, lineHeight: 50},
      item_container: { width: 250, },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { paddingHorizontal: 10, marginTop: 0, paddingBottom: 20 },
      t_title: { fontSize: 32, lineHeight: 32 },
      t_subtitle: { fontSize: 16, lineHeight: 18 },
      divider: { height: 0 },
      v_banners: { alignItems: "center", width: "100%",  marginTop: 0 },
      item_details: { marginTop: 10 },
      item_container: { width: "100%", paddingHorizontal: 12, marginTop: 30 },
      item_banner: { height: 175 },
    },
  }
);

export default useStyles;
