import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import useStyles from "./styles.css";
import { View } from "react-native";
import _ from "lodash";

function DatePicker01(props: any) {
  const styles = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <View style={[styles.container, props.style]}>
        <DatePicker
          value={props.value}
          format={props.format}
          slotProps={{
            textField: {
              variant: "outlined",
              error: !_.isEmpty(props.error),
              InputProps: {
                sx: styles.inputSx,
              },
            },
          }}
          onChange={props.onChange}
        />
      </View>
    </LocalizationProvider>
  );
}

export default DatePicker01;
