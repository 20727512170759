import React from "react";
import { View, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  interpolate,
  Extrapolation,
} from "react-native-reanimated";

const AnimatedImage = Animated.createAnimatedComponent(Image);

function LogoAnim() {
  const styles = useStyles();
  const logo = useSharedValue(0);
  const claim = useSharedValue(0);

  const logoStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      logo.value,
      [0, 1],
      [200, 0],
      Extrapolation.CLAMP
    );

    const opacity = interpolate(
      logo.value,
      [0, 0.65, 1],
      [0, 0, 1],
      Extrapolation.CLAMP
    );

    return {
      opacity: opacity,
      transform: [{ translateY }],
    };
  }, []);

  const claimStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      claim.value,
      [0, 1],
      [200, 0],
      Extrapolation.CLAMP
    );

    const opacity = interpolate(
      claim.value,
      [0, 0.7, 1],
      [0, 0, 1],
      Extrapolation.CLAMP
    );

    return {
      opacity: opacity,
      transform: [{ translateY }],
    };
  }, []);

  React.useEffect(() => {
    claim.value = withTiming(1, { duration: 500 });
    logo.value = withTiming(1, { duration: 1200 });
  }, []);

  return (
    <>
      <View style={styles.v_logo}>
        <AnimatedImage style={[styles.i_logo, logoStyle]} source={images["logo"]} resizeMode="stretch" />
      </View>
      <View style={styles.v_claim}>
        <AnimatedImage style={[styles.i_claim, claimStyle]} source={images["claim"]} resizeMode="stretch" />
      </View>
    </>
  );
}

export default LogoAnim;
