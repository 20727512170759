import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      paddingTop: 50,
      paddingBottom: 60,
      alignItems: "center",
      backgroundColor: "white"
    },
    t_title: {
      fontFamily: "Montserrat-Bold", fontSize: 32, 
      lineHeight: 38, color: colors.colorPrimary, textAlign: "center"
    },
    t_subtitle: {
      width: 750,
      fontFamily: "Inter", fontSize: 18, 
      lineHeight: 27, color: colors.black1, 
      marginTop: 15, textAlign: "center"
    },
    t_minimum: {
      fontFamily: "Montserrat", fontSize: 12, 
      lineHeight: 14, color: colors.black1, 
      marginTop: 15
    },
    t_goldvalue: {
      fontFamily: "Montserrat-Bold", fontSize: 12, 
      lineHeight: 14, color: colors.colorPrimary, marginTop: 15
    },
    t_error: {
      fontFamily: "Montserrat-Medium", fontSize: 12, 
      lineHeight: 14, color: colors.red, marginTop: 15
    },
    t_select_gift: {fontFamily: "Montserrat", fontWeight: "600", 
      fontSize: 12, lineHeight: 14, color: colors.black },
    btn_redeem: { paddingHorizontal: 20, height: 35, backgroundColor: colors.green1, marginTop: 30 },
    btn_redeem2: { paddingHorizontal: 20, height: 35, backgroundColor: colors.green1 },

    v_width: { width: 30 },
    t_fees: { width: "100%",
      fontFamily: "Montserrat-Bold", fontSize: 12, fontStyle: "italic",
      lineHeight: 14, color: colors.black, marginTop: 40
    },
    t_terms: { fontStyle: "normal", color: colors.orange1 },
    v_cards: {width: "100%", flexDirection: "row", alignItems: "center", 
      justifyContent: "flex-start", marginTop: 40},
    v_giftcard_row1: { flexDirection: "row", alignItems: "center", width: "50%"  },
    v_giftcard_row2: { flexDirection: "row", alignItems: "center" },
    v_dropdown_row1: {flexDirection: "row", minWidth: 190, marginLeft: 15},
    v_dropdown_row2: {flexDirection: "row", minWidth: 100, marginLeft: 15},


    // ITEMS
    v_banners: {alignItems: "center", marginTop: 50},
    item_banner: { width: "100%", height: "100%" },
    item_container: { width: 257, height: 147, marginHorizontal: 10, alignSelf: "center" },
    divider: { height: 30 },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      v_cards: {width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "center"},
      btn_redeem: { display: "none" },
      v_width: { width: 6 },
    },

    [DEVICE_SIZES.MD]: {
      item_container: { width: 400, height: 200 },
      container: { paddingHorizontal: 20, marginTop: 0, paddingBottom: 0 },
      t_title: { fontSize: 32, lineHeight: 39 },
      t_subtitle: {width: 400, fontSize: 14, lineHeight: 16 },
      divider: { width: 0 },
      v_banners: {width: "100%", alignItems: "flex-start" },
      t_minimum: { fontSize: 14, lineHeight: 20 },
      t_goldvalue: { fontSize: 14, lineHeight: 20 },
      v_cards: {width: "100%", justifyContent: "flex-start" },
      v_giftcard_row1: { flexDirection: "column", alignItems: "flex-start", },
      v_giftcard_row2: { flexDirection: "column", alignItems: "flex-start", minWidth: "30%" },
      v_dropdown_row1: { width: "100%", marginLeft: 0, marginTop: 6 },
      v_dropdown_row2: { marginLeft: 0, marginTop: 6 },
      btn_redeem2: { display: "none" }

    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { paddingHorizontal: 20, marginTop: 0, paddingBottom: 0 },
      t_title: { fontSize: 24, lineHeight: 27 },
      t_subtitle: {width: 300, fontSize: 12, lineHeight: 16 },
      divider: { width: 0 },
      v_banners: {width: "100%", alignItems: "flex-start" },
      v_width: { width: 30 },
      v_cards: {width: "100%", justifyContent: "flex-start" },
      v_giftcard_row1: { flexDirection: "column", alignItems: "flex-start", width: "50%"},
      v_giftcard_row2: { flexDirection: "column", alignItems: "flex-start", minWidth: "30%", flex: 1 },
      v_dropdown_row1: { width: "100%", marginLeft: 0, marginTop: 6 },
      v_dropdown_row2: { marginLeft: 0, marginTop: 6 },
      btn_redeem2: { display: "none" }
    },
  }
);

export default useStyles;
