import React from "react";
import { mobileSize } from "@src/utils/screensize-helper";
import { FeaturesData } from "@src/utils/mock-data";
import { Text, View, FlatList } from "react-native";
import useStyles from "./styles.css";
import Items from "./items";

function Features(props: any) {
  const styles = useStyles();
  const isMobile = mobileSize();

  return (
    <View style={styles.container} onLayout={props.onLayout}>
      <Text style={styles.t_title}>FEATURE GAMES</Text>
      <Text style={styles.t_subtitle} >
      Explore our vast selection of promotional games and slots catering to every gaming preference.
        {"\n"}Don't miss out on the chance to turn your love for gaming into a treasure trove of rewards.
      </Text>
      <View style={styles.v_banners}>
        <FlatList 
          data={FeaturesData}
          style={{width: "100%"}}
          key={Math.random().toString()}
          numColumns={isMobile ? 1 : 3}
          ItemSeparatorComponent={() => <View style={styles.divider} />}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({item, index}) => {
            const isEven = index % 2 !== 0;
            return (
              <Items key={index.toString()} 
                item={item} 
                isEven={isEven} />
            );
          }} />
      </View>
    </View>
  );
}

export default Features;
