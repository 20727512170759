import React from 'react';
import { View, ActivityIndicator, StyleSheet, Platform } from 'react-native';

export default () => {
  const size = Platform.OS === 'web' ? 'large' : 'small';

  return (
    <View style={styles.container}>
      <ActivityIndicator animating size={size} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20,
    alignItems: 'center',
  },
});
