
export default {
  colorPrimary: "#4F0079",
  black: "#000000",
  black1: "#212121",
  border1: "#979797",
  gray: "gray",
  gray1: "#D9D9D9",
  gray2: "#F7F7F8",
  green: "#0BB97A",
  green1: "#00C8B0",
  gold1: "#FBE18A",
  orange1: "#F48329",
  red: "red",
  red1: "#EE4223",
  red2: "#EB0C0C",
  white: "#FFFFFF",
  white1: "#F5F5F5",
  white2: "#FEF2F2",
  white3: "#FFFFFF70",
  violet: "#502774",
  violet1: "#2F1753",
  violet2: "rgba(79, 0, 121, 0.87)",
  violet3: "rgba(73, 5, 108, 0.97)",
  violet4: "rgba(79, 0, 121, 0.5)",
  violet5: "#610790",
  violet6: "#49056C",
  translucent: "rgba(0,0,0,0.3)",
  transparent: "transparent",
};
