import React from "react";
import { selectedDashboardLoading } from "@src/ducks/slices/dashboard.slice";
import { useNavigation } from "@react-navigation/native";
import { TextInput01 } from "@src/components/textinput";
import { useDashboardService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Text, View, Pressable } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { Button01 } from "@src/components/button";
import { RequestFormContext } from "../provider";
import { AuthContext } from "@src/AuthProvider";
import useStyles from "./styles.css";
import colors from "@assets/colors";
import _ from "lodash";

const BankDetails = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [error, setError] = React.useState<any>({});
  const { onRedeemRequest } = useDashboardService();
  const loading = useAppSelector(selectedDashboardLoading);
  const { onErrorMessage } = React.useContext(AuthContext);
  const { state, onDispatch } = React.useContext(RequestFormContext);

  const onSubmit = () => {
    const newErr: any = {};

    const requiredFields = [
      "bankFirstName",
      "bankLastName",
      "bankName",
      "bankSwiftCode",
      "bankAccountNumber",
      "bankIbanIbcCode",
    ];

    requiredFields.forEach((field) => {
      if (_.isEmpty(state[field])) {
        newErr[field] = "Required";
      }
    });

    if(state.ids.length < 3){
      onErrorMessage("Please provide 3 forms of proof of ID");
      newErr["id1"] = "Required";
    }

    setError(newErr);
    if (_.isEmpty(newErr)) {
      onRedeemRequest({
        ...state,
        "redeemType": {
          "id": 1,
          "name": "BANK"
        },
      });
    }
  };

  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.t_paypal_title}>DIRECT DEPOSIT </Text>
        <Text style={styles.t_subtitle}>ENTER YOUR ACCOUNT DETAILS </Text>
        <View style={styles.v_flex_row}>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >FIRST NAME:</Text>
            <TextInput01 placeholder="Firstname"
              value={state.bankFirstName}
              error={error.bankFirstName}
              onChangeText={onDispatch("bankFirstName")}
              containerStyle={styles.ti_containerStyle} 
              inputStyle={styles.ti_inputStyle} />
          </View>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >LAST NAME:</Text>
            <TextInput01 placeholder="Last name"
              value={state.bankLastName}
              error={error.bankLastName}
              onChangeText={onDispatch("bankLastName")}
              containerStyle={styles.ti_containerStyle}
              inputStyle={styles.ti_inputStyle}/>
          </View>
        </View>

        <View style={styles.v_flex_row}>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >BANK NAME:</Text>
            <TextInput01 placeholder="Bank name"
              value={state.bankName}
              error={error.bankName}
              onChangeText={onDispatch("bankName")}
              containerStyle={styles.ti_containerStyle}
              inputStyle={styles.ti_inputStyle} />
          </View>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >SWIFT CODE:</Text>
            <TextInput01 placeholder="Swift Code"
              value={state.bankSwiftCode}
              error={error.bankSwiftCode}
              onChangeText={onDispatch("bankSwiftCode")}
              containerStyle={styles.ti_containerStyle}
              inputStyle={styles.ti_inputStyle}/>
          </View>
        </View>

        <View style={styles.v_flex_row}>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >ACCOUNT NUMBER:</Text>
            <TextInput01 placeholder="Account number"
              value={state.bankAccountNumber}
              error={error.bankAccountNumber}
              onChangeText={onDispatch("bankAccountNumber")}
              containerStyle={styles.ti_containerStyle}
              inputStyle={styles.ti_inputStyle} />
          </View>
          <View style={styles.v_field}>
            <Text style={styles.t_field} >IBAN or BIC:</Text>
            <TextInput01 placeholder="Enter here..."
              value={state.bankIbanIbcCode}
              error={error.bankIbanIbcCode}
              onChangeText={onDispatch("bankIbanIbcCode")}
              containerStyle={styles.ti_containerStyle}
              inputStyle={styles.ti_inputStyle}/>
          </View>
        </View>

        <View style={styles.v_flex_row_submit}>
          <View style={{flexDirection: "row", alignItems: "center" }}>
            <Pressable onPress={() => onDispatch("isTermsCheck")(!state.isTermsCheck)} 
              style={[styles.btn_box, state.isTermsCheck && { backgroundColor: colors.black}]}>
              <Icon name="check" size={25} color="white" />
            </Pressable> 
            <Text style={styles.t_agree}>I agree to Gameworkz Rewardz 
              <Text onPress={() => navigation.navigate("TermsCondition")} 
                style={{fontFamily: "Montserrat-Bold"}}> Terms & Conditions</Text></Text>
          </View>
          <Button01 disabled={!state.isTermsCheck}
            isLoading={loading}
            onPress={onSubmit} style={styles.btn_submit} 
            label="SUBMIT" labelStyle={styles.label_submit} />
        </View>
      </View>
    </View>
  );
};

export default BankDetails;
