import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { Button01 } from "../button";
import colors from "@assets/colors";

type SessionExpiredProps = {
  visible: boolean;
  onPress: () => void;
};

const SessionExpired = ({ visible, onPress }: SessionExpiredProps) => {
  if (!visible) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.v_modal}>
        <Text style={styles.t_expired}>Session expired</Text>
        <Button01
          label="Okay"
          onPress={onPress}
          style={styles.btn_okay}
          labelStyle={styles.btn_label}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 2,
    paddingTop: 50,
    alignItems: "center",
    backgroundColor: colors.colorPrimary,
    ...StyleSheet.absoluteFillObject,
  },
  v_modal: {
    width: 500,
    minHeight: 60,
    paddingTop: 20,
    paddingBottom: 10,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.white,
  },
  t_expired: {
    fontFamily: "DMSans-Bold",
    fontSize: 22,
    color: colors.black,
    textAlign: "center",
  },
  btn_okay: {
    marginTop: 20,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.colorPrimary,
  },
  btn_label: StyleSheet.flatten([
    { color: colors.white },
  ]),
});

export default SessionExpired;
