import React from "react";
import { selectAuthSession } from "@src/ducks/slices/auth.slice";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import moment from "moment";


const Personal = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const session = useAppSelector(selectAuthSession);

  const user = React.useMemo(() => {
    const userDetail = session.userDetail || {};
    const gender = userDetail?.gender?.name ?? "";
    const country = userDetail?.country?.name ?? "";
    const street = userDetail?.street ?? "";
    const postCode = userDetail?.postCode ?? "";
    const city = userDetail?.city ?? "";

    return { gender, country, street, postCode, city };
  }, [session]);

  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.t_note}>In order to complete Gameworkz Rewardz redemption the below information on your account will be required.</Text>
        <View style={styles.v_personalinfo}>
          <Text style={styles.t_title}>PERSONAL INFORMATION</Text>
          <Text onPress={() => navigation.navigate("EditProfile")} 
            style={[styles.t_edit]}> EDIT DETAILS</Text>
        </View>
      
        <View style={styles.v_row}>
          <View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >FIRST NAME:</Text>
              <Text style={styles.t_field_value} >{session.firstName}</Text>
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >LAST NAME:</Text>
              <Text style={styles.t_field_value} >{session.lastName}</Text>
            </View>
            
            <View style={styles.v_field}>
              <Text style={styles.t_field} >GENDER:</Text>
              <Text style={styles.t_field_value} >{user.gender}</Text>
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >D.O.B:</Text>
              <Text style={styles.t_field_value} >
                {session.dateOfBirth ? moment(session.dateOfBirth).format("DD/MM/YYYY") : ""}
              </Text>
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >CONTACT NUMBER:</Text>
              <Text style={styles.t_field_value} >{session.phoneNumber}</Text>
            </View>
          </View>
          <View style={styles.v_address}>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >STREET ADDRESS:</Text>
              <Text style={styles.t_field_value} >{user.street}</Text>
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >CITY:</Text>
              <Text style={styles.t_field_value} >{user.city}</Text>
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >COUNTRY:</Text>
              <Text style={styles.t_field_value} >{user.country}</Text>
            </View>
            <View style={styles.v_field}>
              <Text style={styles.t_field} >POSTAL CODE:</Text>
              <Text style={styles.t_field_value} >{user.postCode}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Personal;