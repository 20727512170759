import React from "react";
import { mobileSize, tabletSize } from "@src/utils/screensize-helper";
import { Text, View, FlatList, Image } from "react-native";
import { useFetchGet } from "@src/utils/api/hooks";
import useStyles from "./styles.css";
import images from "@assets/images";
import Items from "./items";

function Promotional(props: any) {
  const styles = useStyles();
  const isMobile = mobileSize();
  const isTablet = tabletSize();
  const { data, runRequest } = useFetchGet();

  const numColumns = React.useMemo(() => {
    if(isMobile){
      return 1;
    }else if(isTablet){
      return 2;
    }else{
      return 4;
    }
  }, [isMobile, isTablet]);

  React.useEffect(() => {
    runRequest("/api/v1/redeem/promotionProductList/get");
  },[]);

  return (
    <View style={styles.container} onLayout={props.onLayout}>
      <Text style={styles.t_title}>PROMOTIONAL REWARD ITEMS</Text>
      <Text style={styles.t_subtitle} >Have these special reward items available now in Gameworkz Rewardz.</Text>
      <Image style={styles.image_promo} source={images.promo_swordmaster} resizeMode="stretch"/>
      <View style={styles.v_banners}>
        <FlatList 
          data={data}
          key={Math.random().toString()}
          numColumns={numColumns}
          ItemSeparatorComponent={() => <View style={styles.divider} />}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({item, index}) => {
            return (
              <Items key={index.toString()} 
                item={item} />
            );
          }} />
      </View>
    </View>
  );
}

export default Promotional;
