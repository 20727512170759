import React from "react";
import { TextInput01 } from "@src/components/textinput";
import { useRoute } from "@react-navigation/native";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

function InputAmount() {
  const styles = useStyles();
  const route = useRoute<any>();
  const type = route?.params?.type;
  const amount = route?.params?.amount;

  if(type !== "redeem"){
    return null;
  }

  return (
    <View style={styles.ia_container}>
      <View style={styles.ia_v_confirm}>
        <Text style={styles.ia_t_confirm}>
          CONFIRM THE AMOUNT  YOU WANT TO REDEEM</Text>
        <TextInput01 
          value={amount}
          placeholder="Input Field"
          containerStyle={styles.ti_containerStyle} />
      </View>
      <Text style={styles.ia_t_conversion}>1 GWz GOLD COIN = US$1.00</Text>
    </View>
  );
}

export default InputAmount;