import * as Linking from "expo-linking";
import { getStateFromPath } from "@react-navigation/native";

const prefix = Linking.createURL("/");
const config: any = {
  screens: {
    UnSignedDashboardNavigation: {
      screens: {
        Welcome: "welcome",
        Signin: "sign-in",
        Verification: "account/verify",
        ResetPassword: "account/changepass",
        ForgotPassword: "forgot-password",
      },
    },
    DashboardNavigation: {
      path: "dashboard",
      screens: {
        Home: "home",
        Redeem: "redeem",
        MyAccount: "my-account",
        RequestForm: "request-form",
        EditProfile: "edit-profile",
        ContactUs: "contact-us",
      },
    },
    TermsCondition: "terms-condition",
    PrivacyPolicy: "privacy-policy",
    GamingPolicy: "social-gaming-policy",
    FAQs: "faqs",
    NotFound: "*",
  },
};
const linking: any = {
  prefixes: [prefix],
  config,
  async getInitialURL() {
    return await Linking.getInitialURL();
  },
  subscribe(listener: any) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);
    const subscription = Linking.addEventListener("url", onReceiveURL);

    return () => subscription.remove();
  },

  getStateFromPath: (path: string) => {
    if (path.includes("sign-in")) {
      return {
        routes: [
          {
            name: "UnSignedDashboardNavigation",
            state: {
              routes: [
                { name: "Welcome" },
                { name: "Signin" },
              ],
            },
          },
        ],
      };
    }else if (path.includes("forgot-password")) {
      return {
        routes: [
          {
            name: "UnSignedDashboardNavigation",
            state: {
              routes: [
                { name: "Welcome" },
                { name: "ForgotPassword" },
              ],
            },
          },
        ],
      };
    }

    return getStateFromPath(path, config);
  },
};

export default linking;
