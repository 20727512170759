import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 591,
      width: "100%",
      marginTop: 40,
      marginBottom: 40,
      alignItems: "center", 
      justifyContent: "center",
      backgroundColor: colors.violet3,
    },
    linear_style: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_details: {flexDirection: "row", alignItems: "center", justifyContent: "center"},
    i_howitworks: {width: 416, height: 416},
    v_info: { flex: 1, maxWidth: 432 },
    divider: { width: 50 },
    t_title: {
      fontFamily: "Montserrat-Bold", fontSize: 56, 
      lineHeight: 67, color: colors.white 
    },
    t_terms: {
      fontFamily: "Montserrat", 
      fontSize: 14,
      marginTop: 30,
      lineHeight: 21,
      fontWeight: "400",
      color: colors.white,
    },
    t_subtitle: {
      fontFamily: "Montserrat", 
      fontSize: 18,
      lineHeight: 27,
      fontWeight: "600",
      color: colors.white 
    },
    t_subtitle1: { fontWeight: "400", marginTop: 10},
    t_subtitle2: { fontFamily: "Montserrat-Bold", marginTop: 10},
    mart10: { marginTop: 10 },
    btn_signup: {
      backgroundColor: colors.green1, 
      width: 201, 
      height: 46,
      marginTop: 30,
    },
    label_signup: { color: colors.white, fontSize: 16, lineHeight: 22 },
  },
  {
    [DEVICE_SIZES.MD]: {
      i_howitworks: {width: 380, height: 401},
      t_title: { fontSize: 47, lineHeight: 55 },
      t_subtitle: { fontSize: 17, lineHeight: 25 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { width: "100%", height: 279, paddingHorizontal: 20},
      v_details: { alignItems: "flex-start"},
      i_howitworks: {
        width: 150,
        height: 160
      },
      divider: { width: 12 },
      t_title: { fontSize: 24, lineHeight: 28 },
      t_subtitle: { fontSize: 10, lineHeight: 15 },
      t_subtitle1: { marginTop: 5 },
      t_subtitle2: { fontWeight: "400", marginTop: 5},
      mart10: { marginTop: 5 },
      t_terms: { fontSize: 10, lineHeight: 15, marginTop: 10, },
      btn_signup: {
        width: 126, 
        height: 34,
        marginTop: 20,
      },
      label_signup: { fontSize: 10, lineHeight: 14 },
    },
  }
);

export default useStyles;
